<template>
  <v-dialog v-model="showDialog" width="1400">
    <v-card>
      <v-form
        ref="uploadSupplierItemsPriceForm"
        v-if="!showForm"
        @submit.prevent="submit"
      >
        <v-card-title class="d-flex" style="vertical-align: middle">
          <v-row class="pa-3 mb-2">
            <h2 class="text--secondary">Uvoz cijena artikala za dobavljača</h2>
            <v-spacer></v-spacer>
          </v-row>
        </v-card-title>
        <v-card-text>
          <!-- <v-row class="pa-3 mb-2">
              <v-col>
                <a
                  href="https://firebasestorage.googleapis.com/v0/b/ai-fisk-dev.appspot.com/o/example%2FPrimjer%20datoteke%20za%20uvoz%20artikala.csv?alt=media&token=12b8d436-c111-43a4-a648-54bba55e047c"
                  download
                >
                  Preuzmi primjer datoteke za uvoz
                </a>
              </v-col>
            </v-row> -->
        </v-card-text>
        <v-card-text>
          <!-- <v-row class="d-flex ">
              <v-col cols="4"
                  class="pb-0">
                  <v-select multiple
                    outlined
                    dense
                    clearable
                    label="Odaberi prodajna mjesta"
                    :items="locations"
                    id="locations"
                    item-text="name"
                    item-value="id"
                    persistent-hint
                    v-model="selectedLocations"
                    :rules="[rules.arrReq]"></v-select>
                </v-col>
              </v-row> -->
          <v-row class="d-flex justify-space-around">
            <v-col>
              <v-file-input
                :label="$t('$vuetify.companies.csv')"
                style="overflow: hidden"
                @change="onFileInputChange"
                clearable
                v-model="csvFile"
                accept=".csv"
                @click:close="onFileInputChange"
                :rules="[rules.fileReq]"
              >
              </v-file-input>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn
            class="white--text okButton"
            height="45"
            :loading="submitting"
            :disabled="submitting"
            type="submit"
          >
            {{ $t("$vuetify.save") }}
          </v-btn>
        </v-card-actions>
      </v-form>
      <v-form v-if="showForm">
        <v-card-title class="pa-3">Pogreška u datoteci</v-card-title>
        <!-- <v-card-text>{{ invalidRowsText }} </v-card-text> -->
        <v-card-text>Datoteka je neispravna. </v-card-text>
        <v-card-actions>
          <v-btn @click="onCancelClick" :class="['okButton', 'white--text']">
            Odustani
          </v-btn>
        </v-card-actions>
        <v-card-text>Greške prema redcima datoteke:</v-card-text>
        <v-card class="mb-5" v-for="(inRow, i) in invalidRows" :key="i" hover>
          <v-row>
            <v-card-text class="mx-3" v-html="inRow"></v-card-text>
          </v-row>
        </v-card>
      </v-form>
    </v-card>
  </v-dialog>
</template>
<script>
import rules from '@/plugins/rules'
import { df, auth } from '@/plugins/firebase'
import { v4 as uuidv4 } from 'uuid'
import requestChecker from '@/mixins/requestChecker'
import state from '@/state'
import { clone } from '@/plugins/utils'

export default {
  mixins: [requestChecker],
  inject: ['showMsgBox', 'showLoader', 'hideLoader'],
  data: function () {
    return {
      loading: false,
      visible: false,
      submitting: false,
      selectedLocations: [],
      documentId: '',
      locations: [],
      base64File: '',
      dialogText: '',
      timer: 0,
      showForm: false,
      invalidRowsText: '',
      invalidRows: [],
      csvFile: {},
      supplier: undefined,
      location: undefined,
      listeners: [],
      rules: {
        req: rules.req(),
        arrReq: rules.arrReq(),
        fileReq: rules.fileReq()
      },
      showDialog: false
    }
  },
  mounted () {

  },
  methods: {
    open (supplier, location) {
      this.company = clone(state.getCurrentCompany())
      this.visible = true
      this.showDialog = true

      this.listeners.push(
        df
          .doc(
          `user_locations/${auth.currentUser.uid}.${state.getCurrentCompany().id
          }`
          )
          .onSnapshot((doc) => {
            if (
              doc.data() &&
            doc.data().locations &&
            Object.keys(doc.data().locations).length > 0
            ) {
              this.locations = Object.keys(doc.data().locations)
                .map((key) => {
                  return doc.data().locations[key]
                })
                .filter((location) => location.status === 'OK')

              this.selectedLocations = this.locations.map((location) => {
                return location.id
              })
            }
          })
      )
    },
    resetAll () {
      this.selectedLocations = []
      this.documentId = ''
      this.base64File = ''
      this.dialogText = ''
      this.timer = 0
      this.showForm = false
      this.invalidRowsText = ''
      this.invalidRows = []
      this.csvFile = {}
    },
    close () {},
    onFileInputChange (file) {
      if (!file) {
        this.documentId = ''
        this.base64File = ''
        this.dialogText = ''
        this.timer = 0
        this.invalidRowsText = ''
        this.invalidRows = []
        this.csvFile = {}
        return
      }

      const that = this
      if (window.FileReader) {
        var reader = new FileReader()
        reader.readAsDataURL(this.csvFile)
        reader.onload = function () {
          that.base64File = reader.result
        }

        reader.onerror = function (evt) {
          if (evt.target.error.name === 'NotReadableError') {
            alert('Cannot read file!')
          }
        }
      } else {
        alert('FileReader are not supported in this browser.')
      }
    },
    countDown () {
      setTimeout(() => {
        if (this.timer > 0) {
          this.timer--
          this.countDown()
        }
      }, 1000)
    },
    onCancelClick () {
      this.loading = false
      this.visible = false
      this.close()

      this.resetAll()
    },
    submit () {
      if (!this.$refs.uploadSupplierItemsPriceForm.validate()) return

      if (!this.csvFile) {
        alert('Nije odabran dokument za uvoz!')
        return
      }

      this.showLoader()
      // this.file = this.base64File.replace("data:text/csv;base64,", "");

      this.file = this.base64File.split('base64,')[1]
      const that = this

      try {
        const reqId = uuidv4()
        const payload = {
          action: {
            operation: 'set',
            entity: 'supplier_items_price_csv',
            resource_id: '',
            params: {
              company_id: state.getCurrentCompany().id,
              // locations: this.selectedLocations,
              supplier_id: this.supplier.id,
              supplier_items_file: this.file
            }
          }
        }

        df.doc(`request/${reqId}`)
          .set({
            user_id: `${auth.currentUser.uid}`,
            device_id: 'web',
            created: `${new Date().getTime()}`,
            type: 'supplier',
            payload: btoa(
              unescape(encodeURIComponent(JSON.stringify(payload)))
            )
          })
          .then(() => {
            this.close()
          })
          .catch(function (error) {
            that.showMsgBox({
              text: error || 'An error has occurred',
              actions: ['cancel'],
              cancelBtnText: 'OK',
              color: 'error'
            })
          })
          .finally(() => {
            this.submitting = false
          })

        const unsubscribe = df.doc(`response/${reqId}`).onSnapshot((doc) => {
          if (this.$refs && doc.data()) {
            unsubscribe()

            const resp = doc.data()

            if (resp.body && resp.body.invalid_rows) {
              this.hideLoader()
              this.resetAll()
              this.showForm = true
              const invalidRows = resp.body.invalid_rows

              // this.invalidRowsText =  resp.body.invalid_rows;
              Object.keys(invalidRows).forEach((key) => {
                let invalidRowsText = 'Redak broj ' + key + ': <br>'
                Object.keys(invalidRows[key]).forEach((rowKey) => {
                  invalidRowsText += invalidRows[key][rowKey] + '<br>'
                })
                this.invalidRows.push(invalidRowsText)
              })

              this.timer = 30
            } else if (resp.body && resp.header.code === 200) {
              this.hideLoader()
              this.showMsgBox({
                text: 'Artikli su uspješno uvezeni!',
                actions: ['cancel'],
                cancelBtnText: this.$t('$vuetify.close'),
                color: 'error'
              })
              this.resetAll()
            }
          }
        })
      } catch (err) {
        this.showMsgBox({
          text: err || 'An error has occurred',
          actions: ['cancel'],
          cancelBtnText: 'OK',
          color: 'error'
        })
        this.submitting = false
      }
    }
  }
}
</script>
