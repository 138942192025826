<template>
  <v-container fluid :style="`margin-left: ${margin}`">
    <div>
      <v-card class="pa-5 pt-3" width="97%" height="70">
        <v-row>
          <div
            style="
              width: 15% !important;
              padding-top: 15px !important;
              padding-left: 5px;
              padding-right: 5px;
            "
          >
            <v-menu v-model="reportFromMenu" offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  outlined
                  :value="formatDateFrom"
                  label="Od datuma
                  "
                  dense
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="dateFrom"
                no-title
                scrollable
                @input="reportFromMenu = false"
              >
              </v-date-picker>
            </v-menu>
          </div>
          <div
            style="
              width: 15% !important;
              padding-top: 15px !important;
              padding-left: 5px;
              padding-right: 5px;
            "
          >
            <v-menu v-model="reportToMenu" offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  outlined
                  :value="formatDateTo"
                  label="Do datuma"
                  dense
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="dateTo"
                no-title
                scrollable
                @input="reportToMenu = false"
              >
              </v-date-picker>
            </v-menu>
          </div>
          <div
            style="
              width: 15% !important;
              padding-top: 15px !important;
              padding-left: 5px;
              padding-right: 5px;
            "
          >
            <v-select
              outlined
              dense
              label="Prodajno mjesto"
              v-model="location"
              :items="locations"
              item-text="name"
              item-value="id"
              return-object
            ></v-select>
          </div>
          <div
            style="
              max-width: 18% !important;
              padding-top: 15px;
              padding-right: 10px;
              overflow: auto;
            "
          >
            <v-btn
              :disabled="locationNotSelected"
              class="mr-3"
              @click="getData()"
              ><v-icon>mdi-magnify</v-icon></v-btn
            >
            <v-btn class="mr-2" @click="changeWidth()"
              ><v-icon>mdi-file-pdf-box</v-icon></v-btn
            >
            <v-btn class="ml-2" @click="generateExcel()"
              ><v-icon>mdi-microsoft-excel</v-icon></v-btn
            >
          </div>
          <div style="width: 25% !important">
            <v-switch
              v-model="showUnpaid"
              label="Uključi neplaćene račune"
            ></v-switch>
          </div>
          <div
            style="padding-left: 15px; padding-top: 15px; text-align: right"
            class="pt-3 pr-0"
          >
            <v-btn :to="to">X</v-btn>
          </div>
        </v-row>
        <v-row class="pt-0 mt-0 pl-2">
          <div class="d-flex justify-center"></div>
        </v-row>
      </v-card>
    </div>

    <div class="d-flex justify-center">
      <div
        id="table_info"
        justify="center"
        style="
          max-width: 1200px;
          min-width: 1200px;
          font-size: 16px;
          font-family: 'Roboto-Regular';
        "
      >
        <!-- HTML Code: Place this code in the document's body (between the 'body' tags) where the table should appear -->
        <table id="firma" style="display: none; font-family: 'Roboto-Regular'">
          <tbody>
            <tr>
              <td>
                {{ company ? $options.filters.capitalize(company.name) : "" }}
              </td>
            </tr>
            <tr>
              <td>{{ company ? company.address + " " + company.city : "" }}</td>
            </tr>
            <tr>
              <td>OIB: {{ company ? company.oib : "" }}</td>
            </tr>
            <tr>
              <td>Prodajno mjesto: {{ location ? location.name : "" }}</td>
            </tr>
          </tbody>
        </table>
        <!-- Codes by Quackit.com -->

        <!--  -->
        <div style="font-family: font-family: Raleway, sans-serif;">
          <table
            justify="center"
            style="width: 100% !important"
            cellspacing="0"
            id="title"
          >
            <thead>
              <tr class="header">
                <th colspan="6">
                  <p style="font-weight: bold; font-size: 25px">
                    Rekapitulacija prometa
                  </p>
                </th>
              </tr>
            </thead>
          </table>
          <!-- Codes by Quackit.com -->
        </div>
        <div justify="center">
          <table
            justify="center"
            style="width: 100% !important"
            border="1"
            cellspacing="0"
            bordercolor="gray"
            id="tableTraffic"
          >
            <colgroup>
              <col width="16.6%" />
              <col width="16.6%" />
              <col width="16.6%" />
              <col width="16.6%" />
              <col width="16.6%" />
              <col width="16.6%" />
            </colgroup>
            <thead>
              <tr :class="['header', 'naslov']">
                <th colspan="6">
                  <span style="font-weight: bold"
                    >Promet po načinima plaćanja</span
                  >
                </th>
              </tr>
            </thead>
            <tbody style="width: 100% !important">
              <tr></tr>
              <tr :class="['header', 'naslov']">
                <th colspan="2">Način plaćanja</th>
                <th colspan="4">Iznos</th>
              </tr>
              <tr style="text-align: center">
                <td colspan="2">Novčanice</td>
                <td colspan="4">
                  {{
                    viewType === "pdf"
                      ? $options.filters
                          .money(payments.cashPayments, 100, "")
                          .replace("kn", "")
                      : payments.cashPayments / 100
                  }}
                </td>
              </tr>
              <tr style="text-align: center">
                <td colspan="2">Kartice</td>
                <td colspan="4">
                  {{
                    viewType === "pdf"
                      ? $options.filters
                          .money(payments.cardPayments, 100, "")
                          .replace("kn", "")
                      : payments.cardPayments / 100
                  }}
                </td>
              </tr>
              <tr style="text-align: center">
                <td colspan="2">Ostalo</td>
                <td colspan="4">
                  {{
                    viewType === "pdf"
                      ? $options.filters
                          .money(payments.otherPayments, 100, "")
                          .replace("kn", "")
                      : payments.otherPayments / 100
                  }}
                </td>
              </tr>
              <tr style="text-align: center">
                <td colspan="2">Transakcijski račun</td>
                <td colspan="4">
                  {{
                    viewType === "pdf"
                      ? $options.filters
                          .money(payments.transPayments, 100, "")
                          .replace("kn", "")
                      : payments.transPayments / 100
                  }}
                </td>
              </tr>
              <tr style="text-align: center">
                <td colspan="2">Ukupno</td>
                <td colspan="4">
                  {{
                    viewType === "pdf"
                      ? $options.filters
                          .money(payments.totalPayments, 100, "")
                          .replace("kn", "")
                      : payments.totalPayments / 100
                  }}
                </td>
              </tr>
            </tbody>
          </table>
          <table
            justify="center"
            style="width: 100% !important"
            border="1"
            cellspacing="0"
            bordercolor="gray"
            id="receiptTable"
          >
            <thead>
              <tr :class="['header', 'naslov']">
                <th colspan="7">Računi</th>
              </tr>
              <tr :class="['header', 'naslov']">
                <th colspan="1">Datum računa</th>
                <th colspan="1">Broj računa</th>
                <th colspan="1">Korisnik</th>
                <th colspan="1">Oznake</th>
                <th colspan="1">Fisk.</th>
                <th colspan="1">Način plaćanja</th>
                <th colspan="1">Iznos</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="receipt in allReceipts"
                :key="receipt.id + receipt.designation.number"
                style="text-align: center"
              >
                <td colspan="1" align-end>
                  {{ receipt.issue_date_time | localWithoutDot }}
                </td>
                <td colspan="1">
                  {{
                    receipt.designation.number.toString() +
                    receipt.company_details.rcpt_separator +
                    receipt.designation.location_id +
                    receipt.company_details.rcpt_separator +
                    receipt.designation.register_number.toString()
                  }}
                </td>
                <td colspan="1">{{ receipt.seller_name }}</td>
                <td colspan="1">{{ receipt.formattedTags }}</td>

                <td colspan="1">{{ receipt.fiscalize ? "DA" : "NE" }}</td>
                <td colspan="1">{{ receipt.payment_method }}</td>
                <td colspan="1">
                  {{
                    viewType === "pdf"
                      ? $options.filters
                          .money(receipt.total, 100, "")
                          .replace("kn", "")
                      : receipt.total / 100
                  }}
                </td>
              </tr>
            </tbody>
          </table>
          <table
            justify="center"
            style="width: 100% !important"
            border="1"
            cellspacing="0"
            bordercolor="gray"
            id="taxTable"
          >
            <thead>
              <tr :class="['header', 'naslov']">
                <th colspan="6">Obračun poreza</th>
              </tr>
              <tr style="width: 100% !important" :class="['header', 'naslov']">
                <th colspan="1">Porezna stopa</th>
                <th colspan="2">Osnovica</th>
                <th colspan="1">Porez</th>
                <th colspan="2">Ukupno</th>
              </tr>
            </thead>
            <tbody>
              <tr
                style="text-align: center"
                v-for="(pdv, pk) in payments.pdv"
                :key="pk + pdv.base"
              >
                <td colspan="1">{{ pdv.rate / 100 + "%" }}</td>
                <td colspan="2">
                  {{
                    viewType === "pdf"
                      ? $options.filters
                          .money(pdv.base, 100, "")
                          .replace("kn", "")
                      : pdv.base / 100
                  }}
                </td>
                <td colspan="1">
                  {{
                    viewType === "pdf"
                      ? $options.filters
                          .money(pdv.tax, 100, "")
                          .replace("kn", "")
                      : pdv.tax / 100
                  }}
                </td>
                <td colspan="2">
                  {{
                    viewType === "pdf"
                      ? $options.filters
                          .money(pdv.total, 100, "")
                          .replace("kn", "")
                      : pdv.total / 100
                  }}
                </td>
              </tr>
              <tr style="text-align: center">
                <td colspan="1">{{ payments.pdvTotal.rate }}</td>
                <td colspan="2">
                  {{
                    viewType === "pdf"
                      ? $options.filters
                          .money(payments.pdvTotal.base, 100, "")
                          .replace("kn", "")
                      : payments.pdvTotal.base / 100
                  }}
                </td>
                <td colspan="1">
                  {{
                    viewType === "pdf"
                      ? $options.filters
                          .money(payments.pdvTotal.total, 100, "")
                          .replace("kn", "")
                      : payments.pdvTotal.total / 100
                  }}
                </td>
                <td colspan="2">
                  {{
                    viewType === "pdf"
                      ? $options.filters
                          .money(
                            payments.pdvTotal.total + payments.pdvTotal.base,
                            100,
                            ""
                          )
                          .replace("kn", "")
                      : payments.pdvTotal.total / 100 +
                        payments.pdvTotal.base / 100
                  }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </v-container>
</template>
<script>

import { DAY, clone } from '@/plugins/utils'
import { df, auth } from '@/plugins/firebase'
import state from '@/state'
import 'jspdf-autotable'
import { saveExcel } from '@/modules/all-reports/xlsx/config.js'
import EventBus from '@/plugins/event-bus'
import { initPdf, setTable, setPageNumber } from '@/modules/all-reports/pdf/config.js'
import avansDiscount from '@/mixins/avans'

export default {
  name: 'Reports',
  inject: ['showMsgBox', 'showLoader', 'hideLoader'],
  components: { },
  data: function () {
    return {
      dateFrom: new Date(new Date().getTime() - DAY)
        .toISOString()
        .substr(0, 10),
      dateTo: new Date().toISOString().substr(0, 10),
      allReceipts: [],
      reportFromMenu: undefined,
      reportToMenu: undefined,
      company: undefined,
      payments: {
        cashPayments: 0,
        cardPayments: 0,
        otherPayments: 0,
        transPayments: 0,
        totalPayments: 0,
        pdv: [],
        pdvTotal: { base: 0, rate: 'Ukupno', total: 0 }
      },
      tableWidth: '1200px',
      a4PortraitWidth: '575px',
      a4LandscapeWidth: '610px',
      targetModifier: '',
      viewType: 'pdf',
      location: null,
      locations: [],
      showUnpaid: false
    }
  },
  computed: {
    to () {
      return { name: `${this.targetModifier}.all-reports.reportMenu` }
    },
    formatDateTo () {
      return new Intl.DateTimeFormat('hr-HR', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric'
      }).format(new Date(this.dateTo))
    },
    formatDateFrom () {
      return new Intl.DateTimeFormat('hr-HR', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric'
      }).format(new Date(this.dateFrom))
    },
    locationNotSelected () {
      return !this.location || !this.location.id
    }
  },
  async created () {
    this.margin = state.getMargin()
    await this.getPointsOfSale()
  },
  mounted () {
    var sideNav = this.$route.meta.sideNav
    switch (sideNav) {
      case 'Companies':
        break
      case 'Company':
        this.targetModifier = 'company'
        break
      case 'PointOfSale':
        this.targetModifier = 'pointOfSale'
        break
      case 'CashRegister':
        this.targetModifier = 'pointOfSale'
        break
    }

    EventBus.$on('set-mini', () => {
      this.margin = state.getMargin()
    })

    this.company = clone(state.getCurrentCompany())
    this.location = clone(state.getPointOfSale())

    if (this.company.type === 'pausalni_obrt') {
      this.showUnpaid = false
    } else {
      this.showUnpaid = true
    }
  },
  methods: {
    async getData () {
      this.showLoader()
      const dateFrom = new Date(this.dateFrom).getTime() / 1000
      const dateTo = new Date(this.dateTo).getTime() / 1000

      this.allReceipts = []

      let query = df.collection(`receipts/${state.getCurrentCompany().id}/receipts`)
        .where('issue_date_time', '>=', dateFrom)
        .where('issue_date_time', '<=', dateTo + 86399)
      if (this.location && this.location.id !== 'all') {
        query = query.where('location_details.id', '==', this.location.id)
      }

      await query.get()
        .then((documentSnapshots) => {
          if (documentSnapshots.docs && !documentSnapshots.docs.empty) {
            documentSnapshots.docs.forEach((item) => {
              var rcpt = item.data()
              if (this.showUnpaid === false) {
                if (rcpt.payment && (rcpt.payment.current_status === 'PAID' || rcpt.payment.current_status === 'REVERSED')) {
                  this.allReceipts.push(item.data())
                }
              } else {
                this.allReceipts.push(item.data())
              }
            })
          }
        })

      this.allReceipts.sort((a, b) => a.issue_date_time - b.issue_date_time) // b - a for reverse sort

      this.calculateData()
    },
    getPdvDiscounted (receipt, reprezentacija) {
      var tempPdv = []
      if (receipt.pdv_discounted && receipt.pdv_discounted.length > 0) {
        if (reprezentacija === true) {
          var b = 160
          var t = 200
          var tx = 40

          if (receipt.issue_date_time > 1672531210) {
            b = 40
            t = 50
            tx = 10
          }

          if (receipt.type.flag === 'STORNO') {
            b *= -1
            t *= -1
            tx *= -1
          }

          tempPdv.push({
            rate: (2500),
            base: (b),
            total: (t),
            tax: (tx),
            count: 1,
            paymentMethod: receipt.payment_method
          })
        } else {
          receipt.pdv_discounted.forEach(pdv => {
            tempPdv.push({
              rate: (pdv.rate),
              base: (pdv.base),
              total: (pdv.total + pdv.base),
              tax: (pdv.total),
              count: 1,
              paymentMethod: receipt.payment_method
            })
          })
        }
      }

      return tempPdv
    },
    getPdvAndRemoveDiscountIfExist (receipt, reprezentacija) {
      var tempPdv = []
      if (receipt.pdv && receipt.pdv.length > 0) {
        if (reprezentacija === true) {
          var b = 160
          var t = 200
          var tx = 40

          if (receipt.issue_date_time > 1672531210) {
            b = 40
            t = 50
            tx = 10
          }

          if (receipt.type.flag === 'STORNO') {
            b *= -1
            t *= -1
            tx *= -1
          }

          tempPdv.push({
            rate: (2500),
            base: (b),
            total: (t),
            tax: (tx),
            count: 1,
            paymentMethod: receipt.payment_method
          })
        } else {
          receipt.pdv.forEach(pdv => {
            tempPdv.push({
              rate: (pdv.rate),
              base: (pdv.base),
              total: (pdv.total + pdv.base),
              tax: (pdv.total),
              count: 1,
              paymentMethod: receipt.payment_method
            })
          })
        }
      }

      if (tempPdv && tempPdv.length > 0) {
        // Ako postoji popust na iznos racuna onda skini na pojedinacnim artiklima
        var d = clone(receipt.discount)
        if (d && d.active === true && d.type !== '' && reprezentacija === false) {
          let totalRemove = d.amount / 100

          tempPdv.sort((a, b) => b.rate - a.rate) // b - a for reverse sort

          if (d.name === 'AVANS-STORNO-RECEIPT') {
            if (receipt.type.flag !== 'STORNO') {
              tempPdv = avansDiscount(tempPdv, receipt.tags)
            }
          } else {
            tempPdv.forEach((p, pkey) => {
              if (p.base > 0 && p.total > 0) {
                let totalPrice = p.total / 100

                if (totalPrice >= (1 + (p.rate / 10000))) {
                  let removedAllPossible = false
                  let remove = totalRemove

                  // kreni unazad, uzmi ukupni iznos za skidanje i probaj skinuti
                  for (var j = totalRemove; j > 0 && removedAllPossible === false; j--) {
                  // ako oduzmes iznos i on je veci od 100 na kraju i ustvari si skinuo sve, tu zavrsi
                    if ((totalPrice - j) >= (1 + (p.rate / 10000))) {
                      removedAllPossible = true
                      remove = j
                    }
                  }

                  if (removedAllPossible === true) {
                    totalPrice -= remove
                    totalRemove -= remove
                  }
                }

                tempPdv[pkey].total = totalPrice * 100
                tempPdv[pkey].base = parseInt(parseFloat(tempPdv[pkey].total) / (1 + parseFloat(tempPdv[pkey].rate) / 10000))
                tempPdv[pkey].tax = (tempPdv[pkey].total) - (tempPdv[pkey].base)
              }
            })
          }
        }
      }

      return tempPdv
    },
    calculateData () {
      let transTotal = 0
      let cashTotal = 0
      let cardTotal = 0
      let ostaloTotal = 0
      var totalByReceiptPdv = []

      this.allReceipts.forEach((receipt, rIdx) => {
        if (receipt.payment_method === 'TRANSAKCIJSKI RAČUN') {
          transTotal += receipt.total
        }
        if (receipt.payment_method === 'KARTICA') {
          cardTotal += receipt.total
        }
        if (receipt.payment_method === 'NOVČANICE') {
          cashTotal += receipt.total
        }
        if (receipt.payment_method === 'OSTALO') {
          ostaloTotal += receipt.total
        }

        var reprezentacija = false
        var formattedTags = ''
        if (receipt.tags) {
          receipt.tags.forEach(tag => {
            if (tag.receipt_type && tag.receipt_type === 'REPREZENTACIJA') {
              reprezentacija = true
            }

            Object.keys(tag).forEach(tkey => {
              formattedTags = formattedTags + ' ' + tag[tkey]
            })
          })
        }

        if (formattedTags !== '') {
          this.allReceipts[rIdx].formattedTags = formattedTags
        }

        var tempPdv = []
        // OTKOMENITIRAJ ZA PROVJERU KRIVIH RACUNA
        // var totalByPdv = 0

        if (receipt.pdv_discounted && receipt.pdv_discounted.length > 0) {
          tempPdv = this.getPdvDiscounted(receipt, reprezentacija)
        } else if (receipt.pdv && receipt.pdv.length > 0) {
          tempPdv = this.getPdvAndRemoveDiscountIfExist(receipt, reprezentacija)
        }

        tempPdv.forEach(pdv => {
          const base = Math.round(pdv.base * 100) / 100
          const total = Math.round(pdv.total * 100) / 100
          const tax = Math.round((pdv.tax) * 100) / 100

          // OTKOMENITIRAJ ZA PROVJERU KRIVIH RACUNA
          // totalByPdv += (pdv.tax + pdv.base)

          const idx = totalByReceiptPdv.findIndex(p => p?.rate === pdv.rate)
          if (idx > -1) {
            totalByReceiptPdv[idx].base += base
            totalByReceiptPdv[idx].total += total
            totalByReceiptPdv[idx].tax += tax
            totalByReceiptPdv[idx].count += 1
          } else {
            totalByReceiptPdv.push({
              rate: (pdv.rate),
              base: base,
              total: (total),
              tax: (tax),
              count: 1
            })
          }
        })

        // OTKOMENITIRAJ ZA PROVJERU KRIVIH RACUNA
        // if (receipt.total !== totalByPdv) {
        //   console.log('krivo', receipt.total, totalByPdv, receipt.designation.number, receipt, receipt.payment_method)
        // }
      })

      // Sluzi samo za formatiranje i zbrajanje ukupnog stanja
      var totalBase = 0
      var totalTax = 0
      totalByReceiptPdv.forEach((pdv) => {
        totalBase += pdv.base
        totalTax += pdv.tax
      })

      this.payments = {
        cashPayments: cashTotal,
        cardPayments: cardTotal,
        otherPayments: ostaloTotal,
        transPayments: transTotal,
        totalPayments: cashTotal + cardTotal + ostaloTotal + transTotal,
        pdv: totalByReceiptPdv,
        pdvTotal: { rate: 'Ukupno', base: totalBase, total: totalTax }
      }
      this.hideLoader()
    },
    changeWidth () {
      this.showLoader('', { opacity: 1 })
      document.getElementById('table_info').style.maxWidth = this.a4PortraitWidth
      document.getElementById('table_info').style.minWidth = this.a4PortraitWidth
      document.getElementById('table_info').style.fontSize = '80%'

      this.generatePdf()
    },
    async generatePdf () {
      const that = this
      // eslint-disable-next-line new-cap
      var doc = await initPdf()

      doc = await setTable(doc, '#firma', 10)
      var finalY = doc.lastAutoTable.finalY || 10
      doc = await setTable(doc, '#title', finalY)
      finalY = doc.lastAutoTable.finalY || 10
      doc = await setTable(doc, '#tableTraffic', finalY)
      finalY = doc.lastAutoTable.finalY || 10
      doc = await setTable(doc, '#receiptTable', finalY)
      finalY = doc.lastAutoTable.finalY || 10
      doc = await setTable(doc, '#taxTable', finalY)

      doc = await setPageNumber(doc)

      doc.save('Rekapitulacija prometa.pdf')

      document.getElementById('table_info').style.maxWidth = that.tableWidth
      document.getElementById('table_info').style.minWidth = that.tableWidth
      document.getElementById('table_info').style.fontSize = '100%'
      this.viewType = 'pdf'
      that.hideLoader()
    },
    generateExcel () {
      this.showLoader('', { opacity: 1 })
      this.viewType = 'excel'
      setTimeout(() => {
        var tables = ['firma', 'title', 'tableTraffic', 'receiptTable', 'taxTable']
        var fileName = 'Rekapitulacija prometa.xlsx'

        saveExcel(tables, fileName)

        this.viewType = 'pdf'
        this.hideLoader()
      }, 1000)
    },
    async getPointsOfSale () {
      const docRef = df.doc(`user_locations/${auth.currentUser.uid}.${state.getCurrentCompany().id}`)
      docRef.onSnapshot(doc => {
        var locations = Object.values(doc.data().locations)
        locations.forEach((location, index) => {
          this.locations.push(location)
        })
      })
      // const all = { name: 'Sva', id: 'all' }
      // this.locations.push(all)
      // this.location = all
    }
  }
}
</script>
<style scoped>
#tableTraffic {
  border: 1px solid #767676;
}

#tableTax {
  border: 1px solid #767676;
}

.naslov {
  background-color: #d3d3d3;
  border: 1px solid black;
}
.v-btn {
  min-width: 40px !important;
  padding: 0px !important;
}
</style>
