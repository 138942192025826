
import { clone, duck } from '@/plugins/utils'
var params = {}
export default {
  methods: {
    registerRequestParams (parametars) {
      params = clone(parametars)
    },
    getCleanParams (parametars) {
      const ret = {}
      if (typeof parametars !== 'object') return params
      Object.keys(parametars).forEach(key => {
        if (!Object.prototype.hasOwnProperty.call(params, key)) return
        if (duck(params[key], parametars[key])) ret[key] = parametars[key]
      })
      return ret
    }
  }
}
