export default {
  methods: {
    avansDiscount (tempPdv, tags) {
      tags.forEach(tag => {
        if (tag.tag === 'AVANS_DEDUCTED' && tag.receipts && tag.receipts.length > 0) {
          tag.receipts.forEach(pdvRemoveReceipt => {
            tempPdv.forEach((temp, k) => {
              if (pdvRemoveReceipt.storno_tax_rate === temp.rate) {
                temp.base -= pdvRemoveReceipt.storno_tax_base
                temp.total -= pdvRemoveReceipt.storno_tax_total
              }

              tempPdv[k] = temp
            })
          })
        }
      })

      return tempPdv
    }
  }
}
