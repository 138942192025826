<template>
  <v-text-field
    v-bind="$attrs"
    v-on="_events"
    @keypress.native="checkInput($event)"
  >
    <template
      v-for="(_, slotName) in $scopedSlots"
      v-slot:[slotName]
    >
      <slot :name="slotName" />
    </template>
  </v-text-field>
</template>
<script>

export default {
  name: 'QuantityInput',
  methods: {
    checkInput (ev = false) { // TODO: make it look better
      const maskRe = /^([0-9]+)|([,])$/
      // const maskRe = /^([0-9]+)$/
      if (([','].some(sep => this.$attrs?.value?.toString()?.includes(sep)) && [','].some(sep => ev.key === sep)) || !maskRe.test(ev.key)) {
        ev.preventDefault()
        return false
      }
    }
  }
}
</script>
