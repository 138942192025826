<template>
  <div>
    <v-container class="justify-center">
      <v-card>
        <v-form ref="uploadItemsForm" v-if="!showForm" @submit.prevent="submit">
          <v-card-title class="d-flex" style="vertical-align: middle">
            <v-row class="pa-3 mb-2">
              <h2 class="text--secondary">Uvoz artikala</h2>
              <v-spacer></v-spacer>
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-row class="pa-3 mb-2">
              <v-col>
                <a
                  href="https://firebasestorage.googleapis.com/v0/b/ai-fisk-dev.appspot.com/o/example%2FPrimjer%20datoteke%20za%20uvoz%20artikala.csv?alt=media&token=12b8d436-c111-43a4-a648-54bba55e047c"
                  download
                >
                  Preuzmi primjer datoteke za uvoz
                </a>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-text>
            <v-row class="pa-3 mb-2">
              <v-col>
                <v-select
                  v-model="selectedLocations"
                  :items="locations"
                  label="Omogući prodaju na prodajnom mjestu"
                  multiple
                  outlined
                  dense
                  clearable
                  item-text="name"
                  item-value="id"
                  :rules="[rules.arrReq]"
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row class="d-flex justify-space-around">
              <v-col>
                <v-file-input
                  :label="$t('$vuetify.companies.csv')"
                  style="overflow: hidden"
                  @change="onFileInputChange"
                  clearable
                  v-model="csvFile"
                  accept=".csv"
                  @click:close="onFileInputChange"
                  :rules="[rules.fileReq]"
                >
                </v-file-input>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="d-flex justify-center">
            <v-btn
              class="white--text okButton"
              height="45"
              :loading="submitting"
              :disabled="submitting"
              type="submit"
            >
              {{ $t("$vuetify.save") }}
            </v-btn>
          </v-card-actions>
        </v-form>
        <v-form v-if="showForm">
          <v-card-title class="pa-3">Pogreška u datoteci</v-card-title>
          <!-- <v-card-text>{{ invalidRowsText }} </v-card-text> -->
          <v-card-text
            >Datoteka je neispravna. Ukoliko želite možete nastaviti uvoz, ali
            imajte na umu da neispravni artikli neće bit zapisani! Molimo
            potvrdite svoj odabir!</v-card-text
          >
          <v-card-text>Molimo potvrdite svoj odabir!</v-card-text>
          <v-card-actions>
            <v-btn @click="onOkClick">Nastavi</v-btn>
            <v-btn @click="onCancelClick" :class="['okButton', 'white--text']">
              Odustani
            </v-btn>
          </v-card-actions>
          <v-card-text>Greške prema redcima datoteke:</v-card-text>
          <v-card class="mb-5" v-for="(inRow, i) in invalidRows" :key="i" hover>
            <v-row>
              <v-card-text class="mx-3" v-html="inRow"></v-card-text>
            </v-row>
          </v-card>
        </v-form>
      </v-card>
    </v-container>
  </div>
</template>
<script>
import rules from '@/plugins/rules'
import { df, auth, functions } from '@/plugins/firebase'
import { v4 as uuidv4 } from 'uuid'
import requestChecker from '@/mixins/requestChecker'
import state from '@/state'
import { clone } from '@/plugins/utils'

export default {
  mixins: [requestChecker],
  inject: ['showMsgBox', 'showLoader', 'hideLoader'],
  data: function () {
    return {
      loading: false,
      visible: false,
      submitting: false,
      selectedLocations: [],
      documentId: '',
      locations: [],
      base64File: '',
      dialogText: '',
      timer: 0,
      showForm: false,
      invalidRowsText: '',
      invalidRows: [],
      csvFile: {},
      rules: {
        req: rules.req(),
        arrReq: rules.arrReq(),
        fileReq: rules.fileReq()
      }
    }
  },
  mounted () {
    this.open()
  },
  methods: {
    open () {
      this.company = clone(state.getCurrentCompany())
      this.visible = true
      df.doc(
        `user_locations/${auth.currentUser.uid}.${state.getCurrentCompany().id}`
      )
        .get()
        .then((doc) => {
          if (
            doc.data() &&
            doc.data().locations &&
            Object.keys(doc.data().locations).length > 0
          ) {
            this.locations = Object.keys(doc.data().locations)
              .map((key) => {
                return doc.data().locations[key]
              })
              .filter((location) => location.status === 'OK')

            // this.selectedLocations = this.locations.map((location) => {
            //   return location.id;
            // });
          }
        })
    },
    resetAll () {
      this.selectedLocations = []
      this.documentId = ''
      this.base64File = ''
      this.dialogText = ''
      this.timer = 0
      this.showForm = false
      this.invalidRowsText = ''
      this.invalidRows = []
      this.csvFile = {}
    },
    close () {},
    onCancelClick () {
      this.loading = false
      this.visible = false

      try {
        const payload = {
          action: {
            operation: 'update',
            entity: 'price_list_csv',
            resource_id: this.documentId,
            params: {
              company_id: this.company.id,
              location_ids: this.selectedLocations,
              skip_errors: false
            }
          }
        }

        var companyitemApi = functions.httpsCallable('companyitemapi')

        companyitemApi({
          action: {
            operation: payload.action.operation,
            entity: payload.action.entity,
            resource_id: payload.action.resource_id,
            params: payload.action.params
          }
        })
          .then((result) => {
            if (result.data.code === 200) {
              this.resetAll()
              this.close()
            } else {
              this.showMsgBox({
                text: result.data.message ?? 'Dogodila se pogreška.',
                actions: ['cancel'],
                cancelBtnText: 'OK',
                color: 'error'
              })
            }
            this.submitting = false
          })
      } catch (err) {
        this.showMsgBox({
          text: err || 'An error has occurred',
          actions: ['cancel'],
          cancelBtnText: 'OK',
          color: 'error'
        })
        this.submitting = false
        this.resetAll()
      }
    },
    onOkClick () {
      this.showLoader()
      try {
        const payload = {
          action: {
            operation: 'update',
            entity: 'price_list_csv',
            resource_id: this.documentId,
            params: {
              company_id: this.company.id,
              location_ids: this.selectedLocations,
              skip_errors: true
            }
          }
        }

        var companyitemApi = functions.httpsCallable('companyitemapi')

        companyitemApi({
          action: {
            operation: payload.action.operation,
            entity: payload.action.entity,
            resource_id: payload.action.resource_id,
            params: payload.action.params
          }
        })
          .then((result) => {
            if (result.data.code === 200) {
              this.resetAll()
              this.close()
            } else {
              this.showMsgBox({
                text: result.data.message ?? 'Dogodila se pogreška.',
                actions: ['cancel'],
                cancelBtnText: 'OK',
                color: 'error'
              })

              this.showForm = false
              this.hideLoader()
              this.resetAll()
            }

            this.hideLoader()
            this.submitting = false
          })
      } catch (err) {
        this.showForm = false
        this.hideLoader()
        this.showMsgBox({
          text: err || 'An error has occurred',
          actions: ['cancel'],
          cancelBtnText: 'OK',
          color: 'error'
        })
        this.submitting = false
      }
    },
    onFileInputChange (file) {
      if (!file) {
        this.documentId = ''
        this.base64File = ''
        this.dialogText = ''
        this.timer = 0
        this.invalidRowsText = ''
        this.invalidRows = []
        this.csvFile = {}
        return
      }

      const that = this
      if (window.FileReader) {
        var reader = new FileReader()
        reader.readAsDataURL(this.csvFile)
        reader.onload = function () {
          that.base64File = reader.result
        }

        reader.onerror = function (evt) {
          if (evt.target.error.name === 'NotReadableError') {
            alert('Cannot read file!')
          }
        }
      } else {
        alert('FileReader are not supported in this browser.')
      }
    },
    countDown () {
      setTimeout(() => {
        if (this.timer > 0) {
          this.timer--
          this.countDown()
        }
      }, 1000)
    },
    submit () {
      if (!this.$refs.uploadItemsForm.validate()) return

      if (!this.csvFile) {
        alert('Nije odabran dokument za uvoz!')
        return
      }

      this.showLoader()
      // this.file = this.base64File.replace("data:text/csv;base64,", "");

      this.file = this.base64File.split('base64,')[1]

      try {
        const reqId = uuidv4()
        const payload = {
          action: {
            operation: 'set',
            entity: 'price_list_csv',
            resource_id: '',
            params: {
              company_id: this.company.id,
              location_ids: this.selectedLocations,
              price_list_file: this.file
            }
          }
        }

        df.doc(`request/${reqId}`)
          .set({
            user_id: `${auth.currentUser.uid}`,
            device_id: 'web',
            created: `${new Date().getTime()}`,
            type: 'companyitem',
            payload: btoa(
              unescape(encodeURIComponent(JSON.stringify(payload)))
            )
          })
          .then(() => {
            this.close()
          })
          .catch(function (error) {
            this.showMsgBox({
              text: error || 'An error has occurred',
              actions: ['cancel'],
              cancelBtnText: 'OK',
              color: 'error'
            })
          })
          .finally(() => {
            this.submitting = false
          })

        const unsubscribe = df.doc(`response/${reqId}`).onSnapshot((doc) => {
          if (this.$refs && doc.data()) {
            unsubscribe()

            const resp = doc.data()

            if (resp.body && resp.body.invalid_rows) {
              this.hideLoader()
              this.showForm = true
              const invalidRows = resp.body.invalid_rows

              // this.invalidRowsText =  resp.body.invalid_rows;
              Object.keys(invalidRows).forEach((key) => {
                let invalidRowsText = 'Redak broj ' + key + ': <br>'
                Object.keys(invalidRows[key]).forEach((rowKey) => {
                  invalidRowsText += invalidRows[key][rowKey] + '<br>'
                })
                this.invalidRows.push(invalidRowsText)
              })

              this.timer = 30
              // this.countDown();
              // setTimeout(() => {
              //   this.showForm = false;
              //   this.hideLoader()
              //   this.visible = false;
              // }, 30000);
            } else if (resp.body && resp.body.document && resp.header.code === 200) {
              this.documentId = resp.body.document
              this.hideLoader()
              this.showMsgBox({
                text: 'Artikli su uspješno uvezeni!',
                actions: ['cancel'],
                cancelBtnText: this.$t('$vuetify.close'),
                color: 'error'
              })
              this.resetAll()
            } else {
              this.resetAll()
            }
            if (
              (!doc.data().body ||
              !doc.data().body.document ||
              !doc.data().body.invalid_rows) && doc.data().header.error
            ) {
              this.hideLoader()
              this.showMsgBox({
                text: doc.data().header.error,
                actions: ['cancel'],
                cancelBtnText: 'OK',
                color: 'error'
              })
              this.$emit('editSuccess', this.company)
              this.$emit('onFinish')
            }
          }
        })
      } catch (err) {
        this.showMsgBox({
          text: err || 'An error has occurred',
          actions: ['cancel'],
          cancelBtnText: 'OK',
          color: 'error'
        })
        this.submitting = false
      }
    }
  }
}
</script>
