<template>
  <v-container fluid :style="`margin-left: ${margin}`">
    <div>
      <v-card class="pa-5 pt-3" width="97%" height="70">
        <div class="pt-1" style="display: flex">
          <div class="pr-2 pl-2" style="flex: 1">
            <v-menu v-model="reportFromMenu" offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  outlined
                  :value="formatDateFrom"
                  label="Od datuma"
                  dense
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="dateFrom"
                no-title
                scrollable
                @input="reportFromMenu = false"
              >
              </v-date-picker>
            </v-menu>
          </div>
          <div class="pr-2 pl-2" style="flex: 1">
            <v-menu v-model="reportToMenu" offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  outlined
                  :value="formatDateTo"
                  label="Do datuma"
                  dense
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="dateTo"
                no-title
                scrollable
                @input="reportToMenu = false"
              >
              </v-date-picker>
            </v-menu>
          </div>
          <div class="pl-2 pr-2" style="flex: 1">
            <v-select
              outlined
              dense
              label="Prodajno mjesto"
              v-model="location"
              :items="locations"
              item-text="name"
              item-value="id"
              return-object
            ></v-select>
          </div>
          <div style="flex: 1">
            <categories-filter
              ref="categoriesFilter"
              :items="itemReport"
              @setIdsForFilter="onSetIdsForFilter"
            ></categories-filter>
          </div>
          <div
            class="d-flex justify-center"
            style="flex: 1"
            :disabled="locationNotSelected"
          >
            <v-btn class="ml-2 mr-2" @click="getData()">
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
            <v-btn class="ml-2 mr-2" @click="changeWidth()">
              <v-icon>mdi-file-pdf-box</v-icon>
            </v-btn>
            <v-btn class="ml-2 mr-2" @click="generateExcel()">
              <v-icon>mdi-microsoft-excel</v-icon></v-btn
            >
          </div>
          <div class="d-flex justify-center" style="flex: 1; margin-top: -32px">
            <v-switch
              v-model="showUnpaid"
              label="Uključi neplaćene račune"
            ></v-switch>
          </div>
          <div class="d-flex justify-end">
            <v-btn :to="to">X</v-btn>
          </div>
        </div>
      </v-card>
    </div>

    <div class="d-flex justify-center mt-6">
      <div
        id="table_info"
        justify="center"
        style="
          max-width: 1200px;
          min-width: 1200px;
          font-size: 16px;
          font-family: 'Roboto-Regular';
        "
      >
        <!-- HTML Code: Place this code in the document's body (between the 'body' tags) where the table should appear -->
        <table id="firma" style="display: none; font-family: 'Roboto-Regular'">
          <tbody>
            <tr>
              <td>
                {{ company ? $options.filters.capitalize(company.name) : "" }}
              </td>
            </tr>
            <tr>
              <td>{{ company ? company.address + " " + company.city : "" }}</td>
            </tr>
            <tr>
              <td>OIB: {{ company ? company.oib : "" }}</td>
            </tr>
            <tr>
              <td>Prodajno mjesto: {{ location ? location.name : "" }}</td>
            </tr>
          </tbody>
        </table>
        <!-- Codes by Quackit.com -->

        <!--  -->
        <div style="font-family: font-family: Raleway, sans-serif;">
          <table
            justify="center"
            style="width: 100% !important"
            cellspacing="0"
            id="title"
          >
            <thead>
              <tr class="header">
                <th colspan="6">
                  <p style="font-weight: bold; font-size: 25px">
                    Prometa artikala
                  </p>
                </th>
              </tr>
              <tr class="pa-0 ma-0">
                <th colspan="6">
                  <p style="font-weight: bold; font-size: 12px">
                    za razdoblje od {{ formatDateFrom }} do
                    {{ formatDateTo }}
                  </p>
                </th>
              </tr>
            </thead>
          </table>
          <!-- Codes by Quackit.com -->
        </div>
        <div justify="center">
          <table
            justify="center"
            style="width: 100% !important"
            border="1"
            cellspacing="0"
            bordercolor="gray"
            id="tableTraffic"
          >
            <thead>
              <tr :class="['header', 'naslov']" style="display: none">
                <th colspan="6">
                  <span style="font-weight: bold">Promet po artiklima</span>
                </th>
              </tr>
              <tr :class="['header', 'naslov']">
                <th style="text-align: left">Artikl</th>
                <th style="text-align: right">Količina</th>
                <!-- <th style="text-align: right">J. cijena</th> -->
                <!-- <th style="text-align: right">Ukupna cijena</th> -->
              </tr>
            </thead>
            <tbody style="width: 100% !important">
              <tr v-for="(item, itIdx) in itemReport" :key="item.id + itIdx">
                <td style="text-align: left">{{ item.name }}</td>
                <td style="text-align: right">
                  {{
                    viewType === "pdf"
                      ? (item.quantity / 1000).toFixed(3).replace(".", ",")
                      : (item.quantity / 1000).toFixed(3)
                  }}
                </td>
                <!-- <td style="text-align: right">
                  {{
                    viewType === "pdf"
                      ? $options.filters
                          .money(item.singleItemPrice, 1, currency)
                          .replace("€", "")
                      : item.singleItemPrice
                  }}
                </td> -->
                <!-- <td style="text-align: right">
                  {{
                    viewType === "pdf"
                      ? $options.filters
                          .money(item.totalPrice, 1, currency)
                          .replace("€", "")
                      : item.totalPrice
                  }}
                </td> -->
              </tr>

              <!-- Ukupno redak -->
              <tr v-if="itemReport && itemReport.length > 0">
                <th :class="['header', 'naslov']" style="text-align: left">
                  Ukupno
                </th>
                <th :class="['header', 'naslov']" style="text-align: right">
                  {{
                    viewType === "pdf"
                      ? (totalReport.quantity / 1000)
                          .toFixed(3)
                          .replace(".", ",")
                      : (totalReport.quantity / 1000).toFixed(3)
                  }}
                </th>
                <!-- <th :class="['header', 'naslov']" style="text-align: right"> -->
                <!-- {{
                    viewType === "pdf"
                      ? $options.filters
                          .money(totalReport.singleItemPrice, 1, currency)
                          .replace("€", "")
                      : totalReport.singleItemPrice
                  }} -->
                <!-- </th> -->
                <!-- <th :class="['header', 'naslov']" style="text-align: right">
                  {{
                    viewType === "pdf"
                      ? $options.filters
                          .money(totalReport.totalPrice, 1, currency)
                          .replace("€", "")
                      : totalReport.totalPrice
                  }}
                </th> -->
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </v-container>
</template>
<script>

import { DAY, clone } from '@/plugins/utils'
import { df, auth } from '@/plugins/firebase'
import state from '@/state'
import 'jspdf-autotable'
import { saveExcel } from '@/modules/all-reports/xlsx/config.js'
import EventBus from '@/plugins/event-bus'
import { initPdf, setTable, setPageNumber } from '@/modules/all-reports/pdf/config.js'
import CategoriesFilter from '@/components/CategoriesFilter.vue'
import avansDiscount from '@/mixins/avans'

export default {
  name: 'Reports',
  inject: ['showMsgBox', 'showLoader', 'hideLoader'],
  components: { CategoriesFilter },
  data: function () {
    return {
      dateFrom: new Date(new Date().getTime() - DAY)
        .toISOString()
        .substr(0, 10),
      dateTo: new Date().toISOString().substr(0, 10),
      allReceipts: [],
      reportFromMenu: undefined,
      reportToMenu: undefined,
      company: undefined,
      tableWidth: '1200px',
      a4PortraitWidth: '575px',
      a4LandscapeWidth: '610px',
      targetModifier: '',
      viewType: 'pdf',
      itemReport: [],
      totalReport: {},
      currency: 'EUR',
      locations: [],
      location: null,
      showUnpaid: true
    }
  },
  computed: {
    to () {
      return { name: `${this.targetModifier}.all-reports.reportMenu` }
    },
    formatDateTo () {
      return new Intl.DateTimeFormat('hr-HR', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric'
      }).format(new Date(this.dateTo))
    },
    formatDateFrom () {
      return new Intl.DateTimeFormat('hr-HR', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric'
      }).format(new Date(this.dateFrom))
    },
    locationNotSelected () {
      return !this.location || !this.location.id
    }
  },
  async created () {
    this.margin = state.getMargin()
    await this.getPointsOfSale()
  },
  mounted () {
    var sideNav = this.$route.meta.sideNav
    switch (sideNav) {
      case 'Companies':
        break
      case 'Company':
        this.targetModifier = 'company'
        break
      case 'PointOfSale':
        this.targetModifier = 'pointOfSale'
        break
      case 'CashRegister':
        this.targetModifier = 'pointOfSale'
        break
    }

    EventBus.$on('set-mini', () => {
      this.margin = state.getMargin()
    })

    this.company = clone(state.getCurrentCompany())
    this.location = clone(state.getPointOfSale())

    if (this.company.type === 'pausalni_obrt') {
      this.showUnpaid = false
    } else {
      this.showUnpaid = true
    }
  },
  methods: {
    onSetIdsForFilter (ids) {
      this.filters = []
      this.filters.push(ids)
    },

    async getData () {
      this.showLoader()

      const dateFrom = new Date(this.dateFrom).getTime() / 1000
      const dateTo = new Date(this.dateTo).getTime() / 1000

      this.allReceipts = []

      let query = df.collection(`receipts/${state.getCurrentCompany().id}/receipts`)
        .where('issue_date_time', '>=', dateFrom)
        .where('issue_date_time', '<=', dateTo + 86399)
      if (this.location && this.location.id !== 'all') {
        query = query.where('location_details.id', '==', this.location.id)
      }

      await query.get()
        .then((documentSnapshots) => {
          if (documentSnapshots.docs && !documentSnapshots.docs.empty) {
            documentSnapshots.docs.forEach((item) => {
              var rcpt = item.data()
              if (this.showUnpaid === false) {
                if (rcpt.payment && (rcpt.payment.current_status === 'PAID' || rcpt.payment.current_status === 'REVERSED')) {
                  this.allReceipts.push(item.data())
                }
              } else {
                this.allReceipts.push(item.data())
              }
            })
          }
        })

      this.allReceipts.sort((a, b) => a.issue_date_time - b.issue_date_time) // b - a for reverse sort

      this.calculateData()
    },
    async calculateData () {
      this.allReceipts.forEach(receipt => {
        // Micanje poreza ako je dodan popust na iznos
        if (receipt && receipt.discount && receipt.discount.amount > 0 && receipt.discount.name !== 'Reprezentacija') {
          let totalRemove = receipt.discount.amount / 100

          if (receipt.discount.name === 'AVANS-STORNO-RECEIPT') {
            if (receipt.type.flag !== 'STORNO') {
              receipt.pdv = avansDiscount(receipt.pdv, receipt.tags)
            }
          } else {
            receipt.pdv.forEach((p, pkey) => {
              if (p.base > 0 && p.total > 0) {
                let totalPrice = p.base + p.total

                if (totalPrice >= (1 + (p.rate / 10000))) {
                  let removedAllPossible = false
                  let remove = totalRemove

                  // kreni unazad, uzmi ukupni iznos za skidanje i probaj skinuti
                  for (var j = totalRemove; j > 0 && removedAllPossible === false; j--) {
                  // ako oduzmes iznos i on je veci od 100 na kraju i ustvari si skinuo sve, tu zavrsi
                    if ((totalPrice - j) >= (1 + (p.rate / 10000))) {
                      removedAllPossible = true
                      remove = j
                    }
                  }

                  if (removedAllPossible === true) {
                    totalPrice -= remove
                    totalRemove -= remove
                  }
                }

                receipt.pdv[pkey].base = parseInt(parseFloat(totalPrice) / (1 + parseFloat(receipt.pdv[pkey].rate) / 10000))
                receipt.pdv[pkey].total = totalPrice - receipt.pdv[pkey].base
              }
            })
          }
        }
      })

      var itemReport = []
      var totalReport = {
        quantity: 0,
        totalPrice: 0,
        singleItemPrice: 0
      }

      var hasWarehouse = state.getCurrentCompany().warehouse === 'module'

      this.itemReport = []
      this.allReceipts.forEach(receipt => {
        if (receipt.items && receipt.items.length > 0) {
          receipt.items.forEach(item => {
            if (!hasWarehouse || (hasWarehouse && item.id.includes('.'))) {
              var idx = itemReport.findIndex(p => p?.id === item.id)
              if (idx > -1) {
                itemReport[idx].quantity += item.amt
                itemReport[idx].totalPrice += item.price / 100
              } else {
                itemReport.push({
                  id: item.id,
                  name: item.name,
                  quantity: item.amt,
                  totalPrice: item.price / 100,
                  singleItemPrice: item.single_item_price / 100
                })
              }
            }
          })
        }
      })

      if (this.filters && this.filters.flat().length > 0) {
        const newItemReport = await this.$refs.categoriesFilter.getCategoriesByItemId(itemReport)
        itemReport = newItemReport
      }

      this.itemReport = itemReport
      this.itemReport.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))

      itemReport.forEach((_, itIdx) => {
        itemReport[itIdx].singleItemPrice = Math.round(itemReport[itIdx].singleItemPrice * 100) / 100
        itemReport[itIdx].price = Math.round(itemReport[itIdx].price * 100) / 100

        totalReport.quantity += itemReport[itIdx].quantity
        totalReport.singleItemPrice += itemReport[itIdx].singleItemPrice
        totalReport.totalPrice += itemReport[itIdx].totalPrice
      })

      this.totalReport = totalReport
      this.hideLoader()
    },
    changeWidth () {
      this.showLoader('', { opacity: 1 })
      document.getElementById('table_info').style.maxWidth = this.a4PortraitWidth
      document.getElementById('table_info').style.minWidth = this.a4PortraitWidth
      document.getElementById('table_info').style.fontSize = '80%'

      this.generatePdf()
    },
    async generatePdf () {
      const that = this
      // eslint-disable-next-line new-cap
      var doc = await initPdf()

      doc = await setTable(doc, '#firma', 10)
      var finalY = doc.lastAutoTable.finalY || 10
      doc = await setTable(doc, '#title', finalY)
      finalY = doc.lastAutoTable.finalY || 10
      doc = await setTable(doc, '#tableTraffic', finalY)

      doc = await setPageNumber(doc)

      doc.save('Rekapitulacija prometa artikala.pdf')

      document.getElementById('table_info').style.maxWidth = that.tableWidth
      document.getElementById('table_info').style.minWidth = that.tableWidth
      document.getElementById('table_info').style.fontSize = '100%'
      this.viewType = 'pdf'
      that.hideLoader()
    },
    generateExcel () {
      this.showLoader('', { opacity: 1 })
      this.viewType = 'excel'
      setTimeout(() => {
        var tables = ['firma', 'title', 'tableTraffic']
        var fileName = 'Rekapitulacija prometa artikala.xlsx'

        saveExcel(tables, fileName)

        this.viewType = 'pdf'
        this.hideLoader()
      }, 1000)
    },
    async getPointsOfSale () {
      const docRef = df.doc(`user_locations/${auth.currentUser.uid}.${state.getCurrentCompany().id}`)
      docRef.onSnapshot(doc => {
        var locations = Object.values(doc.data().locations)
        locations.forEach((location, index) => {
          this.locations.push(location)
        })
      })
      // const all = { name: 'Sva', id: 'all' }
      // this.locations.push(all)
      // this.location = all
    }
  }
}
</script>
<style scoped>
#tableTraffic {
  border: 1px solid #767676;
}

#tableTax {
  border: 1px solid #767676;
}

.naslov {
  background-color: #d3d3d3;
  border: 1px solid black;
}
.v-btn {
  min-width: 40px !important;
  padding: 0px !important;
}
</style>
