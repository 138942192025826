<template>
  <v-dialog
    v-model="visible"
    @keydown.esc="close"
    persistent
    scrollable
    max-width="600"
    style="z-index: 6"
  >
    <v-form ref="addDiscountForm" @submit.prevent="submit">
      <v-card
        class="overflow-auto"
        :class="{ 'mobile-card': $vuetify.breakpoint.smAndDown }"
      >
        <v-card-title class="d-flex" style="vertical-align: middle">
          <v-row class="pa-3 mb-2">
            <h3 class="text--secondary">Popust</h3>
            <v-spacer></v-spacer>
            <v-icon large @click="close">mdi-close</v-icon>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row class="flex-wrap justify-center">
            <v-col>
              <v-row class="pd-5 pt-5">
                <v-textarea
                  rows="2"
                  class="pa-2"
                  dense
                  outlined
                  label="Naziv"
                  autofocus
                  v-model="name"
                  :rules="[rules.req]"
                ></v-textarea>
              </v-row>
              <v-row class="pd-5">
                <v-col>
                  <v-select
                    dense
                    outlined
                    label="Vrsta popusta"
                    v-model="type"
                    item-text="name"
                    item-value="value"
                    :items="discountTypes"
                    :rules="[rules.req]"
                  >
                  </v-select>
                </v-col>
                <v-col cols="3" v-if="type == 'RATE'">
                  <!-- <v-text-field dense outlined label="Postotak" v-model="rate">
                  </v-text-field> -->
                  <quantity-input
                    dense
                    outlined
                    label="Postotak"
                    v-model="rate"
                    suffix="%"
                  ></quantity-input>
                </v-col>
                <v-col cols="3" v-if="type == 'AMOUNT'">
                  <!-- <v-text-field dense outlined label="Iznos" v-model="amount">
                  </v-text-field> -->

                  <quantity-input
                    dense
                    outlined
                    label="Iznos"
                    v-model="amount"
                    :suffix="currency"
                  ></quantity-input>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-select
                    v-model="selectedUsers"
                    :items="users"
                    label="Korisnici koji mogu koristiti popust"
                    multiple
                    outlined
                    dense
                    clearable
                    item-text="name"
                    item-value="id"
                  >
                  </v-select>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-center pb-5">
          <v-btn
            class="white--text okButton"
            height="45"
            :loading="submitting"
            :disabled="submitting"
            type="submit"
          >
            Dodaj popust
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import { df, auth } from '@/plugins/firebase'
import { v4 as uuidv4 } from 'uuid'
import rules from '@/plugins/rules'
import { clone } from '@/plugins/utils'
import state from '@/state'
import QuantityInput from '../../../../components/QuantityInput.vue'

export default {
  inject: ['showMsgBox', 'showLoader', 'hideLoader'],
  components: { QuantityInput },
  data: () => ({
    visible: false,
    submitting: false,
    discountTypes: [],
    name: undefined,
    type: undefined,
    rate: undefined,
    amount: undefined,
    selectedUsers: [],
    users: undefined,
    currency: 'EUR',
    rules: {
      req: rules.req(),
      eq: rules.eq
    }
  }),
  mounted () {
    this.currency = 'HRK'
    if (Date.now() / 1000 > 1672527599) {
      this.currency = 'EUR'
    }
    this.discountTypes = [
      { name: this.$t('$vuetify.discount.rate'), value: 'RATE' },
      { name: this.$t('$vuetify.discount.amount'), value: 'AMOUNT' },
      { name: this.$t('$vuetify.discount.manual'), value: 'MANUAL' }
    ]

    df.collection(`companies/${state.getCurrentCompany().id}/users`)
      .get()
      .then((documentSnapshots) => {
        this.loading = false
        if (documentSnapshots.docs && !documentSnapshots.docs.empty) {
          const users = []
          documentSnapshots.docs.forEach((user) => {
            if (user.data().config.active === true) {
              users.push(user.data().user)
            }
          })
          this.users = clone(users.sort((a, b) => a.name.localeCompare(b.name)))
        }
      })
  },
  methods: {
    open () {
      this.visible = true
      this.submitting = false
    },
    close () {
      this.resetInputs()
    },
    resetInputs () {
      this.name = undefined
      this.visible = false
    },
    submit () {
      if (!this.$refs.addDiscountForm.validate()) return
      //   this.showLoader()
      this.submitting = true
      const reqId = uuidv4()

      const payload = {
        action: {
          operation: 'set',
          entity: 'discount',
          params: {
            name: this.name,
            type: this.type,
            company_id: state.getCurrentCompany().id
          }
        }
      }

      if (this.selectedUsers.length > 0) {
        payload.action.params.users = this.selectedUsers
      }

      if (this.rate !== undefined && parseInt(this.rate) > 0) {
        this.rate = parseFloat(this.rate.toString().replace(',', '.'))
        payload.action.params.rate = parseInt(this.rate * 100)
      }

      if (this.amount !== undefined && parseInt(this.amount) > 0) {
        this.amount = parseFloat(this.amount.toString().replace(',', '.'))
        payload.action.params.amount = parseInt(this.amount * 100)
      }

      const resp = payload.action.params

      try {
        df.doc(`request/${reqId}`)
          .set({
            user_id: `${auth.currentUser.uid}`,
            device_id: 'web',
            created: `${new Date().getTime()}`,
            type: 'discount',
            payload: btoa(
              unescape(encodeURIComponent(JSON.stringify(payload)))
            )
          })
          .then(() => {
            // setTimeout(() => {
            // this.$emit('success', this.name)
            this.$emit('success', { ...resp, ...{ action: 'ADD' } })
            this.$refs.addDiscountForm.reset()
            this.submitting = false
            this.visible = false
            // }, 2000);
          })
          .catch((err) => {
            this.showMsgBox({
              text: err && err !== '' ? err : 'An error has occurred',
              actions: ['cancel'],
              cancelBtnText: 'OK',
              color: 'error'
            })
            this.submitting = false
          })
      } catch (err) {
        this.showMsgBox({
          text: err && err !== '' ? err : 'An error has occurred',
          actions: ['cancel'],
          cancelBtnText: 'OK',
          color: 'error'
        })
        this.submitting = false
      }
    }
  }
}
</script>
