<template>
  <v-container fluid :style="`margin-left: ${margin}`">
    <v-data-iterator :items="groups" :items-per-page="-1" hide-default-footer>
      <template v-slot:default="props">
        <v-row>
          <v-col
            v-for="item in props.items"
            :key="item.groupName"
            cols="3"
            sm="3"
            md="3"
            lg="3"
          >
            <v-card elevation="5">
              <v-card-title
                card-title-font-size="5"
                height="10"
                class="pa-2 font-weight-bold text-md-center justify-center"
              >
                {{ item.groupName }}
              </v-card-title>
              <v-divider></v-divider>
              <v-list dense>
                <v-list-item
                  class="text-no-wrap rounded-lg"
                  style="
                    background-color: #2597c5;
                    color: white;
                    font-weight: bold;
                    margin: 5px;
                  "
                  v-for="(it, index) in item.items"
                  :key="index"
                  :to="it.to"
                >
                  <v-list-item-content style="text-align: center">{{
                    it.itemName
                  }}</v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </v-col>
        </v-row>
      </template>
    </v-data-iterator>
  </v-container>
</template>
<script>

import 'jspdf-autotable'
import state from '@/state'
import EventBus from '@/plugins/event-bus'

export default {
  name: 'Reports',
  inject: ['showMsgBox', 'showLoader', 'hideLoader'],
  components: { },
  data: function () {
    return {
      margin: '56px',
      groups: [{
        groupName: 'Izvještaji',
        items: [{
          itemName: 'Rekapitulacija prometa',
          to: { name: 'pointOfSale.all-reports.recapitulation' }
        },
        {
          itemName: 'Rekapitulacija kupci',
          to: { name: 'pointOfSale.all-reports.recapitulationBuyers' }
        },
        {
          itemName: 'Porezno izvješće',
          to: { name: 'pointOfSale.all-reports.taxReport' }
        },
        {
          itemName: 'Promet artikala',
          to: { name: 'pointOfSale.all-reports.itemReport' }
        },
        {
          itemName: 'Porez na potrošnju',
          to: { name: 'pointOfSale.all-reports.pnpReport' }
        },
        {
          itemName: 'Rekapitulacija napojnica',
          to: { name: 'pointOfSale.all-reports.recapitulationTips' }
        }]
      },
      {
        groupName: 'Cjenik',
        items: [{
          itemName: 'Pregled cjenika',
          to: { name: 'pointOfSale.all-reports.itemPricelistReport' }
        }]
      }
      ],
      selectedTemplate: ''
    }
  },
  created () {

  },
  mounted () {
    this.margin = state.getMargin()
    EventBus.$on('set-mini', () => {
      this.margin = state.getMargin()
    })
  },
  watch: {

  },
  computed: {

  },
  methods: {

  }
}
</script>
