<template>
  <v-dialog
    v-model="visible"
    content-class="my-custom-dialog"
    transition="dialog-top-transition"
    scrollable
    persistent
    max-width="350"
    max-height="600"
    style="height: 50px"
  >
    <v-form ref="addDomainForm" @submit.prevent="submit">
      <v-card
        tile
        color="white"
        style="border-radius: 25px !important"
        class="overflow-auto pb-0"
        :class="{ 'mobile-card': $vuetify.breakpoint.smAndDown }"
      >
        <v-card-title
          class="d-flex"
          style="
            vertical-align: middle;
            background-color: #1577da;

            height: 58px;
            color: white;
          "
        >
          <span class="text-h5" style="font-weight: bold">Domena</span>
        </v-card-title>
        <v-card-text class="pb-0 mb-0">
          <v-row class="pt-2">
            <v-col>
              <label style="font-weight: bold; font-size: 16px">
                Firma: {{ company ? company.name : "" }}
              </label>
            </v-col>
          </v-row>
          <v-row class="pb-0 mb-0">
            <v-col class="pb-0 mb-0">
              <v-text-field
                dense
                class="pb-0 mb-0"
                outlined
                label="Unesite željenu domenu"
                v-model="domain"
                @keypress="checkInput($event)"
                :rules="[rules.req, rules.reserved]"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex pb-3 pt-0 mt-0 pr-4 justify-end">
          <v-btn class="btn dangerButton" @click="close"> Odustani </v-btn>
          <v-btn
            id="success"
            class="btn successButton"
            :disabled="submitting"
            type="submit"
          >
            Spremi
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import rules from '@/plugins/rules'
import { auth, df, firebase } from '@/plugins/firebase'

export default {
  inject: ['showMsgBox'],
  data: function () {
    return {
      company: undefined,
      domain: undefined,
      submitting: false,
      visible: false,
      rules: {
        req: rules.req()
      },
      reservedDomains: []
    }
  },
  methods: {
    open (company) {
      this.company = company
      this.visible = true
      df.doc('companies_domain/data').get()
        .then(doc => {
          this.reservedDomains = doc?.data()?.domains || []
        })
      this.rules.reserved = (val) => !this.reservedDomains.includes(val) || `Domena ${val} je već zauzeta.`
      this.setDefaultDomain()
    },
    close () {
      this.visible = false
    },
    setDefaultDomain () {
      this.domain = this.company.name
      //  problem with Đ https://stackoverflow.com/questions/2362810/why-doesnt-%C4%90-get-flattened-to-d-when-removing-accents-diacritics
      const map = {
        d: 'đ',
        D: 'Đ'
      }
      for (var pattern in map) {
        this.domain = this.domain.replace(new RegExp(map[pattern], 'g'), pattern)
      }
      this.domain = this.domain.normalize('NFD').replace(/\p{Diacritic}/gu, '').replace(/[^0-9a-zA-Z]+/g, '').substring(0, 10).toLowerCase()
    },
    checkInput (ev) { // change this with a custom directive  this accepts custom regex and checks input field
      const maskRe = /[A-Za-z0-9]/
      if (!maskRe.test(ev.key)) {
        ev.preventDefault()
        return false
      }
    },
    resetDefaults () {
      this.company = undefined
      this.domain = undefined
    },
    submit () {
      if (!this.$refs.addDomainForm.validate()) return
      this.submitting = true
      df.doc(`companies/${this.company.id}`).update({ domain: this.domain })
      df.doc(`user_companies/${auth.currentUser.uid}`).update({ [`companies.${this.company.id}.domain`]: this.domain })
        .then(() => {
          df.doc('companies_domain/data').update({
            domains:
              firebase.firestore.FieldValue.arrayUnion(
                this.domain
              )
          })
            .then(() => {
              this.$emit('success')
            })
            .catch(() => {
              this.showMsgBox({ text: 'Pogreška prilikom zapisa podataka', actions: ['cancel'], cancelBtnText: 'OK', color: 'error' })
              this.submitting = false
              this.close()
            })
            .finally(() => {
              this.submitting = false
              this.close()
            })
        })
        .catch(() => {
          this.showMsgBox({ text: 'Pogreška prilikom zapisa podataka', actions: ['cancel'], cancelBtnText: 'OK', color: 'error' })
          this.submitting = false
          this.close()
        })
    }
  }
}
</script>
