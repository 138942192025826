<template>
  <div>
    <div class="videos">
      <ul class="videos__list">
        <li v-for="(video, index) in videos" :key="index" class="videos__item">
          <div>{{ video.title }}</div>
          <LazyYoutubeVideo
            :src="video.url"
            :preview-image-size="video.preview_image_size"
            aspect-ratio="16:9"
            :thumbnail-listeners="{}"
          />
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import applicationSettings from '@/mixins/applicationSettings'
import LazyYoutubeVideo from 'vue-lazy-youtube-video'
import { df } from '@/plugins/firebase'

export default {
  mounted () {
    this.getVideos()
  },
  name: 'Reports',
  mixins: [applicationSettings],
  components: {
    LazyYoutubeVideo
  },
  inject: ['showMsgBox', 'showLoader', 'hideLoader'],
  data: function () {
    return {
      videos: [],
      listeners: []
    }
  },
  watch: {

  },
  computed: {

  },
  beforeDestroy () {
    if (this.listeners) {
      this.detachListeners()
    }
  },
  methods: {
    getVideos () {
      const listener = df.doc('project_constants/instructions').onSnapshot(doc => {
        this.videos = doc.data().videos
      })
      this.listeners.push(listener)
    }
  }

}
</script>
<style lang="scss">
$gap: 40px;

.videos {
  &__list {
    display: flex;
    flex-wrap: wrap;
    padding-left: 65px !important;
    margin: {
      top: 0;
      bottom: 0;
    }
    list-style: none;
  }

  &__item {
    width: calc(20% - #{$gap / 5});
    padding: 5px;
    padding-top: 10px;

    &:nth-child(n + 6) {
      margin-top: $gap;
    }
  }
}
</style>
