var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{staticStyle:{"height":"50px"},attrs:{"content-class":"my-custom-dialog","transition":"dialog-top-transition","scrollable":"","persistent":"","max-width":"600","max-height":"600"},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[(_vm.submitting)?_c('v-overlay',{attrs:{"absolute":"","z-index":"10"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64","color":"primary"}})],1):_vm._e(),_c('v-form',{ref:"editUserResourcesForm",on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('v-card',{staticClass:"pb-3 overflow-auto",class:[
          { 'mobile-card': _vm.$vuetify.breakpoint.smAndDown },
          { 'card-users': !_vm.$vuetify.breakpoint.smAndDown } ],attrs:{"tile":"","color":"white"}},[_c('v-card-title',{staticClass:"d-flex",staticStyle:{"vertical-align":"middle","background-color":"#1577da","height":"58px","color":"white"}},[_c('span',{staticClass:"text-h5",staticStyle:{"font-weight":"bold"}},[_vm._v("Korisnik")])]),_c('v-card-text',[_c('v-row',{staticClass:"pt-3"},[_c('v-col',{attrs:{"cols":"9"}},[_c('label',{staticClass:"font-weight-bold"},[_vm._v("Korisničko ime: "+_vm._s(_vm.user && _vm.user.email ? _vm.user.email : ""))])]),_c('v-col',{staticClass:"pt-0 mt-0 pb-0 mb-0 pr-0",staticStyle:{"text-align":"right"},attrs:{"cols":"3"}},[_c('v-switch',{staticClass:"pt-0 mt-3",staticStyle:{"font-weight":"bold"},attrs:{"hide-details":"","dense":"","color":"#1777da","label":_vm.user.active ? 'Aktivan' : 'Neaktivan'},model:{value:(_vm.user.active),callback:function ($$v) {_vm.$set(_vm.user, "active", $$v)},expression:"user.active"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0 mt-0 pb-0 mb-0"},[_c('v-select',{attrs:{"label":"Firma","items":[_vm.company],"outlined":"","dense":"","hide-details":"","item-text":"name","item-value":"id","small-chips":"","deletable-chips":"","disabled":"","messages":!_vm.selectedCompany
                    ? 'Brisanjem iz firme brišete korisnika sa svih prodajnih mjesta.'
                    : ''},model:{value:(_vm.selectedCompany),callback:function ($$v) {_vm.selectedCompany=$$v},expression:"selectedCompany"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-3 mt-0 pb-0 mb-0"},[_c('v-select',{attrs:{"label":"Prodajna mjesta","multiple":"","outlined":"","dense":"","hide-details":"","items":_vm.locations,"item-text":"name","item-value":"id","return-object":"","small-chips":"","disabled":_vm.superAdmin,"deletable-chips":"","loading":_vm.loadingLocations},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('v-row',[_c('v-col',[_c('v-list-item',{attrs:{"ripple":""},on:{"click":_vm.toggleAll}},[_c('v-list-item-action',[_c('v-icon',{attrs:{"color":_vm.selectedLocations.length > 0
                                ? 'indigo darken-4'
                                : ''}},[_vm._v(" "+_vm._s(_vm.icon)+" ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Odaberi sve ")])],1)],1)],1),_c('v-divider',{staticClass:"my-4",attrs:{"vertical":""}}),_c('v-col',{staticClass:"pt-5"},[_c('v-btn',{attrs:{"text":"","plain":""},on:{"click":_vm.resetToInitialState}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-restore")]),_vm._v(" Vrati na početno stanje ")],1)],1)],1),_c('v-divider',{staticClass:"mt-2"})]},proxy:true}]),model:{value:(_vm.selectedLocations),callback:function ($$v) {_vm.selectedLocations=$$v},expression:"selectedLocations"}})],1)],1),_c('v-row',{staticClass:"pt-3"},[_c('v-col',{staticClass:"pt-0 mt-0 pb-0 mb-0"},[_c('v-text-field',{staticClass:"text-capitalize",attrs:{"label":_vm.$t('$vuetify.formDetails.firstName'),"hide-details":"","rules":[_vm.rules.req],"outlined":"","dense":""},model:{value:(_vm.user.name),callback:function ($$v) {_vm.$set(_vm.user, "name", $$v)},expression:"user.name"}})],1),_c('v-col',{staticClass:"pt-0 mt-0 pb-0 mb-0"},[_c('v-text-field',{staticClass:"text-capitalize",attrs:{"hide-details":"","label":_vm.$t('$vuetify.formDetails.lastName'),"disabled":_vm.superAdmin,"rules":[_vm.rules.req],"outlined":"","dense":""},model:{value:(_vm.user.surname),callback:function ($$v) {_vm.$set(_vm.user, "surname", $$v)},expression:"user.surname"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-3 mt-0 pb-0 mb-0"},[_c('v-text-field',{attrs:{"hide-details":"","label":"Korisničko ime","disabled":"","outlined":"","dense":""},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-3 mt-0 pb-0 mb-0"},[_c('v-text-field',{attrs:{"label":_vm.$t('$vuetify.formDetails.password'),"outlined":"","disabled":_vm.superAdmin,"rules":[
                  _vm.rules.validPass([
                    _vm.user.name,
                    _vm.user.surname,
                    _vm.user.email ? _vm.user.email.split('@')[0] : '' ]) ],"type":_vm.passVisible ? 'text' : 'password',"append-icon":_vm.passVisible ? 'mdi-eye' : 'mdi-eye-off',"dense":""},on:{"click:append":function () { return (_vm.passVisible = !_vm.passVisible); }},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-1 mt-0 pb-0 mb-0"},[_c('v-text-field',{attrs:{"label":"PIN za prijavu na blagajnu","validate-on-blur":"","outlined":"","dense":"","rules":[
                  _vm.rules.pinused,
                  _vm.rules.req,
                  _vm.rules.minLen(4),
                  _vm.rules.maxLen(4) ]},on:{"input":_vm.checkPincodeAvailability},model:{value:(_vm.user.pincode),callback:function ($$v) {_vm.$set(_vm.user, "pincode", $$v)},expression:"user.pincode"}})],1)],1),_c('v-row',{staticClass:"pt-3 mt-0"},[_c('v-col',{staticClass:"pt-0 mt-0 pb-0 mb-0"},[_c('v-select',{attrs:{"label":"Privilegije","multiple":"","items":_vm.roles,"item-text":"name","item-value":"name","small-chips":"","deletable-chips":"","outlined":"","dense":"","rules":[_vm.rules.arrReq]},model:{value:(_vm.selectedRoles),callback:function ($$v) {_vm.selectedRoles=$$v},expression:"selectedRoles"}})],1)],1),_c('v-row',{staticClass:"pt-0 mt-0 pb-0 mb-0"},[_c('v-col',{staticClass:"pt-0 mt-0 pb-0 mb-0"},[_c('v-text-field',{attrs:{"label":"Oib","outlined":"","dense":"","rules":_vm.selectedProdavac ? [_vm.rules.req, _vm.rules.oib] : [_vm.rules.oib]},model:{value:(_vm.user.oib),callback:function ($$v) {_vm.$set(_vm.user, "oib", $$v)},expression:"user.oib"}})],1),_c('v-col',{staticClass:"pt-0 mt-0 pb-0 mb-0"},[_c('v-text-field',{attrs:{"label":"Oznaka operatera","outlined":"","dense":""},model:{value:(_vm.user.designation),callback:function ($$v) {_vm.$set(_vm.user, "designation", $$v)},expression:"user.designation"}})],1)],1)],1),_c('v-card-actions',{staticClass:"d-flex pt-0 mt-0 justify-end"},[_c('v-btn',{staticClass:"btn dangerButton",on:{"click":_vm.close}},[_vm._v(" Odustani ")]),_c('v-btn',{staticClass:"btn successButton",attrs:{"id":"success","disabled":_vm.submitting,"type":"submit"}},[_vm._v(" Spremi ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }