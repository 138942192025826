import { utils, writeFileXLSX } from 'xlsx'

export const saveExcel = async (tables, fileName) => {
  var htmlTables = []
  var workSheets = []
  var jsons = []
  var oneJson = []
  tables.forEach(tableName => {
    htmlTables.push(document.getElementById(tableName))
  })

  htmlTables.forEach(htmlTable => {
    workSheets.push(utils.table_to_sheet(htmlTable))
  })

  workSheets.forEach(worksheet => {
    jsons.push(utils.sheet_to_json(worksheet, { header: 1 }))
  })

  jsons.forEach(json => {
    oneJson = oneJson.concat(json)
  })

  const worksheet = utils.json_to_sheet(oneJson)

  const newWorkbook = utils.book_new()

  utils.book_append_sheet(newWorkbook, worksheet, 'worksheet')
  writeFileXLSX(newWorkbook, fileName)
}
