<template>
  <div style="width: 100%" class="fill-height">
    <v-container>
      <v-row
        v-if="listView"
        style="margin-top: 0px"
        class="d-flex justify-center"
      >
        <v-col cols="12" sm="11" lg="11" :class="['px-0']">
          <v-card v-for="(discount, i) in sortedDiscounts" :key="i" hover>
            <v-row
              :class="[
                'ma-0',
                discount.active === true
                  ? 'status-ok-exp'
                  : 'status-failed-exp',
              ]"
            >
              <v-col
                cols="5"
                sm="5"
                md="3"
                lg="3"
                style="min-width: 100px; max-width: 100%"
                :class="[densityPadding, 'flex-grow-1']"
              >
                <div class="caption grey--text">Naziv popusta</div>
                <div
                  class="text-subtitle-1 one-liner-text"
                  @mouseover="hoverOver(`hover-${discount.id}`, discount)"
                  @mouseleave="discount[`hover-${discount.id}`] = false"
                  :id="`hover-${discount.id}`"
                  :ref="`hover-${discount.id}`"
                >
                  {{ capitalizeFirst(discount.name) }}
                </div>
                <v-tooltip v-model="discount[`hover-${discount.id}`]" bottom>
                  <!--Fake activator to avoid an attach property which is not working properly -->
                  <template v-slot:activator="{ on }">
                    <div v-on="on"></div>
                  </template>
                  <div style="max-width: 300px">
                    {{ capitalizeFirst(discount.name) }}
                  </div>
                </v-tooltip>
              </v-col>
              <v-col cols="3" sm="3" md="2" :class="[densityPadding]">
                <div class="caption grey--text">Vrsta popusta</div>
                <div>{{ translateType(discount.type) }}</div>
              </v-col>

              <v-col cols="3" sm="3" md="2" :class="[densityPadding]">
                <div
                  class="caption grey--text"
                  v-if="discount.type !== 'MANUAL'"
                >
                  Iznos popusta
                </div>
                <div v-if="discount.type == 'RATE'">
                  {{ discount.rate / 100 }} %
                </div>
                <div v-if="discount.type == 'AMOUNT'">
                  {{ (discount.amount / 100) | money(0, currency) }}
                </div>
              </v-col>
              <v-col cols="3" sm="3" md="2" :class="[densityPadding]">
                <div class="caption grey--text">Aktivan</div>
                <div>
                  {{ showActivity(discount.active) }}
                </div>
              </v-col>
              <v-col cols="2" md="1" lg="1" :class="[densityPadding]">
                <div class="caption grey--text text-center">Akcije</div>
                <div class="d-flex align-start justify-center">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="mt-0 pt-0"
                        :x-small="density === 'greater'"
                        v-on="on"
                        v-bind="attrs"
                        icon
                        @click="openEditDiscountForm(discount)"
                        @keypress.enter="openEditDiscountForm(discount)"
                      >
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                    <span>Uredi popust</span>
                  </v-tooltip>
                </div>
              </v-col>
            </v-row>
            <v-divider></v-divider>
          </v-card>
        </v-col>
      </v-row>
      <v-row
        v-else
        class="d-flex justify-center align-center fill-height ma-0 pa-0"
      >
        <v-card
          class="align-center ma-5"
          v-for="(discount, i) in sortedDiscounts"
          :key="i"
          hover
          height="300"
          width="250"
        >
          <div class="mr-4 ml-4">
            <div>
              <h3 class="selling-point-title-text one-liner-text">
                {{ capitalizeFirst(discount.name) }}
              </h3>
            </div>
          </div>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-on="on"
                  v-bind="attrs"
                  icon
                  @click="openEditDiscountForm(discount)"
                  @keypress.enter="openEditDiscountForm(discount)"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>Uredi artikl</span>
            </v-tooltip>
          </v-card-actions>
        </v-card>
      </v-row>
      <infinite-loading
        ref="InfiniteLoading"
        @infinite="infiniteHandler"
        spinner="waveDots"
      >
        <div slot="no-more" class="text--secondary font-italic"></div>
        <div slot="no-results" class="text--secondary font-italic"></div>
      </infinite-loading>
    </v-container>
    <add-discount ref="addDiscount" @success="refreshDiscounts"></add-discount>
    <edit-discount
      ref="editDiscount"
      @success="refreshDiscounts"
    ></edit-discount>
  </div>
</template>
<script>
import { df } from '@/plugins/firebase'
import { clone } from '@/plugins/utils'
import state from '@/state'
import addDiscount from '@/modules/company/components/discount/AddDiscount'
import editDiscount from '@/modules/company/components/discount/EditDiscount'
import InfiniteLoading from 'vue-infinite-loading'
import flow from '@/mixins/flow'
import applicationSettings from '@/mixins/applicationSettings' // don't move.. detaches firebase listeners
import barcodeChecker from '@/mixins/barcodeChecker'
export default {
  inject: ['showLoader', 'confirm', 'confirmClose', 'hideLoader'],
  mixins: [flow, applicationSettings, barcodeChecker],
  components: { addDiscount, InfiniteLoading, editDiscount },
  data: () => ({
    loading: false,
    lastiItem: false,
    options: {},
    pagination: {
      itemsPerPage: 10, // TODO: change to 30 after testing.
      page: 1
    },
    headers: [],
    discounts: [],
    sortedDiscounts: [],
    avatarSize: 120,
    lastVisible: '',
    listeners: [],
    newItem: false,
    multiplier: 0,
    currency: 'EUR'
  }),
  mounted () {
    this.currency = 'HRK'
    if (Date.now() / 1000 > 1672527599) {
      this.currency = 'EUR'
    }
    this.getMultiplier()
  },
  computed: {
    listView () {
      return state.isListView()
    }
  },
  watch: {
    listView (nv, ov) {
      if (nv !== ov) {
        if (this.$refs.InfiniteLoading) {
          this.$refs.InfiniteLoading.stateChanger.reset()
        }
      }
    },
    companyId (nv, ov) {
      if (nv !== ov) {
        this.detachListeners()
        this.items = []
        if (this.$refs.InfiniteLoading) {
          this.$refs.InfiniteLoading.stateChanger.reset()
        }
      }
    },
    search () {
      this.lastVisible = ''
      this.items = []
      if (this.$refs.InfiniteLoading) {
        this.$refs.InfiniteLoading.stateChanger.reset()
      }
    }
  },
  methods: {
    showActivity (active) {
      if (active) {
        return 'Aktivan'
      } else {
        return 'Neaktivan'
      }
    },
    translateType (type) {
      switch (type) {
        case 'RATE':
          return 'Postotni popust'
        case 'AMOUNT':
          return 'Apsolutni popust'
        case 'MANUAL':
          return 'Ručni upis'
      }
    },
    capitalizeFirst (name) {
      return name.charAt(0).toUpperCase() + name.slice(1)
    },
    hoverOver (ref, item) {
      const container = this.$refs[ref]
      if (!container || !container[0]) return
      if (container[0].associateHeight < container[0].scrollHeight) {
        item[ref] = true
      }
    },
    openaddDiscount () {
      this.$refs.addDiscount.open()
    },
    openEditDiscountForm (discount) {
      this.$refs.editDiscount.open(discount)
    },
    refreshDiscounts (discount) {
      // if (discount.action === 'ADD') {
      //   this.newItem = true
      //   this.items.unshift(
      //     { ...item, ...{ status: 'PROCESSING' } }
      //   )
      //   this.$vuetify.goTo(0)
      // } else if (item.action === 'EDIT') {
      //   this.items = this.items.map(_item => {
      //     if (_item.id === item.id) {
      //       _item.name = item.name
      //       _item.status = 'PROCESSING'
      //     }
      //     return _item
      //   })
      // }
    },
    modifyItems (change) {
      if (change.type === 'added') {
        if (this.discounts.some(discount =>
          (discount.id === change.doc.data().id) ||
          (discount.name.toLowerCase() === change.doc.data().name)
        )) {
          this.discounts = this.discounts.map(discount => {
            let ret = discount
            if (((discount.id === change.doc.data().id) || (discount.name === change.doc.data().name.toLowerCase())) && this.newItem) {
              ret = change.doc.data()
            }
            return { ...ret, ...{ [`hover-${change.doc.data().id}`]: false } }
          })
        } else {
          this.discounts.push({ ...change.doc.data(), ...{ [`hover-${change.doc.data().id}`]: false } })
        }
      }
      if (change.type === 'modified') {
        this.discounts = this.discounts.map(discount => {
          let ret = discount
          if (discount.id === change.doc.data().id) {
            ret = change.doc.data()
          }
          return { ...ret, ...{ [`hover-${change.doc.data().id}`]: false } }
        })
      }
      this.sortedDiscounts = clone(this.discounts.sort((a, b) => a.name.localeCompare(b.name)))
    },
    infiniteHandler ($state) {
      const search = 'name'
      const where = this.search ? this.search.toLowerCase() : ''

      let query = df.collection(`companies/${state.getCurrentCompany().id}/discounts`)
        .orderBy(search, 'asc')
        .limit(10)
        .startAfter(this.lastVisible)
      if (where) {
        query = query.where(search, '>=', where).where(search, '<=', where + '\uf8ff')
      }

      console.log('query', query)
      const listener = query
        .onSnapshot((doc) => {
          doc.docChanges().forEach(change => {
            this.modifyItems(change)
          })
          if (doc && !doc.empty) {
            if (doc.docs[doc.docs.length - 1]) {
              this.lastVisible = doc.docs[doc.docs.length - 1]
            }
            $state.loaded()
          } else {
            $state.complete()
          }
        }
        // (error) => {
        //   console.log('error', error)
        // }
        )

      this.listeners.push(listener)
    },
    getMultiplier () {
      const listener = df.doc('project_constants/config').onSnapshot(doc => {
        this.config = doc.data()
        this.multiplier = doc.data().item_amount.decimal_multiplier
      })
      this.listeners.push(listener)
    }
  }
}
</script>
