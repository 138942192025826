<template>
  <div>
    <v-dialog
      v-model="visible"
      content-class="my-custom-dialog"
      transition="dialog-top-transition"
      scrollable
      persistent
      max-width="600"
      max-height="600"
      style="height: 50px"
    >
      <v-overlay v-if="submitting" absolute z-index="10">
        <v-progress-circular
          indeterminate
          size="64"
          color="primary"
        ></v-progress-circular>
      </v-overlay>
      <v-form ref="editUserResourcesForm" @submit.prevent="submit">
        <v-card
          tile
          color="white"
          class="pb-3 overflow-auto"
          :class="[
            { 'mobile-card': $vuetify.breakpoint.smAndDown },
            { 'card-users': !$vuetify.breakpoint.smAndDown },
          ]"
        >
          <v-card-title
            class="d-flex"
            style="
              vertical-align: middle;
              background-color: #1577da;

              height: 58px;
              color: white;
            "
          >
            <span class="text-h5" style="font-weight: bold">Korisnik</span>
          </v-card-title>
          <v-card-text>
            <v-row class="pt-3">
              <v-col cols="9">
                <label class="font-weight-bold"
                  >Korisničko ime:
                  {{ user && user.email ? user.email : "" }}</label
                >
              </v-col>
              <v-col
                cols="3"
                style="text-align: right"
                class="pt-0 mt-0 pb-0 mb-0 pr-0"
              >
                <v-switch
                  class="pt-0 mt-3"
                  hide-details
                  dense
                  v-model="user.active"
                  color="#1777da"
                  style="font-weight: bold"
                  :label="user.active ? 'Aktivan' : 'Neaktivan'"
                ></v-switch>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pt-0 mt-0 pb-0 mb-0">
                <v-select
                  label="Firma"
                  :items="[company]"
                  v-model="selectedCompany"
                  outlined
                  dense
                  hide-details
                  item-text="name"
                  item-value="id"
                  small-chips
                  deletable-chips
                  disabled
                  :messages="
                    !selectedCompany
                      ? 'Brisanjem iz firme brišete korisnika sa svih prodajnih mjesta.'
                      : ''
                  "
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pt-3 mt-0 pb-0 mb-0">
                <v-select
                  label="Prodajna mjesta"
                  multiple
                  outlined
                  dense
                  hide-details
                  :items="locations"
                  v-model="selectedLocations"
                  item-text="name"
                  item-value="id"
                  return-object
                  small-chips
                  :disabled="superAdmin"
                  deletable-chips
                  :loading="loadingLocations"
                >
                  <template v-slot:prepend-item>
                    <v-row>
                      <v-col>
                        <v-list-item ripple @click="toggleAll">
                          <v-list-item-action>
                            <v-icon
                              :color="
                                selectedLocations.length > 0
                                  ? 'indigo darken-4'
                                  : ''
                              "
                            >
                              {{ icon }}
                            </v-icon>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title> Odaberi sve </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-col>
                      <v-divider vertical class="my-4"></v-divider>
                      <v-col class="pt-5">
                        <v-btn text plain @click="resetToInitialState">
                          <v-icon class="mr-2">mdi-restore</v-icon>
                          Vrati na početno stanje
                        </v-btn>
                      </v-col>
                    </v-row>
                    <v-divider class="mt-2"></v-divider>
                  </template>
                </v-select>
              </v-col>
            </v-row>
            <v-row class="pt-3">
              <v-col class="pt-0 mt-0 pb-0 mb-0">
                <v-text-field
                  :label="$t('$vuetify.formDetails.firstName')"
                  v-model="user.name"
                  hide-details
                  :rules="[rules.req]"
                  outlined
                  dense
                  class="text-capitalize"
                >
                </v-text-field>
              </v-col>
              <v-col class="pt-0 mt-0 pb-0 mb-0">
                <v-text-field
                  hide-details
                  :label="$t('$vuetify.formDetails.lastName')"
                  v-model="user.surname"
                  :disabled="superAdmin"
                  :rules="[rules.req]"
                  outlined
                  dense
                  class="text-capitalize"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pt-3 mt-0 pb-0 mb-0">
                <v-text-field
                  hide-details
                  label="Korisničko ime"
                  v-model="user.email"
                  disabled
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pt-3 mt-0 pb-0 mb-0">
                <v-text-field
                  :label="$t('$vuetify.formDetails.password')"
                  outlined
                  v-model="password"
                  :disabled="superAdmin"
                  :rules="[
                    rules.validPass([
                      user.name,
                      user.surname,
                      user.email ? user.email.split('@')[0] : '',
                    ]),
                  ]"
                  :type="passVisible ? 'text' : 'password'"
                  :append-icon="passVisible ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="() => (passVisible = !passVisible)"
                  dense
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pt-1 mt-0 pb-0 mb-0">
                <v-text-field
                  label="PIN za prijavu na blagajnu"
                  v-model="user.pincode"
                  @input="checkPincodeAvailability"
                  validate-on-blur
                  outlined
                  dense
                  :rules="[
                    rules.pinused,
                    rules.req,
                    rules.minLen(4),
                    rules.maxLen(4),
                  ]"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row class="pt-3 mt-0">
              <v-col class="pt-0 mt-0 pb-0 mb-0">
                <v-select
                  label="Privilegije"
                  multiple
                  :items="roles"
                  v-model="selectedRoles"
                  item-text="name"
                  item-value="name"
                  small-chips
                  deletable-chips
                  outlined
                  dense
                  :rules="[rules.arrReq]"
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row class="pt-0 mt-0 pb-0 mb-0">
              <v-col class="pt-0 mt-0 pb-0 mb-0">
                <v-text-field
                  label="Oib"
                  v-model="user.oib"
                  outlined
                  dense
                  :rules="
                    selectedProdavac ? [rules.req, rules.oib] : [rules.oib]
                  "
                >
                </v-text-field>
              </v-col>
              <v-col class="pt-0 mt-0 pb-0 mb-0">
                <v-text-field
                  label="Oznaka operatera"
                  v-model="user.designation"
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="d-flex pt-0 mt-0 justify-end">
            <v-btn class="btn dangerButton" @click="close"> Odustani </v-btn>
            <v-btn
              id="success"
              class="btn successButton"
              :disabled="submitting"
              type="submit"
            >
              Spremi
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>
<script>
import { df, functions } from '@/plugins/firebase'
import { clone, duck } from '@/plugins/utils'
// import { v4 as uuidv4 } from 'uuid'
import rules from '@/plugins/rules'
import requestChecker from '@/mixins/requestChecker'

export default {
  inject: ['showMsgBox', 'confirm', 'confirmClose'],
  mixins: [requestChecker],
  data: function () {
    return {
      visible: false,
      submitting: false,
      company: undefined,
      user: {},
      selectedCompany: undefined,
      selectAll: false,
      locations: [],
      initialLocations: [],
      selectedLocations: [],
      superAdmin: false,
      rules: {
        req: rules.req(),
        oib: rules.oib(),
        arrReq: rules.arrReq(),
        validPass: rules.validPass,
        maxLen: rules.maxLen,
        minLen: rules.minLen
      },
      roles: [
        { name: 'SUPERADMIN', disabled: true },
        { name: 'ADMIN' },
        { name: 'PRODAVAČ' },
        { name: 'KNJIGOVOĐA' },
        { name: 'VODITELJ' }
      ],
      initialRoles: [],
      pincode: '',
      selectedRoles: [],
      passVisible: false,
      password: undefined,
      initialUser: {},
      oibExists: false,
      loadingLocations: false,
      isExistingPincode: false,
      timeout: false
    }
  },
  computed: {
    allSelected () {
      return this.selectedLocations.length === this.locations.length
    },
    someSelected () {
      return this.selectedLocations.length > 0 && !this.allSelected
    },
    icon () {
      if (this.allSelected) return 'mdi-checkbox-marked'
      if (this.someSelected) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
    selectedProdavac () {
      return this.selectedRoles.includes('PRODAVAČ')
    }
  },
  mounted () {
    this.rules.pinused = (val) => {
      return (
        !this.isExistingPincode ||
        `Korisnik sa PIN-om ${val} već postoji.`
      )
    }
  },
  methods: {
    open (user, company) {
      this.user = clone(user)
      this.initialUser = clone(user)
      this.company = clone(company)
      this.timeout = false
      this.superAdmin = this.user?.roles?.SUPERADMIN
      this.oibExists = this.user?.oib?.length > 0
      if (this.user?.roles) {
        this.setInitialRoles()
      }
      this.selectedCompany = clone(company)
      this.setInitialState()
      this.visible = true

      this.registerRequestParams(this.initialUser)
    },
    async checkPincodeAvailability () {
      const pin = this.user.pincode
      this.checking = true
      if (this.user !== undefined && this.user !== undefined && this.selectedCompany !== undefined && this.selectedCompany !== null && this.selectedCompany.id !== undefined && this.selectedCompany.id !== null && this.user.id !== undefined && this.selectedCompany.id !== null) {
        const isExistingPincode = await df
          .collection(`companies/${this.selectedCompany.id}/users`)
          .where('user.pincode', '==', pin)
          .where('user.status', '==', 'OK')
          .where('user.id', '!=', this.user.id)
          .get()
        this.isExistingPincode = !isExistingPincode.empty
      }
      this.checking = false
    },
    close () {
      this.$refs.editUserResourcesForm.reset()
      this.visible = false
    },
    toggleAll () {
      this.$nextTick(() => {
        if (this.allSelected) {
          this.selectedLocations = []
        } else {
          this.selectedLocations = this.locations.slice()
        }
      })
    },
    setInitialRoles () {
      const _roles = clone(this.user.roles)
      const roles = []
      Object.keys(_roles).forEach((key) => {
        if (_roles[key]) roles.push(key)
      })
      this.initialRoles = clone(roles)
      this.selectedRoles = clone(roles)
    },
    async setInitialState () {
      this.loadingLocations = true
      this.locations = []
      this.initialLocations = []
      this.selectedLocations = []
      const allLocations = await df
        .collection('locations')
        .where('company_id', '==', this.company.id)
        .get()
      if (!allLocations?.empty) {
        this.locations = allLocations.docs.map((doc) => {
          return doc.data()
        })
      }

      const locationsMap = await df
        .doc(`user_locations/${this.user.id}.${this.company.id}`)
        .get()
      this.loadingLocations = false
      if (locationsMap?.exists && locationsMap.data()) {
        this.initialLocations = Object.keys(locationsMap.data().locations)
          .map((key) => {
            return locationsMap.data().locations[key]
          })
          .filter((location) => location.resource_status === 'ACTIVE')
        this.selectedLocations = clone(this.initialLocations)
      }
    },
    resetToInitialState () {
      this.selectedLocations = clone(this.initialLocations)
    },
    submit () {
      if (!this.$refs.editUserResourcesForm.validate()) return
      if (
        !duck(this.initialUser, this.user) ||
        this.password ||
        this.selectedRoles.some((role) => !this.initialRoles.includes(role)) ||
        this.initialRoles.some((role) => !this.selectedRoles.includes(role))
      ) {
        this.editUser()
      }
      this.editUserResources()
    },
    editUser () {
      this.submitting = true
      // const reqId = uuidv4()
      const payload = {
        action: {
          operation: 'update',
          entity: 'user',
          resource_id: this.user.id,
          params: {
            name: this.user.name,
            surname: this.user.surname,
            designation: this.user.designation,
            active: this.user.active,
            oib: this.user.oib,
            pincode: this.user.pincode
          }
        }
      }

      const cleanParams = this.getCleanParams(this.user)
      Object.keys(cleanParams).forEach((key) => {
        delete payload.action.params[key]
      })

      if (this.password) {
        if (duck(this.initialUser, this.user)) {
          this.timeout = true
        }
        payload.action.params.password = this.password
        payload.action.params.password_confirmation = this.password
      }

      payload.action.params.roles = this.selectedRoles.reduce(
        (roles, r) => Object.assign(roles, { [r]: true }),
        {}
      )

      var userApi = functions.httpsCallable('userapi')
      userApi({
        action: {
          operation: 'update',
          resource_id: this.user.id,
          entity: payload.action.entity,
          params: payload.action.params
        }
      })
        .then((result) => {
          if (result.data.code === 200) {
            this.showMsgBox({
              text: 'Uspješno promijenjeno!',
              actions: ['cancel'],
              cancelBtnText: this.$t('$vuetify.close'),
              color: 'error'
            })

            this.$emit('refreshUser')
          } else {
            this.showMsgBox({
              text: 'Greška prilikom spremanja podataka.',
              actions: ['cancel'],
              cancelBtnText: 'OK',
              color: 'error'
            })
          }

          this.submitting = false
          this.close()
        })
    },
    editUserResources () {
      const deletingLocations = this.initialLocations.filter(
        (location) =>
          !this.selectedLocations.some((loc) => loc.id === location.id)
      )
      const addingLocations = this.selectedLocations.filter(
        (location) =>
          !this.initialLocations.some((loc) => loc.id === location.id)
      )
      if (addingLocations?.length > 0) {
        this.addLocations(addingLocations)
      }
      if (deletingLocations?.length > 0) {
        this.removeLocations(deletingLocations)
      }
    },
    addLocations (locations) {
      this.submitting = true
      // const reqId = uuidv4()
      const payload = {
        action: {
          operation: 'set',
          entity: 'resource',
          params: {
            user_ids: [this.user.id],
            flag: 'add',
            company_id: this.company.id,
            location_ids: locations.map((location) => location.id)
            // resource_user_id: auth.currentUser.uid
          }
        }
      }

      var userApi = functions.httpsCallable('userapi')
      userApi({
        action: {
          operation: payload.action.operation,
          entity: payload.action.entity,
          params: payload.action.params
        }
      })
        .then((result) => {
          if (result.data.code === 200) {
            this.showMsgBox({
              text: 'Uspješno promijenjeno!',
              actions: ['cancel'],
              cancelBtnText: this.$t('$vuetify.close'),
              color: 'error'
            })

            this.$emit('refreshUser')
          } else {
            this.showMsgBox({
              text: 'Greška prilikom spremanja podataka.',
              actions: ['cancel'],
              cancelBtnText: 'OK',
              color: 'error'
            })
          }

          this.$emit('action', { ...this.user, timeout: true })
          this.submitting = false
          this.close()
        })
    },
    removeLocations (locations) {
      this.submitting = true
      // const reqId = uuidv4()
      const payload = {
        action: {
          operation: 'delete',
          entity: 'resource',
          params: {
            user_ids: [this.user.id],
            flag: 'del',
            company_id: this.company.id,
            location_ids: locations.map((location) => location.id)
          }
        }
      }

      var userApi = functions.httpsCallable('userapi')
      userApi({
        action: {
          operation: payload.action.operation,
          entity: payload.action.entity,
          params: payload.action.params
        }
      })
        .then((result) => {
          if (result.data.code === 200) {
            this.showMsgBox({
              text: 'Uspješno promijenjeno!',
              actions: ['cancel'],
              cancelBtnText: this.$t('$vuetify.close'),
              color: 'error'
            })

            this.$emit('refreshUser')
          } else {
            this.showMsgBox({
              text: 'Greška prilikom spremanja podataka.',
              actions: ['cancel'],
              cancelBtnText: 'OK',
              color: 'error'
            })
          }
          this.$emit('action', { ...this.user, timeout: true })
          this.$emit('remove', { ...this.user, timeout: true })
          this.submitting = false
          this.close()
        })
    }
  }
}
</script>
<style scoped>
.card-users {
  border-radius: 25px !important;
}
</style>
