var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('add-user',{ref:"addUser",on:{"action":_vm.setLoading,"refreshUser":_vm.reload}}),_c('edit-user',{ref:"editUser",on:{"action":_vm.setLoading,"remove":_vm.setRemovingUser,"refreshUser":_vm.reload}}),_c('v-container',[_c('v-row',{staticClass:"pt-3 pr-3"},[_c('v-spacer'),_c('Search-box',{attrs:{"emitDestination":_vm.searchEmitDestination,"searchPlaceholder":"Pretraži korisnike"}})],1),_c('v-row',{staticClass:"d-flex justify-center",staticStyle:{"margin-top":"0px"}},[_c('v-col',{class:['px-0'],attrs:{"cols":"12","sm":"11","lg":"11"}},[_c('v-row',{staticClass:"d-flex justify-center"},[_c('v-col',{staticClass:"ml-0 pl-0",attrs:{"cols":"6","md":"3"}},[_c('v-select',{attrs:{"outlined":"","dense":"","label":"Firma","items":_vm.companies,"return-object":"","item-text":"name","item-value":"id","clearable":""},on:{"change":_vm.companyChange,"click:clear":_vm.resetAll},model:{value:(_vm.company),callback:function ($$v) {_vm.company=$$v},expression:"company"}})],1),_c('v-col',{staticClass:"ml-0 pl-0",attrs:{"cols":"6","md":"3"}},[_c('v-select',{attrs:{"outlined":"","dense":"","label":"Prodajno mjesto","items":_vm.locations,"item-text":"name","item-value":"id","return-object":"","clearable":""},on:{"change":_vm.resetInfiniteLoader,"click:clear":_vm.resetInfiniteLoader},model:{value:(_vm.location),callback:function ($$v) {_vm.location=$$v},expression:"location"}})],1)],1),_c('v-row',{staticClass:"d-flex justify-center",staticStyle:{"margin-top":"0px"}},[_c('v-col',{class:['px-0'],attrs:{"cols":"12"}},_vm._l((_vm.sortedUsers),function(doc,i){return _c('v-card',{key:i,attrs:{"hover":"","tabindex":"0"}},[_c('v-row',{class:[
                  'ma-0',
                  !doc.user.status || doc.user.status === 'OK'
                    ? 'status-ok-exp'
                    : ("" + (doc.user.status === 'PROCESSING'
                          ? 'status-processing-exp'
                          : 'status-failed-exp')),
                  doc.user.active ? 'status-ok-exp' : 'status-failed-exp' ],staticStyle:{"height":"50px !important"}},[_c('div',{staticClass:"pl-2",class:[_vm.densityPadding],staticStyle:{"width":"10% !important"}},[_c('div',{staticClass:"text-capitalize caption grey--text text-left"},[_vm._v(" Prezime ")]),_c('div',{staticClass:"text-capitalize text-left"},[_vm._v(" "+_vm._s(doc.user.surname)+" ")])]),_c('div',{class:[_vm.densityPadding],staticStyle:{"width":"10% !important"}},[_c('div',{staticClass:"text-capitalize caption grey--text text-left"},[_vm._v(" Ime ")]),_c('div',{staticClass:"text-capitalize text-left"},[_vm._v(" "+_vm._s(doc.user.name)+" ")])]),_c('div',{class:[_vm.densityPadding, 'hidden-md-and-down'],staticStyle:{"width":"13% !important"}},[_c('div',{staticClass:"caption grey--text text-left"},[_vm._v("Oib")]),_c('div',{staticClass:"text-left"},[_vm._v(_vm._s(doc.user.oib))])]),_c('div',{class:[_vm.densityPadding, ' hidden-md-and-down'],staticStyle:{"width":"8% !important"}},[_c('div',{staticClass:"caption grey--text text-center"},[_vm._v("Aktivan")]),_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm._f("yesno")(doc.config.active))+" ")])]),_c('div',{class:[_vm.densityPadding],staticStyle:{"width":"23% !important"}},[_c('div',{staticClass:"caption grey--text text-left"},[_vm._v(" Korisničko ime ")]),_c('div',{staticClass:"text-left"},[_vm._v(_vm._s(doc.user.email))])]),_c('div',{class:[_vm.densityPadding, 'flex-grow-1', 'flex-shrink-0'],staticStyle:{"min-width":"100px","max-width":"100%","width":"23% !important"}},[_c('div',{staticClass:"caption grey--text"},[_vm._v("Privilegije")]),_vm._l((doc.user.roles
                      ? Object.keys(doc.user.roles)
                      : []),function(role,i){return _c('span',{key:role,staticClass:"text-capitalize"},[(doc.user.roles[role])?_c('span',[_vm._v(_vm._s(("" + role + (doc.user.roles && Object.keys(doc.user.roles).length - 1 > i ? ", " : ""))))]):_vm._e()])})],2),_c('div',{class:[_vm.densityPadding],staticStyle:{"width":"7% !important"}},[_c('div',{staticClass:"d-flex align-start justify-center pb-1"},[_c('v-btn',{staticClass:"mt-0 pt-0",attrs:{"x-small":_vm.density === 'greater',"tabindex":"-1","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.editUser(doc.user)},"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.stopPropagation();}}},[_c('v-icon',[_vm._v("mdi-pencil-outline")])],1)],1)])]),_c('v-divider')],1)}),1)],1)],1)],1),_c('infinite-loading',{ref:"InfiniteLoading",attrs:{"spinner":"waveDots"},on:{"infinite":_vm.infiniteHandler}},[_c('div',{attrs:{"slot":"no-more"},slot:"no-more"}),_c('div',{attrs:{"slot":"no-results"},slot:"no-results"},[_c('v-row',{staticClass:"d-flex justify-center 'mt-0'"},[_c('v-col',{class:['px-0', 'pt-0'],attrs:{"cols":"12","sm":"11","lg":"11"}},[_c('v-card',{attrs:{"hover":"","tabindex":"0"}},[_c('v-row',{staticClass:"ma-0 status-processing justify-center"},[_c('v-col',[_c('div',{staticClass:"text-center"},[_vm._v(" Ne postoje korisnici po odabranim kriterijima. ")])])],1)],1)],1)],1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }