<template>
  <div>
    <v-dialog
      v-model="visible"
      content-class="my-custom-dialog"
      transition="dialog-top-transition"
      scrollable
      persistent
      max-width="600"
      max-height="600"
      style="height: 50px"
    >
      <v-overlay v-if="submitting" absolute z-index="10">
        <v-progress-circular
          indeterminate
          size="64"
          color="primary"
        ></v-progress-circular>
      </v-overlay>
      <v-form ref="addUserForm" @submit.prevent="submit">
        <v-card
          tile
          color="white"
          class="pb-3 overflow-auto"
          :class="[
            { 'mobile-card': $vuetify.breakpoint.smAndDown },
            { 'card-users': !$vuetify.breakpoint.smAndDown },
          ]"
        >
          <v-card-title
            class="d-flex"
            style="
              vertical-align: middle;
              background-color: #1577da;

              height: 58px;
              color: white;
            "
          >
            <span class="text-h5" style="font-weight: bold"
              >Dodaj korisnika</span
            >
          </v-card-title>

          <v-card-text>
            <v-row class="pt-3">
              <v-col class="pt-3 mt-0 pb-0 mb-0">
                <v-select
                  label="Firma"
                  outlined
                  :items="companies"
                  v-model="company"
                  return-object
                  item-text="name"
                  item-value="id"
                  :rules="[rules.req]"
                  @change="companyChange"
                  @click:clear="resetAll"
                  clearable
                  dense
                  hide-details
                >
                  <template v-if="!domain && company" v-slot:append-outer>
                    <v-btn
                      icon
                      @click="openAddDomainForm"
                      v-if="!domain && company"
                    >
                      <v-icon :color="!domain ? 'error' : 'success'"
                        >mdi-exclamation-thick</v-icon
                      >
                    </v-btn>
                    <a
                      v-if="!domain && company"
                      href=""
                      @click.prevent="openAddDomainForm"
                      class="d-flex align-center"
                      style="white-space: nowrap"
                      >Dodaj domenu</a
                    >
                  </template>
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pt-3 mt-0 pb-0 mb-0">
                <v-select
                  label="Prodajna mjesto"
                  outlined
                  multiple
                  hide-details
                  :items="locations"
                  v-model="selectedLocations"
                  item-text="name"
                  item-value="id"
                  clearable
                  dense
                  :loading="loadingLocations"
                ></v-select>
              </v-col>
            </v-row>
            <v-row class="pt-3">
              <v-col class="pt-0 mt-0 pb-0 mb-0">
                <v-text-field
                  :label="$t('$vuetify.formDetails.firstName')"
                  v-model="firstName"
                  :rules="[rules.req]"
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
              <v-col class="pt-0 mt-0 pb-0 mb-0">
                <v-text-field
                  :label="$t('$vuetify.formDetails.lastName')"
                  v-model="lastName"
                  :rules="[rules.req]"
                  outlined
                  dense
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row class="pt-0 mt-0 pb-0 mb-0">
              <v-col class="pt-0 mt-0 pb-0 mb-0">
                <v-text-field
                  label="Korisničko ime"
                  v-model="userName"
                  @input="checkEmailAvailability"
                  validate-on-blur
                  outlined
                  :disabled="!domain"
                  dense
                  :rules="[
                    rules.reserved,
                    rules.req,
                    rules.username,
                    rules.minLen(4),
                  ]"
                  :suffix="domain ? `@${domain}.aikasa` : ''"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row class="pt-0 mt-0">
              <v-col class="pt-0 mt-0 pb-0 mb-0">
                <v-text-field
                  :label="$t('$vuetify.formDetails.password')"
                  outlined
                  v-model="password"
                  :rules="[
                    rules.req,
                    rules.validPass([firstName, lastName, userName]),
                  ]"
                  :type="passVisible ? 'text' : 'password'"
                  :append-icon="passVisible ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="() => (passVisible = !passVisible)"
                  dense
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row class="pt-0 mt-0">
              <v-col class="pt-3 mt-0 pb-0 mb-0">
                <v-text-field
                  label="PIN za prijavu na blagajnu"
                  v-model="pincode"
                  @input="checkPincodeAvailability"
                  validate-on-blur
                  outlined
                  :disabled="!domain"
                  dense
                  :rules="[
                    rules.pinused,
                    rules.req,
                    rules.minLen(4),
                    rules.maxLen(4),
                  ]"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row class="pt-0 mt-0">
              <v-col class="pt-3 mt-0 pb-0 mb-0">
                <v-select
                  label="Privilegije"
                  multiple
                  :items="roles"
                  v-model="selectedRoles"
                  item-text="name"
                  item-value="id"
                  return-object
                  outlined
                  dense
                  :rules="[rules.arrReq]"
                >
                </v-select>
              </v-col>
            </v-row>
            <div v-if="selectedProdavac" class="pt-0 mt-0 pb-0 mb-0">
              <v-row class="pt-0 mt-0 pb-0 mb-0">
                <v-col class="pt-0 mt-0 pb-0 mb-0">
                  <v-text-field
                    label="Oib"
                    v-model="userOib"
                    outlined
                    dense
                    :rules="[rules.req, rules.oib]"
                  >
                  </v-text-field>
                </v-col>
                <v-col class="pt-0 mt-0 pb-0 mb-0">
                  <v-text-field
                    label="Oznaka operatera"
                    v-model="operator"
                    outlined
                    dense
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </div>
          </v-card-text>

          <v-card-actions class="d-flex pt-0 mt-0 pr-4 justify-end">
            <v-btn class="btn dangerButton" @click="close"> Odustani </v-btn>
            <v-btn
              id="success"
              class="btn successButton"
              :loading="submitting"
              :disabled="submitting || !domain || checking"
              type="submit"
            >
              Dodaj korisnika
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <add-domain ref="addDomain" @success="getCompanyDomain"></add-domain>
  </div>
</template>
<script>
import rules from '@/plugins/rules'
import { df, functions } from '@/plugins/firebase'
import { v4 as uuidv4 } from 'uuid'
import { clone } from '@/plugins/utils'
import addDomain from '@/modules/companies/components/users/components/AddDomain'

export default {
  components: { addDomain },
  inject: ['showMsgBox', 'confirm', 'confirmClose'],
  data: function () {
    return {
      visible: false,
      submitting: false,
      passVisible: false,
      password: undefined,
      companies: [],
      company: undefined,
      locations: [],
      selectedLocations: [],
      roles: ['ADMIN', 'PRODAVAČ', 'KNJIGOVOĐA', 'VODITELJ'],
      selectedRoles: [],
      rules: {
        req: rules.req(),
        minLen: rules.minLen,
        maxLen: rules.maxLen,
        arrReq: rules.arrReq(),
        oib: rules.oib(),
        validPass: rules.validPass,
        username: rules.username()
      },
      domain: undefined,
      firstName: '',
      lastName: '',
      userName: '',
      pincode: '',
      isExistingEmail: false,
      isExistingPincode: false,
      checking: false,
      userActive: true,
      userOib: '',
      operator: '',
      loadingLocations: false
    }
  },
  computed: {
    selectedProdavac () {
      return this.selectedRoles.includes('PRODAVAČ')
    }
  },
  mounted () {
    this.rules.reserved = (val) => {
      return (
        !this.isExistingEmail ||
        `Korisnik sa korisničkim imenom ${val} već postoji.`
      )
    }
    this.rules.pinused = (val) => {
      return (
        !this.isExistingPincode ||
        `Korisnik sa PIN-om ${val} već postoji.`
      )
    }
  },
  methods: {
    open (companies) {
      this.companies = companies
      this.visible = true
    },
    close () {
      this.$refs.addUserForm.reset()
      this.resetDefaults()
      this.visible = false
    },
    async companyChange () {
      if (this.company) {
        this.getCompanyDomain()
        let locations = []
        this.loadingLocations = true
        const allLocations = await df
          .collection('locations')
          .where('company_id', '==', this.company.id)
          .get()
        this.loadingLocations = false
        if (!allLocations?.empty) {
          locations = allLocations.docs.map((doc) => {
            return doc.data()
          })
        }
        this.locations = clone(locations.sort((a, b) => a.name.localeCompare(b.name)))
      }
    },
    checkInput (ev) {
      // change this with a custom directive  this accepts custom regex and checks input field
      const maskRe =
        /(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))/
      if (!maskRe.test(ev.key)) {
        ev.preventDefault()
        return false
      }
    },
    getCompanyDomain () {
      this.domain = ''
      df.doc(`companies/${this.company.id}`)
        .get()
        .then((doc) => {
          if (doc?.data()?.domain) {
            this.domain = doc?.data()?.domain
          }
          if (!this.domain) {
            this.confirm({
              title: 'Domena',
              message:
                'Još nemate dodanu domenu! Da bi dodali korisnike na firmu morate prvo odrediti domenu koja je sastavni dio Korisničkog imena.',
              options: {
                toolbar: true,
                width: 410,
                confirmText: 'Dodaj domenu',
                cancelText: this.$t('$vuetify.close')
              }
            }).then((resp) => {
              if (resp) {
                this.confirmClose()
                this.$refs.addDomain.open(this.company)
              }
            })
          }
        })
    },
    openAddDomainForm () {
      this.$refs.addDomain.open(this.company)
    },
    async checkEmailAvailability () {
      const email = `${this.userName}@${this.domain}.aikasa`
      if (this.userName?.length > 3) {
        this.checking = true
        const isExistingEmail = await df
          .collection('users')
          .where('email', '==', email)
          .where('status', '==', 'OK')
          .get()
        this.isExistingEmail = !isExistingEmail.empty
        this.checking = false
      } else {
        this.isExistingEmail = false
      }
    },
    async checkPincodeAvailability () {
      const pin = this.pincode
      this.checking = true
      if (this.company !== undefined && this.company !== null && this.company.id !== undefined && this.company.id !== null) {
        const isExistingPincode = await df
          .collection(`companies/${this.company.id}/users`)
          .where('user.pincode', '==', pin)
          .where('user.status', '==', 'OK')
          .get()
        this.isExistingPincode = !isExistingPincode.empty
      }
      this.checking = false
    },
    submit () {
      if (!this.$refs.addUserForm.validate()) return

      this.submitting = true
      const payload = {
        action: {
          operation: 'set',
          entity: 'user',
          params: {
            user: {
              id: uuidv4(),
              name: this.firstName.trim().toLowerCase(),
              surname: this.lastName.trim().toLowerCase(),
              email: `${this.userName}@${this.domain}.aikasa`,
              password: this.password,
              password_confirmation: this.password,
              agree_terms_of_use: 'YES',
              designation: this.operator,
              pincode: this.pincode,
              roles: this.selectedRoles.reduce(
                (roles, r) => Object.assign(roles, { [r]: true }),
                {}
              )
            },
            resources: {
              company_id: this.company.id,
              location_ids: this.selectedLocations,
              flag: 'add'
            }
          }
        }
      }

      if (this.selectedProdavac) {
        payload.action.params.user.designation = this.operator
        payload.action.params.user.oib = this.userOib
      }

      var userApi = functions.httpsCallable('userapi')
      userApi({
        action: {
          operation: 'set',
          entity: payload.action.entity,
          params: payload.action.params
        }
      })
        .then((result) => {
          if (result.data.code === 200) {
            this.showMsgBox({
              text: 'Uspješno dodan korisnik!',
              actions: ['cancel'],
              cancelBtnText: this.$t('$vuetify.close'),
              color: 'error'
            })
            this.$emit('refreshUser')
          } else {
            this.showMsgBox({
              text: 'Greška prilikom spremanja podataka.',
              actions: ['cancel'],
              cancelBtnText: 'OK',
              color: 'error'
            })
          }

          this.submitting = false

          this.close()
        })
    },
    resetAll () {
      this.company = undefined
      this.location = undefined
      this.locations = []
      this.domain = undefined
    },
    resetDefaults () {
      this.company = undefined
      this.location = undefined
      this.userActive = true
      this.domain = undefined
    }
  }
}
</script>
<style scoped>
.card-users {
  border-radius: 25px !important;
}
</style>
