<template>
  <!-- <div class="d-flex justify-center">
    <div>
      <v-select
        v-model="selectedCategories"
        :items="uniqueCategories"
        label="Filter kategorija"
        item-text="name"
        item-value="id"
        outlined
        multiple
        dense
        style="max-width: 250px"
      >
        <template v-slot:selection="{ item, index }">
          <v-chip v-if="index === 0" style="height: 26px">
            <span>{{ item.name }}</span>
          </v-chip>
          <span v-if="index === 1" class="grey--text text-caption">
            (+{{ selectedCategories.length - 1 }})
          </span>
        </template>
        <template v-slot:item="{ item }">
          <div
            style="width: 100%; height: 35px"
            @click="setCategoryFilters(item)"
          >
            {{ item.name }}
          </div>
        </template>
      </v-select>
    </div>
  </div> -->

  <div class="d-flex justify-center">
    <div>
      <v-select
        outlined
        multiple
        dense
        v-model="selectedCategories"
        :items="uniqueCategories"
        label="Filter kategorija"
        item-text="name"
        item-value="id"
        style="max-width: 250px"
        @change="setCategoryFilters()"
      >
        <template v-slot:selection="{ item, index }">
          <v-chip v-if="index === 0">
            <span>{{ item.name }}</span>
          </v-chip>
          <span v-if="index === 1" class="grey--text text-caption">
            (+{{ selectedCategories.length - 1 }})
          </span>
        </template>
      </v-select>
    </div>
  </div>
</template>

<script>
import { df } from '@/plugins/firebase'
import state from '@/state'

export default {
  data () {
    return {
      categories: [],
      uniqueCategories: [],
      categoryFilters: [],
      localItems: [],
      initialItems: [],
      filters: [],
      location: null,
      selectedCategories: []
    }
  },
  props: {
    items: {
      type: Array,
      default: () => []
    }
  },

  methods: {
    init (items) {
      this.localItems = [...items]
      this.initialItems = [...items]
    },

    setCategoryFilters () {
      // if (this.categoryFilters.includes(item.id)) {
      //   this.categoryFilters.splice(this.categoryFilters.indexOf(item.id), 1)
      // } else {
      //   this.categoryFilters.push(item.id)
      // }
      // if (this.categoryFilters.length === 0) {
      //   this.$emit('setIdsForFilter', [])
      // } else {
      //   this.$emit('setIdsForFilter', [...this.categoryFilters])
      // }

      if (this.selectedCategories.length === 0) {
        this.$emit('setIdsForFilter', [])
      } else {
        this.$emit('setIdsForFilter', [...this.selectedCategories])
      }
    },

    async getAllCategories () {
      const snapshot = await df.doc(`company_categories/${state.getCurrentCompany().id}`).get()
      if (snapshot) {
        const document = snapshot.data()
        for (const property in document.categories) {
          const category = { id: document.categories[property].id, name: document.categories[property].name }
          this.uniqueCategories.push(category)
        }
        this.uniqueCategories = this.uniqueCategories.sort((a, b) => a.name.localeCompare(b.name))
      }
    },

    async getCategoriesByItemId (items) {
      const fileredItems = []

      const query = df.collection(`location_pricelist/${state.getPointOfSale().id}/items`)
        .where('active', '==', true)
        .where('status', '==', 'OK')

      const documentSnapshots = await query.get()

      if (documentSnapshots.docs && !documentSnapshots.docs.empty) {
        const filters = this.selectedCategories.flat()
        documentSnapshots.docs.forEach((item) => {
          const priceListItem = items.find(i => i.id.substring(0, i.id.indexOf('.')) === item.data().id)
          if (priceListItem && filters.length > 0) {
            item.data().category_ids.forEach(catId => {
              if (filters.includes(catId)) {
                fileredItems.push(priceListItem)
              }
            })
          }
        })
      }

      return fileredItems.flat()
    }
  },

  created () {
    this.getAllCategories()
  }
}
</script>
