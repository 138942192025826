<template>
  <v-dialog v-model="visible"
    @keydown.esc="close"
    persistent
    scrollable
    max-width="500"
    style="z-index: 6">
    <v-form ref="addSupplierItemPrice"
      @submit.prevent="submit">
      <v-card class="overflow-auto"
        :class="{ 'mobile-card': $vuetify.breakpoint.smAndDown }">
        <v-card-title class="d-flex"
          style="vertical-align: middle">
          <v-row class="pa-3 ">
            <h3 class="text--secondary">
              Dodavanje cijene za artikl
            </h3>
            <v-spacer></v-spacer>
            <v-icon large
              @click="close">mdi-close</v-icon>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row class="justify-center align-center">
            <v-col cols="12">
              <v-row class="pt-4 pb-0 mb-0 justify-center align-center">
                <v-col class="pa-0"
                  cols="6">
                  <money-input outlined
                    autofocus
                    label="Nabavna cijena"
                    v-model="price"
                    :currency="currency"
                    ref="priceInput">
                  </money-input>
                </v-col>
                <v-col class="pa-0"
                  cols="6">
                  <v-select label="PDV"
                    outlined
                    :items="pdvs"
                    v-model="pdv"
                    item-text="name"
                    item-value="rate"
                    :rules="[rules.req]">
                  </v-select>
                </v-col>
              </v-row>
              <v-row class="pt-0 mt-0 justify-center align-center">
                <v-col class="pa-0"
                  cols="6">
                  <money-input outlined
                    hide-details
                    class="pa-0"
                    autofocus
                    label="Rabat"
                    v-model="margin"
                    currency="%"
                    ref="marginInput">
                  </money-input>
                </v-col>
                <v-col class="pa-0 ma-0"
                  cols="6">
                  <money-input outlined
                    hide-details
                    class="pa-0"
                    autofocus
                    label="Maloprodajna cijena"
                    v-model="salePrice"
                    :currency="currency"
                    ref="salePriceInput">
                  </money-input>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-center pb-5">
          <v-btn class="white--text okButton"
            height="45"
            :loading="submitting"
            :disabled="submitting"
            type="submit">
            Dodaj na cjenik
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import { df, auth } from '@/plugins/firebase'
import { v4 as uuidv4 } from 'uuid'
import rules from '@/plugins/rules'
import state from '@/state'
import countries from '@/mixins/countries'
import MoneyInput from '@/components/MoneyInput'

export default {
  data: function (vm) {
    return {
      visible: false,
      submitting: false,
      rules: {
        req: rules.req(),
        oib: rules.oib(),
        email: rules.email(),
        arrReq: rules.arrReq()
      },
      item: undefined,
      supplier: undefined,
      price: undefined,
      pdv: undefined,
      margin: undefined,
      salePrice: undefined,
      listeners: [],
      currency: 'EUR',
      pdvs: [{
        name: '5%', rate: 5
      },
      {
        name: '13%', rate: 13
      },
      {
        name: '25%', rate: 25
      }]
    }
  },
  inject: ['showMsgBox', 'showLoader', 'hideLoader'],
  mixins: [countries],
  components: { MoneyInput },

  mounted () {

  },
  methods: {
    formatNumber (num, multiplier = 100) {
      if (!num || num === '' || num === 0) return 0

      var bla = num.toString().trim()
      if (bla === '') return 0

      var number = bla.replace(/,/g, '.')
      number = parseFloat(number)

      return parseInt(number * multiplier)
    },
    open (data) {
      this.supplier = data.supplier
      this.item = data.item
      this.visible = true
      this.submitting = false
    },
    close () {
      this.visible = false
    },

    submit () {
      var price = this.$refs.priceInput.currencyToFloat(this.price)
      var salePrice = this.$refs.salePriceInput.currencyToFloat(this.salePrice)

      const that = this
      //   this.showLoader()
      this.submitting = true
      const reqId = uuidv4()
      const payload = {
        action: {
          operation: 'set',
          entity: 'supplier_item_price',
          params: {
            supplier_id: this.supplier.id,
            item_id: this.item.id,
            item_code: this.item.code,
            company_id: state.getCurrentCompany().id,
            price: this.formatNumber(price),
            pdv: this.formatNumber(this.pdv),
            margin: this.formatNumber(this.margin),
            sale_price: this.formatNumber(salePrice)
          }
        }
      }

      that.$emit('success', payload.action.params)
      try {
        df.doc(`request/${reqId}`)
          .set({
            user_id: `${auth.currentUser.uid}`,
            device_id: 'web',
            created: `${new Date().getTime()}`,
            type: 'supplier',
            payload: btoa(
              unescape(encodeURIComponent(JSON.stringify(payload)))
            )
          })
          .then(function () {
            // setTimeout(() => {
            that.$refs.addSupplierItemPrice.reset()
            that.submitting = false
            that.visible = false
            // }, 100);
          })
          .catch(function (err) {
            that.showMsgBox({
              text: err && err !== '' ? err : 'An error has occurred',
              actions: ['cancel'],
              cancelBtnText: 'OK',
              color: 'error'
            })
            that.submitting = false
          })
      } catch (err) {
        that.showMsgBox({
          text: err && err !== '' ? err : 'An error has occurred',
          actions: ['cancel'],
          cancelBtnText: 'OK',
          color: 'error'
        })
        that.submitting = false
      }
    }
  }
}
</script>
<style scoped>
.uppercased-input>>>input {
  text-transform: uppercase !important;
}
</style>
