var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fill-height",staticStyle:{"width":"100%"}},[_c('v-container',[(_vm.listView)?_c('v-row',{staticClass:"d-flex justify-center",staticStyle:{"margin-top":"0px"}},[_c('v-col',{class:['px-0'],attrs:{"cols":"12","sm":"11","lg":"11"}},_vm._l((_vm.sortedDiscounts),function(discount,i){return _c('v-card',{key:i,attrs:{"hover":""}},[_c('v-row',{class:[
              'ma-0',
              discount.active === true
                ? 'status-ok-exp'
                : 'status-failed-exp' ]},[_c('v-col',{class:[_vm.densityPadding, 'flex-grow-1'],staticStyle:{"min-width":"100px","max-width":"100%"},attrs:{"cols":"5","sm":"5","md":"3","lg":"3"}},[_c('div',{staticClass:"caption grey--text"},[_vm._v("Naziv popusta")]),_c('div',{ref:("hover-" + (discount.id)),refInFor:true,staticClass:"text-subtitle-1 one-liner-text",attrs:{"id":("hover-" + (discount.id))},on:{"mouseover":function($event){return _vm.hoverOver(("hover-" + (discount.id)), discount)},"mouseleave":function($event){discount[("hover-" + (discount.id))] = false}}},[_vm._v(" "+_vm._s(_vm.capitalizeFirst(discount.name))+" ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('div',_vm._g({},on))]}}],null,true),model:{value:(discount[("hover-" + (discount.id))]),callback:function ($$v) {_vm.$set(discount, ("hover-" + (discount.id)), $$v)},expression:"discount[`hover-${discount.id}`]"}},[_c('div',{staticStyle:{"max-width":"300px"}},[_vm._v(" "+_vm._s(_vm.capitalizeFirst(discount.name))+" ")])])],1),_c('v-col',{class:[_vm.densityPadding],attrs:{"cols":"3","sm":"3","md":"2"}},[_c('div',{staticClass:"caption grey--text"},[_vm._v("Vrsta popusta")]),_c('div',[_vm._v(_vm._s(_vm.translateType(discount.type)))])]),_c('v-col',{class:[_vm.densityPadding],attrs:{"cols":"3","sm":"3","md":"2"}},[(discount.type !== 'MANUAL')?_c('div',{staticClass:"caption grey--text"},[_vm._v(" Iznos popusta ")]):_vm._e(),(discount.type == 'RATE')?_c('div',[_vm._v(" "+_vm._s(discount.rate / 100)+" % ")]):_vm._e(),(discount.type == 'AMOUNT')?_c('div',[_vm._v(" "+_vm._s(_vm._f("money")((discount.amount / 100),0, _vm.currency))+" ")]):_vm._e()]),_c('v-col',{class:[_vm.densityPadding],attrs:{"cols":"3","sm":"3","md":"2"}},[_c('div',{staticClass:"caption grey--text"},[_vm._v("Aktivan")]),_c('div',[_vm._v(" "+_vm._s(_vm.showActivity(discount.active))+" ")])]),_c('v-col',{class:[_vm.densityPadding],attrs:{"cols":"2","md":"1","lg":"1"}},[_c('div',{staticClass:"caption grey--text text-center"},[_vm._v("Akcije")]),_c('div',{staticClass:"d-flex align-start justify-center"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mt-0 pt-0",attrs:{"x-small":_vm.density === 'greater',"icon":""},on:{"click":function($event){return _vm.openEditDiscountForm(discount)},"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.openEditDiscountForm(discount)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Uredi popust")])])],1)])],1),_c('v-divider')],1)}),1)],1):_c('v-row',{staticClass:"d-flex justify-center align-center fill-height ma-0 pa-0"},_vm._l((_vm.sortedDiscounts),function(discount,i){return _c('v-card',{key:i,staticClass:"align-center ma-5",attrs:{"hover":"","height":"300","width":"250"}},[_c('div',{staticClass:"mr-4 ml-4"},[_c('div',[_c('h3',{staticClass:"selling-point-title-text one-liner-text"},[_vm._v(" "+_vm._s(_vm.capitalizeFirst(discount.name))+" ")])])]),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.openEditDiscountForm(discount)},"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.openEditDiscountForm(discount)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Uredi artikl")])])],1)],1)}),1),_c('infinite-loading',{ref:"InfiniteLoading",attrs:{"spinner":"waveDots"},on:{"infinite":_vm.infiniteHandler}},[_c('div',{staticClass:"text--secondary font-italic",attrs:{"slot":"no-more"},slot:"no-more"}),_c('div',{staticClass:"text--secondary font-italic",attrs:{"slot":"no-results"},slot:"no-results"})])],1),_c('add-discount',{ref:"addDiscount",on:{"success":_vm.refreshDiscounts}}),_c('edit-discount',{ref:"editDiscount",on:{"success":_vm.refreshDiscounts}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }