var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"ml-13 mt-5 mr-13",staticStyle:{"background-color":"rgba(0, 0, 0, 0.04)"},attrs:{"rounded":""}},[_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"12","lg":"2"}},[_c('v-select',{attrs:{"label":"Prodajno mjesto","items":_vm.locations,"item-text":"name","item-value":"location_id","rules":[_vm.rules.req],"dense":""},model:{value:(_vm.userLocationFilterValue),callback:function ($$v) {_vm.userLocationFilterValue=$$v},expression:"userLocationFilterValue"}})],1),_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"12","lg":"2"}},[_c('v-select',{attrs:{"label":"Valuta","items":_vm.currencies,"item-value":"currency_id","rules":[_vm.rules.req],"dense":""},model:{value:(_vm.currencyFilterValue),callback:function ($$v) {_vm.currencyFilterValue=$$v},expression:"currencyFilterValue"}})],1),_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"12","lg":"2"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px","dense":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"Od","prepend-icon":"mdi-calendar","readonly":"","value":_vm.fromDateDisp,"dense":""}},on))]}}]),model:{value:(_vm.fromDateMenu),callback:function ($$v) {_vm.fromDateMenu=$$v},expression:"fromDateMenu"}},[_c('v-date-picker',{attrs:{"locale":"en-in","no-title":"","rules":[_vm.rules.req]},on:{"input":function($event){_vm.fromDateMenu = false}},model:{value:(_vm.fromDateVal),callback:function ($$v) {_vm.fromDateVal=$$v},expression:"fromDateVal"}})],1)],1),_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"12","lg":"2"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"Do","prepend-icon":"mdi-calendar","readonly":"","value":_vm.toDateDisp,"dense":""}},on))]}}]),model:{value:(_vm.toDateMenu),callback:function ($$v) {_vm.toDateMenu=$$v},expression:"toDateMenu"}},[_c('v-date-picker',{attrs:{"disabled":_vm.isFromDateSelected,"locale":"en-in","no-title":""},on:{"input":function($event){_vm.toDateMenu = false}},model:{value:(_vm.toDateVal),callback:function ($$v) {_vm.toDateVal=$$v},expression:"toDateVal"}})],1)],1),_c('v-col',{staticClass:"d-flex align-center mb-4",attrs:{"cols":"1","lg":"1"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"background-color":"rgba(0, 0, 0, 0.12)"},on:{"click":_vm.userLocationFilter}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"20"}},[_vm._v("mdi-table-search")])],1)]}}])},[_c('span',[_vm._v("Dohvat podataka")])])],1),_c('v-dialog',{attrs:{"max-width":"650px"},scopedSlots:_vm._u([{key:"default",fn:function(dialog){return [_c('v-card',[_c('v-toolbar',{staticStyle:{"font-weight":"bold","font-family":"Raleway, sans-serif"},attrs:{"color":"loginButton","dark":""}},[_vm._v("Grafički prikaz prometa")]),_c('v-card-text',[_c('div',{staticClass:"text-h2 pt-12 pl-6"},[_c('apexchart',{attrs:{"width":"500","type":"line","options":_vm.chartOptions,"series":_vm.series}})],1)]),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){dialog.value = false}}},[_vm._v("Close")])],1)],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}})],1),_c('v-row',[(_vm.receiptsFromCurrentCompany !== null)?_c('v-container',[_c('v-data-table',{ref:"myTable",staticClass:"ml-13 mr-13 elevation-1",attrs:{"headers":_vm.headers,"items":_vm.tableItems,"disable-sort":"","dense":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"dense":"","flat":"","outlined":"","rounded":"","shaped":""}},[_c('v-spacer'),_c('v-toolbar-title',[_c('vue-excel-xlsx',{attrs:{"data":_vm.exceltableItems,"columns":_vm.columns,"file-name":'Zbirni Izvjestaj',"file-type":'xlsx',"sheet-name":'Izvjestaj'}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"rounded":"","color":"primary","plain":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"20"}},[_vm._v("mdi-microsoft-excel")])],1)]}}],null,false,3248971907)},[_c('span',[_vm._v("Download xlsx datoteke")])])],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","plain":"","rounded":""},on:{"click":function($event){_vm.dialog = true}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"20"}},[_vm._v("mdi-chart-line")])],1)]}}],null,false,2542094633)},[_c('span',[_vm._v("Prikaži graf")])])],1)],1)]},proxy:true},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',[_vm._v(_vm._s(item.datum))]),_c('td',[_vm._v(_vm._s(item.gotovina))]),_c('td',[_vm._v(_vm._s(item.kartice))]),_c('td',[_vm._v(_vm._s(item.trans))]),_c('td',[_vm._v(_vm._s(item.ostalo))]),_c('td',[_vm._v(_vm._s(item.iznos))])])]}}],null,false,1483361602)},[_c('template',{slot:"body.append"},[_c('tr',{staticStyle:{"font-size":"80px"}},[_c('th'),_c('th'),_c('th'),_c('th'),_c('th',{staticStyle:{"text-align":"right"}},[_vm._v(" Ukupno ("+_vm._s(_vm.fromDateDisp)+" - "+_vm._s(_vm.toDateDisp)+") ")]),_c('th',{staticStyle:{"text-align":"center"}},[_vm._v(" "+_vm._s(_vm._f("money")(this.totalSum,100, this.currencyFilterValue))+" ")])])])],2)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }