<template>
  <v-dialog
    v-model="visible"
    @keydown.esc="close"
    persistent
    scrollable
    max-width="600"
    style="z-index: 6"
  >
    <v-form ref="addItemForm" @submit.prevent="submit">
      <v-card
        class="overflow-auto"
        :class="{ 'mobile-card': $vuetify.breakpoint.smAndDown }"
      >
        <v-card-title class="d-flex" style="vertical-align: middle">
          <v-row class="pa-3 mb-2">
            <h3 class="text--secondary">Popust</h3>
            <v-spacer></v-spacer>
            <v-icon large @click="close">mdi-close</v-icon>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row v-if="discount !== undefined" class="flex-wrap justify-center">
            <v-col>
              <v-row class="pa-3">
                <v-textarea
                  rows="2"
                  class="pt-2"
                  dense
                  outlined
                  label="Naziv"
                  autofocus
                  v-model="discount.name"
                  :rules="[rules.req]"
                ></v-textarea>
              </v-row>
              <v-row>
                <v-col>
                  <v-select
                    dense
                    outlined
                    label="Vrsta popusta"
                    v-model="discount.type"
                    item-text="name"
                    :items="discountTypes"
                    :rules="[rules.req]"
                    @change="setToNull()"
                  >
                  </v-select>
                </v-col>
                <v-col v-if="discount.type == 'RATE'" cols="3">
                  <!-- <v-text-field dense outlined label="Postotak" v-model="rate">
                  </v-text-field> -->
                  <quantity-input
                    dense
                    outlined
                    label="Postotak"
                    v-model="discount.rate"
                    suffix="%"
                  ></quantity-input>
                </v-col>
                <v-col v-if="discount.type == 'AMOUNT'" cols="3">
                  <!-- <v-text-field dense outlined label="Iznos" v-model="amount">
                  </v-text-field> -->

                  <quantity-input
                    dense
                    outlined
                    label="Iznos"
                    v-model="discount.amount"
                    suffix="€"
                  ></quantity-input>
                </v-col>
                <v-col cols="3">
                  <v-checkbox
                    class="pd-5"
                    label="Aktivan"
                    v-model="discount.active"
                  >
                  </v-checkbox>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-select
                    v-model="discount.users"
                    :items="users"
                    label="Korisnici koji mogu koristiti popust"
                    multiple
                    outlined
                    dense
                    clearable
                    item-text="name"
                    item-value="id"
                  >
                  </v-select>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-center pb-5">
          <v-btn
            class="white--text okButton"
            height="45"
            :loading="submitting"
            :disabled="submitting"
            type="submit"
          >
            {{ $t("$vuetify.save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import { df, auth } from '@/plugins/firebase'
import { v4 as uuidv4 } from 'uuid'
import rules from '@/plugins/rules'
import state from '@/state'
import { clone } from '@/plugins/utils'
import requestChecker from '@/mixins/requestChecker'
import QuantityInput from '../../../../components/QuantityInput.vue'

export default {
  inject: ['showMsgBox', 'showLoader', 'hideLoader'],
  components: { QuantityInput },
  mixins: [requestChecker],
  data: () => ({
    visible: false,
    submitting: false,
    discountTypes: [],
    discount: undefined,
    name: undefined,
    rate: undefined,
    amount: undefined,
    users: undefined,
    type: undefined,
    rules: {
      req: rules.req(),
      eq: rules.eq
    }
  }),
  mounted () {
    this.discountTypes = [
      { name: this.$t('$vuetify.discount.rate'), value: 'RATE' },
      { name: this.$t('$vuetify.discount.amount'), value: 'AMOUNT' },
      { name: this.$t('$vuetify.discount.manual'), value: 'MANUAL' }
    ]

    df.collection(`companies/${state.getCurrentCompany().id}/users`)
      .get()
      .then((documentSnapshots) => {
        this.loading = false
        if (documentSnapshots.docs && !documentSnapshots.docs.empty) {
          this.users = []
          documentSnapshots.docs.forEach((user) => {
            if (user.data().config.active === true) {
              this.users.push(user.data().user)
            }
          })
        }
      })
  },
  methods: {
    setToNull () {
      if (this.discount.type === 'RATE') {
        this.discount.amount = 0
      } else {
        this.discount.rate = 0
      }
    },
    open (discount) {
      this.visible = true
      this.submitting = false
      this.discount = clone(discount)

      if (this.discount.amount !== undefined && this.discount.amount > 0) {
        this.discount.amount /= 100
      }

      if (this.discount.rate !== undefined && this.discount.rate > 0) {
        this.discount.rate /= 100
      }
    },
    close () {
      this.visible = false
    },
    submit () {
      const that = this
      //   this.showLoader()
      this.submitting = true
      const reqId = uuidv4()

      const payload = {
        action: {
          operation: 'update',
          entity: 'discount',
          resource_id: this.discount.id,
          params: {
            company_id: this.discount.company_id,
            name: this.discount.name,
            active: this.discount.active,
            type: this.discount.type
          }
        }
      }
      if (this.discount.users !== undefined && this.discount.users !== null && this.discount.users.length > 0) {
        payload.action.params.users = this.discount.users
      }

      if (this.discount.rate !== undefined && parseInt(this.discount.rate) > 0) {
        this.discount.rate = parseFloat(this.discount.rate.toString().replace(',', '.'))
        payload.action.params.rate = parseInt(this.discount.rate * 100)
      }

      if (this.discount.amount !== undefined && parseInt(this.discount.amount) > 0) {
        this.discount.amount = parseFloat(this.discount.amount.toString().replace(',', '.'))
        payload.action.params.amount = parseInt(this.discount.amount * 100)
      }

      const cleanParams = this.getCleanParams(this.discount)
      Object.keys(cleanParams).forEach((key) => {
        delete payload.action.params[key]
      })

      try {
        df.doc(`request/${reqId}`)
          .set({
            user_id: `${auth.currentUser.uid}`,
            device_id: 'web',
            created: `${new Date().getTime()}`,
            type: 'discount',
            payload: btoa(
              unescape(encodeURIComponent(JSON.stringify(payload)))
            )
          })
          .then(function () {
            // setTimeout(() => {
            that.$emit('success', { ...payload, ...{ action: 'EDIT' } })
            that.submitting = false
            that.visible = false
            // }, 2000);
          })
          .catch(function (err) {
            that.showMsgBox({
              text: err && err !== '' ? err : 'An error has occurred',
              actions: ['cancel'],
              cancelBtnText: 'OK',
              color: 'error'
            })
            that.submitting = false
          })
      } catch (err) {
        that.showMsgBox({
          text: err && err !== '' ? err : 'An error has occurred',
          actions: ['cancel'],
          cancelBtnText: 'OK',
          color: 'error'
        })
        that.submitting = false
      }
    }
  }
}
</script>
