<template>
  <div>
    <add-user
      ref="addUser"
      @action="setLoading"
      @refreshUser="reload"
    ></add-user>
    <edit-user
      ref="editUser"
      @action="setLoading"
      @remove="setRemovingUser"
      @refreshUser="reload"
    ></edit-user>
    <v-container>
      <v-row class="pt-3 pr-3">
        <v-spacer></v-spacer>
        <Search-box
          :emitDestination="searchEmitDestination"
          searchPlaceholder="Pretraži korisnike"
        ></Search-box>
      </v-row>
      <v-row style="margin-top: 0px" class="d-flex justify-center">
        <v-col cols="12" sm="11" lg="11" :class="['px-0']">
          <v-row class="d-flex justify-center">
            <v-col cols="6" md="3" class="ml-0 pl-0">
              <v-select
                outlined
                dense
                label="Firma"
                :items="companies"
                v-model="company"
                return-object
                item-text="name"
                item-value="id"
                @change="companyChange"
                @click:clear="resetAll"
                clearable
              ></v-select>
            </v-col>
            <v-col cols="6" md="3" class="ml-0 pl-0">
              <v-select
                outlined
                dense
                label="Prodajno mjesto"
                :items="locations"
                v-model="location"
                item-text="name"
                item-value="id"
                return-object
                clearable
                @change="resetInfiniteLoader"
                @click:clear="resetInfiniteLoader"
              ></v-select>
            </v-col>
          </v-row>
          <v-row style="margin-top: 0px" class="d-flex justify-center">
            <v-col cols="12" :class="['px-0']">
              <v-card
                v-for="(doc, i) in sortedUsers"
                :key="i"
                hover
                tabindex="0"
              >
                <v-row
                  :class="[
                    'ma-0',
                    !doc.user.status || doc.user.status === 'OK'
                      ? 'status-ok-exp'
                      : `${
                          doc.user.status === 'PROCESSING'
                            ? 'status-processing-exp'
                            : 'status-failed-exp'
                        }`,
                    doc.user.active ? 'status-ok-exp' : 'status-failed-exp',
                  ]"
                  style="height: 50px !important"
                >
                  <div
                    style="width: 10% !important"
                    :class="[densityPadding]"
                    class="pl-2"
                  >
                    <div class="text-capitalize caption grey--text text-left">
                      Prezime
                    </div>
                    <div class="text-capitalize text-left">
                      {{ doc.user.surname }}
                    </div>
                  </div>
                  <div style="width: 10% !important" :class="[densityPadding]">
                    <div class="text-capitalize caption grey--text text-left">
                      Ime
                    </div>
                    <div class="text-capitalize text-left">
                      {{ doc.user.name }}
                    </div>
                  </div>
                  <div
                    style="width: 13% !important"
                    :class="[densityPadding, 'hidden-md-and-down']"
                  >
                    <div class="caption grey--text text-left">Oib</div>
                    <div class="text-left">{{ doc.user.oib }}</div>
                  </div>
                  <div
                    style="width: 8% !important"
                    :class="[densityPadding, ' hidden-md-and-down']"
                  >
                    <div class="caption grey--text text-center">Aktivan</div>
                    <div class="text-center">
                      {{ doc.config.active | yesno }}
                    </div>
                  </div>
                  <div style="width: 23% !important" :class="[densityPadding]">
                    <div class="caption grey--text text-left">
                      Korisničko ime
                    </div>
                    <div class="text-left">{{ doc.user.email }}</div>
                  </div>
                  <div
                    style="
                      min-width: 100px;
                      max-width: 100%;
                      width: 23% !important;
                    "
                    :class="[densityPadding, 'flex-grow-1', 'flex-shrink-0']"
                  >
                    <div class="caption grey--text">Privilegije</div>
                    <span
                      class="text-capitalize"
                      v-for="(role, i) in doc.user.roles
                        ? Object.keys(doc.user.roles)
                        : []"
                      :key="role"
                    >
                      <span v-if="doc.user.roles[role]">{{
                        `${role}${
                          doc.user.roles &&
                          Object.keys(doc.user.roles).length - 1 > i
                            ? ", "
                            : ""
                        }`
                      }}</span>
                    </span>
                  </div>
                  <div style="width: 7% !important" :class="[densityPadding]">
                    <div class="d-flex align-start justify-center pb-1">
                      <v-btn
                        class="mt-0 pt-0"
                        :x-small="density === 'greater'"
                        tabindex="-1"
                        icon
                        @click.stop="editUser(doc.user)"
                        @keypress.enter.stop=""
                      >
                        <v-icon>mdi-pencil-outline</v-icon>
                      </v-btn>
                    </div>
                  </div>
                </v-row>
                <v-divider></v-divider>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <infinite-loading
        ref="InfiniteLoading"
        @infinite="infiniteHandler"
        spinner="waveDots"
      >
        <div slot="no-more"></div>
        <div slot="no-results">
          <v-row class="d-flex justify-center 'mt-0'">
            <v-col cols="12" sm="11" lg="11" :class="['px-0', 'pt-0']">
              <v-card hover tabindex="0">
                <!--
            @keypress.enter=""
            @click="" -->
                <v-row class="ma-0 status-processing justify-center">
                  <v-col>
                    <div class="text-center">
                      Ne postoje korisnici po odabranim kriterijima.
                    </div>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </infinite-loading>
    </v-container>
  </div>
</template>
<script>
import applicationSettings from '@/mixins/applicationSettings'
import addUser from '@/modules/companies/components/users/components/AddUser'
import editUser from '@/modules/companies/components/users/components/EditUser'
import { auth, df } from '@/plugins/firebase'
import { clone, duck } from '@/plugins/utils'
import InfiniteLoading from 'vue-infinite-loading'
import state from '@/state'
import EventBus from '@/plugins/event-bus'
import { collection, query, orderBy, getDocs, where, limit, startAfter } from 'firebase/firestore'

export default {
  name: 'Users',
  mixins: [applicationSettings],
  components: { addUser, editUser, InfiniteLoading },
  data: function () {
    return {
      visible: false,
      loading: false,
      lastVisible: '',
      submitting: false,
      companies: [],
      company: undefined,
      locations: [],
      location: undefined,
      registers: [],
      register: undefined,
      users: [],
      sortDirections: [
        { name: 'Uzlazno', id: 'asc' },
        { name: 'Silazno', id: 'desc' }
      ],
      sortDirection: { name: 'Uzlazno', id: 'asc' },
      sortOption: { name: 'Korisničko ime', id: 'email' },
      sortOptions: [
        { name: 'Ime', id: 'name' },
        { name: 'Prezime', id: 'surname' },
        { name: 'Korisničko ime', id: 'email' }
      ],
      removingUser: undefined,
      searchEmitDestination: 'userSearch'
    }
  },
  computed: {
    sortedUsers () {
      const localUsers = clone(this.users)
      return localUsers.sort((a, b) => a.user.surname.localeCompare(b.user.surname))
    }
  },
  mounted () {
    const user = state.getUser()

    if (!user.roles.ADMIN && !user.roles.SUPERADMIN) {
      this.$router.push({
        name: 'pointOfSale.cashRegisters',
        params: { companyId: state.getCurrentCompany().id, locationId: state.getPointOfSale().id }
      })
      return
    }
    this.listeners.push(
      df.doc(`user_companies/${auth.currentUser.uid}`).onSnapshot(doc => {
        if (doc?.data()?.companies) {
          const companies = Object.keys(doc.data().companies).map(key => {
            return doc.data().companies[key]
          })
          this.companies = clone(companies.sort((a, b) => a.name.localeCompare(b.name)))

          if (!duck(state.getCurrentCompany(), {})) {
            this.company = this.companies.find(company => state.getCurrentCompany().id === company.id)
          } else {
            this.company = this.companies[0]
          }
          this.companyChange()
        }
      })
    )

    EventBus.$on('add-role', _user => {
      if (this.$refs?.editUser) {
        this.$refs.editUser.open(_user, state.getCurrentCompany())
      }
    })

    EventBus.$on(this.searchEmitDestination, (searchObject) => {
      // console.log(searchObject.triGramObject)
      if (!searchObject.triGramObject) {
        this.lastDocSnapshot = null
        this.detachListeners()
        this.resetHandler()
      } else {
        this.getUsers(searchObject.triGramObject)
      }
    })
  },
  watch: {
    search () {
      this.lastVisible = ''
      this.users = []
      if (this.$refs.InfiniteLoading) {
        this.$refs.InfiniteLoading.stateChanger.reset()
      }
    }
  },
  methods: {
    reload () {
      setTimeout(() => {
        this.lastDocSnapshot = null
        this.detachListeners()
        this.resetHandler()
      }, 1000)
    },
    resetHandler () {
      this.users = []
      if (this.$refs.InfiniteLoading) {
        this.$refs.InfiniteLoading.stateChanger.reset()
      }
    },
    resetDefaults () {
      this.company = undefined
    },

    resetAll () {
      this.company = undefined
      this.location = undefined
      this.locations = []
      this.users = []
    },

    setRemovingUser (user) {
      this.users = this.users.map(us => {
        const ret = us
        if (us.id === user.id) ret.user.status = 'PROCESSING'
        return ret
      })
      this.removingUser = { ...user, status: 'PROCESSING' }
    },
    setLoading (user) {
      this.users = this.users.map(us => {
        const ret = us
        if (us.user.id === user.id) ret.user.status = 'PROCESSING'
        return ret
      })
      if (user.timeout && !this.location) {
        setTimeout(() => {
          this.users = this.users.map(us => {
            const ret = us
            if (us.user.id === user.id) ret.user.status = 'OK'
            return ret
          })
        }, 7000)
      }
    },
    // modifyItems (change) {
    //   if (change.type === 'added') {
    //     if (change.doc.data()) {
    //       const _document = clone(change.doc.data())
    //       if (this.users.filter(doc => doc.user.id === _document.user.id).length === 0) {
    //         this.users.push({ ..._document, ...{ [`hover-${change.doc.data().user.id}`]: false } })
    //       }
    //     }
    //   }
    //   if (change.type === 'modified') {
    //     this.users = this.users.map(item => {
    //       let ret = item
    //       if (item.user.id === change.doc.data().user.id) {
    //         const _document = clone(change.doc.data())
    //         if (_document?.user?.id === state.getUser()?.id) state.setUser(_document.user) // set refreshed user
    //         ret = _document
    //       }
    //       return { ...ret, ...{ [`hover-${change.doc.data().user.id}`]: false } }
    //     })
    //   }
    //   if (change.type === 'removed') {
    //     if (this.removingUser.id === change.doc.data().id) {
    //       this.users = this.users.filter(item => this.removingUser.user.id !== item.user.id)
    //     }
    //   }
    // },

    async infiniteHandler ($state) {
      if (!this.company) {
        return $state.complete()
      }

      const newItemsCount = await this.getUsers()
      if (newItemsCount > 0) {
        return $state.loaded() // getItems je vratio vise od nula artikala, znaci da ih ima jos
      }
      return $state.complete() // getItems je vratio 0 artikala, znaci da ih vise nema
    },

    async getUsers (triGramObject = undefined) {
      const searchConstraints = []
      if (triGramObject) {
        Object.keys(triGramObject).forEach(name =>
          searchConstraints.push(where(`user.meta.${name}`, '==', true))
        )
      }

      if (!triGramObject) {
        searchConstraints.push(orderBy('user.name', 'asc'))
      }

      if (this.lastDocSnapshot && !triGramObject) {
        searchConstraints.push(startAfter(this.lastDocSnapshot))
      }

      // searchConstraints.push(where('user.id', '!=', 'Zg4qIpxX2uNGKvjznSVnICZAVtJ2'))
      searchConstraints.push(limit(10))

      let source = 'companies'
      if (this.location) {
        source = 'locations'
      }

      const constraints = [
        collection(df, `${source}/${source === 'locations' ? this.location.id : this.company.id}/users`),
        ...searchConstraints
      ]

      const q = query.apply(this, constraints)

      const querySnapshot = await getDocs(q)
      this.lastDocSnapshot = querySnapshot.docs[querySnapshot.docs.length - 1]

      const results = []
      querySnapshot.forEach(doc => {
        if (doc.id !== 'Zg4qIpxX2uNGKvjznSVnICZAVtJ2') {
          results.push({ _id: doc.id, ...doc.data() })
        }
      })

      if (triGramObject) {
        this.users = results
        this.users = this.users.sort((a, b) => a.name.localeCompare(b.name))
        return true
      }

      this.users.push(...results)
      return results.length
    },

    // infiniteHandler ($state) {
    //   if (!this.company) {
    //     this.users = []
    //     $state.complete()
    //     return
    //   }
    //   let source = 'companies'
    //   if (this.location) {
    //     source = 'locations'
    //   }
    //   const where = this.search ? this.search.toLowerCase() : ''
    //   let query = df.collection(`${source}/${source === 'locations' ? this.location.id : this.company.id}/users`)
    //     .where('user.id', '!=', 'Zg4qIpxX2uNGKvjznSVnICZAVtJ2')
    //     .orderBy('user.id', 'asc')
    //     .limit(10)
    //     .startAfter(this.lastVisible)

    //   if (where) {
    //     query = query.where(this.sortOption.id, '>=', where).where(this.sortOption.id, '<=', where + '\uf8ff')
    //   }

    //   const listener = query
    //     .onSnapshot((doc) => {
    //       doc.docChanges().forEach((change) => {
    //         this.modifyItems(change)
    //       })
    //       if (doc && !doc.empty) {
    //         if (doc.docs[doc.docs.length - 1]) {
    //           this.lastVisible = doc.docs[doc.docs.length - 1]
    //         }
    //         $state.loaded()
    //       } else {
    //         $state.complete()
    //       }
    //     })

    //   this.listeners.push(listener)
    // },
    companyChange () {
      this.detachListeners()
      this.lastVisible = ''
      this.users = []
      this.$refs.InfiniteLoading.stateChanger.reset()
      this.lastDocSnapshot = null
      this.location = undefined
      if (this.company) this.setLocations()
    },
    resetInfiniteLoader () {
      this.detachListeners()
      this.lastVisible = ''
      this.lastDocSnapshot = null

      this.users = []
      if (this.$refs.InfiniteLoading) {
        this.$refs.InfiniteLoading.stateChanger.reset()
      }
    },
    async setLocations () {
      const _locations = await df.doc(`user_locations/${auth.currentUser.uid}.${this.company.id}`).get()

      let locations = []
      if (_locations?.data()?.locations) {
        locations = Object.keys(_locations.data().locations).map(key => {
          return _locations?.data()?.locations[key]
        })
      }
      this.locations = clone(locations.sort((a, b) => a.name.localeCompare(b.name)))
    },
    addUser () {
      this.$refs.addUser.open(this.companies)
    },
    editUser (user) {
      if (user.status !== 'PROCESSING') {
        this.$refs.editUser.open(user, this.company)
      }
    }
  }
}
</script>
