<template>
  <v-dialog v-model="openDialog" width="1400">
    <v-card>
      <v-card-title>
        <v-col>
          <v-row class="pa-3 mb-2">
            <h2 class="text--secondary">Artikli dobavljača</h2>
            <v-spacer></v-spacer>
            <v-icon large @click="close">mdi-close</v-icon>
          </v-row>
        </v-col>
      </v-card-title>
      <v-card-text>
        <v-row style="margin-top: 5px" class="d-flex justify-center">
          <v-col cols="12" sm="11" lg="11" :class="['px-0']">
            <v-btn
              @click="uploadSupplierItem"
              v-if="supplier && supplier.editable === true"
              >Uvezi artikle za dobavljača</v-btn
            >
            <v-btn @click="uploadSupplierItemPrice"
              >Uvezi cijene za artikle dobavljača</v-btn
            >
            <v-expansion-panels focusable>
              <v-expansion-panel
                v-for="(item, i) in items"
                :key="i"
                hover
                tabindex="0"
              >
                <v-expansion-panel-header height="200" hide-actions>
                  <v-row>
                    <v-col cols="4" :class="[densityPadding]">
                      <div class="caption grey--text text-center">
                        Naziv kod dobavljača
                      </div>
                      <div class="text-center">{{ item.name }}</div>
                    </v-col>

                    <v-col cols="3" :class="[densityPadding]">
                      <div class="caption grey--text text-center">
                        Šifra artikla
                      </div>
                      <div class="text-center">{{ item.code }}</div>
                    </v-col>
                    <v-col cols="2" :class="[densityPadding]">
                      <div class="caption grey--text text-center">
                        Mjerna jedinica
                      </div>
                      <div class="text-center">{{ item.unit }}</div>
                    </v-col>
                    <v-col cols="2" :class="[densityPadding]">
                      <v-btn @click.prevent.stop="addSupplierItemPrice(item)"
                        >Dodaj cijenu za artikl</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row>
                    <v-col cols="12" sm="5"> </v-col>
                  </v-row>
                  <v-list>
                    <v-row> </v-row>
                    <!-- <v-list-item
                    v-for="(item, index) in items"
                    :key="index"
                    class="pa-0"
                  >
                    <v-row class="pa-0">
                      <v-col>
                        <div class="text-center text-subtitle-2">
                          {{ index + 1 }}
                        </div>
                      </v-col>
                      <v-col>
                        <div class="text-center text-subtitle-2">
                          {{ item.name }}
                        </div>
                      </v-col>
                      <v-col>
                        <div class="text-center text-subtitle-2">
                          {{ item.amt / multiplier }}
                        </div>
                      </v-col>
                      <v-col>
                        <div class="text-center text-subtitle-2">
                          {{
                            item.single_item_price
                              | money(100, receipt.currency)
                          }}
                        </div>
                      </v-col>
                      <v-col>
                        <div class="text-center text-subtitle-2">
                          {{ item.price | money(100, receipt.currency) }}
                        </div>
                      </v-col>
                    </v-row>
                  </v-list-item> -->
                  </v-list>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
        <infinite-loading
          ref="InfiniteLoading"
          @infinite="infiniteHandler"
          spinner="waveDots"
        >
          <div slot="no-more"></div>
          <div slot="no-results"></div>
        </infinite-loading> </v-card-text
    ></v-card>
    <supplier-item-add-price
      ref="supplierItemAddPrice"
    ></supplier-item-add-price>
    <supplier-items-upload ref="supplierItemsUpload"></supplier-items-upload>
    <supplier-items-price-upload
      ref="supplierItemsPriceUpload"
    ></supplier-items-price-upload>
  </v-dialog>
</template>
<script>
import { df } from '@/plugins/firebase'
// import state from '@/state'
import { clone } from '@/plugins/utils'
import InfiniteLoading from 'vue-infinite-loading'
import flow from '@/mixins/flow'
import applicationSettings from '@/mixins/applicationSettings'
import SupplierItemAddPrice from '@/modules/company/components/suppliers/pricelist/SupplierItemAddPrice.vue'
import SupplierItemsUpload from './SupplierItemsUpload.vue'
import SupplierItemsPriceUpload from './SupplierItemsPriceUpload.vue'

export default {
  components: {
    InfiniteLoading,
    SupplierItemAddPrice,
    SupplierItemsUpload,
    SupplierItemsPriceUpload
  },
  mixins: [flow, applicationSettings],
  inject: [
    'showMsgBox',
    'showLoader',
    'hideLoader'

  ],
  data: function () {
    return {
      loading: false,
      lastVisible: '',
      listeners: [],
      multiplier: 0,
      items: [],
      supplier: undefined,
      location: undefined,
      openDialog: false
    }
  },
  watch: {
    openDialog (nv) {
      if (!nv) {
        this.items = []
        this.close()
      }
      if (nv) {
        this.reset()
      }
    }
  },
  mounted () {
    this.getMultiplier()
  },
  methods: {
    open (supplier, location) {
      this.supplier = supplier
      this.location = location
      this.openDialog = true
    },
    close () {
      this.lastVisible = ''
      this.detachListeners()
      this.openDialog = false
    },
    detachListeners () {
      this.listeners.forEach((listener) => {
        listener()
        this.listeners = this.listeners.filter((l) => l !== listener)
      })
    },
    reset () {
      if (this.$refs.InfiniteLoading) {
        this.$refs.InfiniteLoading.stateChanger.reset()
      }
    },
    uploadSupplierItem () {
      this.$refs.supplierItemsUpload.open(this.supplier, this.location)

      // this.$router.push({
      //   name: 'pointOfSale.suppliers.supplierItemsUpload',
      //   params: {
      //     supplier: this.supplier,
      //     location: this.location
      //   }
      // })
    },
    uploadSupplierItemPrice () {
      this.$refs.supplierItemsPriceUpload.open(this.supplier, this.location)

      // this.$router.push({
      //   name: 'pointOfSale.suppliers.supplierItemsPriceUpload',
      //   params: {
      //     supplier: this.supplier,
      //     location: this.location
      //   }
      // })
    },
    addSupplierItemPrice (item) {
      var data = {
        item: item,
        supplier: this.supplier,
        location: this.location
      }
      this.$refs.supplierItemAddPrice.open(data)
    },
    getMultiplier () {
      const listener = df.doc('project_constants/config').onSnapshot(doc => {
        this.config = doc.data()
        this.multiplier = doc.data().item_amount.decimal_multiplier
      })
      this.listeners.push(listener)
    },
    infiniteHandler ($state) {
      if (this.supplier === undefined) {
        return
      }
      const that = this

      const query = df.collection(`suppliers/${this.supplier.id}/items`).orderBy('name')
        .limit(10)
        .startAfter(that.lastVisible)
      const listener = query.onSnapshot((doc) => {
        doc.docChanges().forEach((change) => {
          that.modifyItems(change)
        })
        if (doc && !doc.empty) {
          if (doc.docs[doc.docs.length - 1]) {
            that.lastVisible = doc.docs[doc.docs.length - 1]
          }
          $state.loaded()
        } else {
          $state.complete()
        }
      })
      this.listeners.push(listener)
    },
    modifyItems (change) {
      if (change.type === 'added') {
        if (change.doc.data()) {
          const _document = clone(change.doc.data())

          this.items.push({
            ..._document,
            ...{ [`hover-${change.doc.data().id}`]: false }
          })
          // }

          this.items.sort((a, b) => a.name - b.name) // b - a for reverse sort
          this.$forceUpdate()
        }
      }
      if (change.type === 'modified') {
        this.items = this.items.map(item => {
          let ret = item
          if (item.id === change.doc.data().id) {
            const _document = clone(change.doc.data())

            ret = _document
          }
          return { ...ret, ...{ [`hover-${change.doc.data().id}`]: false } }
        })
      }
    }
  }
}
</script>
<style scoped>
tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.15);
}
tr:hover {
  cursor: pointer;
}
</style>

<style>
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
