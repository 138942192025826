<template>
  <div>
    <v-row
      class="ml-13 mt-5 mr-13"
      style="background-color: rgba(0, 0, 0, 0.04)"
      rounded
    >
      <v-col class="d-flex align-center" cols="12" lg="2">
        <v-select
          label="Prodajno mjesto"
          :items="locations"
          item-text="name"
          item-value="location_id"
          v-model="userLocationFilterValue"
          :rules="[rules.req]"
          dense
        >
        </v-select>
      </v-col>
      <v-col class="d-flex align-center" cols="12" lg="2">
        <v-select
          label="Valuta"
          :items="currencies"
          v-model="currencyFilterValue"
          item-value="currency_id"
          :rules="[rules.req]"
          dense
        >
        </v-select>
      </v-col>
      <v-col class="d-flex align-center" cols="12" lg="2">
        <v-menu
          v-model="fromDateMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
          dense
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              label="Od"
              prepend-icon="mdi-calendar"
              readonly
              :value="fromDateDisp"
              v-on="on"
              dense
            ></v-text-field>
          </template>
          <v-date-picker
            locale="en-in"
            v-model="fromDateVal"
            no-title
            @input="fromDateMenu = false"
            :rules="[rules.req]"
          ></v-date-picker>
        </v-menu>
      </v-col>

      <v-col class="d-flex align-center" cols="12" lg="2">
        <v-menu
          v-model="toDateMenu"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              label="Do"
              prepend-icon="mdi-calendar"
              readonly
              :value="toDateDisp"
              v-on="on"
              dense
            ></v-text-field>
          </template>

          <v-date-picker
            :disabled="isFromDateSelected"
            locale="en-in"
            v-model="toDateVal"
            no-title
            @input="toDateMenu = false"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col class="d-flex align-center mb-4" cols="1" lg="1">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              v-on:click="userLocationFilter"
              style="background-color: rgba(0, 0, 0, 0.12)"
              ><v-icon size="20">mdi-table-search</v-icon></v-btn
            > </template
          ><span>Dohvat podataka</span>
        </v-tooltip>
      </v-col>

      <v-dialog v-model="dialog" max-width="650px">
        <template v-slot:default="dialog">
          <v-card>
            <v-toolbar
              color="loginButton"
              style="font-weight: bold; font-family: Raleway, sans-serif"
              dark
              >Grafički prikaz prometa</v-toolbar
            >
            <v-card-text>
              <div class="text-h2 pt-12 pl-6">
                <apexchart
                  width="500"
                  type="line"
                  :options="chartOptions"
                  :series="series"
                ></apexchart>
              </div>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn text @click="dialog.value = false">Close</v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>
    </v-row>
    <v-row>
      <v-container v-if="receiptsFromCurrentCompany !== null">
        <v-data-table
          :headers="headers"
          :items="tableItems"
          disable-sort
          class="ml-13 mr-13 elevation-1"
          ref="myTable"
          dense
        >
          <template v-slot:top>
            <v-toolbar dense flat outlined rounded shaped
              ><v-spacer></v-spacer>
              <v-toolbar-title>
                <vue-excel-xlsx
                  :data="exceltableItems"
                  :columns="columns"
                  :file-name="'Zbirni Izvjestaj'"
                  :file-type="'xlsx'"
                  :sheet-name="'Izvjestaj'"
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        rounded
                        color="primary"
                        plain
                        ><v-icon size="20">mdi-microsoft-excel</v-icon></v-btn
                      ></template
                    >
                    <span>Download xlsx datoteke</span>
                  </v-tooltip>
                </vue-excel-xlsx>

                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      plain
                      v-bind="attrs"
                      v-on="on"
                      rounded
                      @click="dialog = true"
                    >
                      <v-icon size="20">mdi-chart-line</v-icon>
                    </v-btn></template
                  >
                  <span>Prikaži graf</span>
                </v-tooltip>
              </v-toolbar-title>
            </v-toolbar>
          </template>
          <template v-slot:item="{ item }">
            <tr>
              <td>{{ item.datum }}</td>
              <td>{{ item.gotovina }}</td>
              <td>{{ item.kartice }}</td>
              <td>{{ item.trans }}</td>
              <td>{{ item.ostalo }}</td>
              <td>{{ item.iznos }}</td>
            </tr>
          </template>
          <template slot="body.append">
            <tr style="font-size: 80px">
              <th></th>
              <th></th>
              <th></th>
              <th></th>
              <th style="text-align: right">
                Ukupno ({{ fromDateDisp }} - {{ toDateDisp }})
              </th>
              <th style="text-align: center">
                {{ this.totalSum | money(100, this.currencyFilterValue) }}
              </th>
            </tr>
          </template>
        </v-data-table>
      </v-container>
    </v-row>
  </div>
</template>

<script>
import { auth, df } from '@/plugins/firebase'

import state from '@/state'
import rules from '@/plugins/rules'
// import { toISOLocal } from '@/plugins/utils'
import VueExcelXlsx from 'vue-excel-xlsx'
import VueApexCharts from 'vue-apexcharts'
import Vue from 'vue'
Vue.use(VueExcelXlsx)
Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)
export default {
  data: vm => ({
    toggle_exclusive: null,
    dialog: false,
    rules: {
      req: rules.req(),
      money: rules.money()
    },
    receiptsFromCurrentCompany: [],
    allReceipts: [],
    receiptFormatedIssueDateTimes: [],
    chartOptions: { },
    series: [],
    columns: [
      {
        label: 'Datum',
        field: 'datum'
      },
      {
        label: 'Gotovina',
        field: 'gotovina'
      },
      {
        label: 'Kartice',
        field: 'kartice'
      },
      {
        label: 'Transakcijski',
        field: 'trans'
      },
      {
        label: 'Ostalo',
        field: 'ostalo'
      },
      {
        label: 'Ukupno',
        field: 'iznos'
      }
    ],

    // first date picker stuff
    fromDateMenu: false,
    fromDateVal: new Date().toISOString().substr(0, 10),

    // second date picker stuff ggoing wodn for the code
    toDateMenu: false,
    toDateVal: new Date().toISOString().substr(0, 10),

    userLocationFilterValue: 'all',
    currencyFilterValue: 'EUR',
    locations: [],
    currencies: ['HRK', 'EUR', 'GBP', 'USD'],
    receiptsID: [],
    totalSum: 0,

    tableItems: [],
    exceltableItems: [],

    headers: [
      { text: 'Datum', align: 'center', value: 'Datum', divider: true, dataKey: 'datum', title: 'Datum' },
      { text: 'Gotovina', align: 'center', value: 'Gotovina', divider: true, dataKey: 'gotovina', title: 'Gotovina' },
      { text: 'Kartice', align: 'center', value: 'Kartice', divider: true, dataKey: 'kartice', title: 'Kartice' },
      { text: 'Transakcijski', align: 'center', value: 'Transakcijski', divider: true, dataKey: 'trans', title: 'Transakcijski' },
      { text: 'Ostalo', align: 'center', value: 'Ostalo', divider: true, dataKey: 'ostalo', title: 'Ostalo' },
      { text: 'Ukupno', align: 'center', value: 'Ukupno', divider: true, dataKey: 'iznos', title: 'Ukupno' }
    ]
  }),

  computed: {

    isFromDateSelected () {
      if (!this.fromDateVal) {
        return true
      }
      return false
    },

    fromDateDisp () {
      return this.formatDate(this.fromDateVal)
    },

    toDateDisp () {
      return this.formatDate(this.toDateVal)
    }
  },

  watch: {
    date (val) {
      this.dateFormatted = this.formatDate(this.date)
    }

  },

  mounted () {
    this.currencyFilterValue = 'HRK'
    if (Date.now() / 1000 > 1672527599) {
      this.currencyFilterValue = 'EUR'
    }
    this.getAllCurrentCompanyPointsOfSale()
  },

  methods: {
    getAllCurrentCompanyPointsOfSale () {
      const docRef = df.doc(`user_locations/${auth.currentUser.uid}.${state.getCurrentCompany().id}`)
      docRef.onSnapshot(doc => {
        var locations = Object.values(doc.data().locations)
        locations.forEach((location, index) => {
          this.locations.push(location)
        })
      })
      this.locations.push({ name: 'Sva', location_id: 'all' })
    },

    async userLocationFilter () {
      // Resetiranje vrijednosti racuna prije nego sto se ponovno dohvate iz baze
      this.resetValues()

      // Dohvacanje racuna
      const dateFrom = new Date(this.fromDateVal).getTime() / 1000
      const dateTo = new Date(this.toDateVal).getTime() / 1000

      const snapshot = await df.collection(`receipts/${state.getCurrentCompany().id}/receipts`)
        .where('payment.current_status', 'in', ['PAID', 'REVERSED'])
        .where('issue_date_time', '>=', dateFrom)
        .where('issue_date_time', '<=', dateTo + 86399)
        .where('currency', '==', this.currencyFilterValue)
        .get()

      const documentSnapshot = await snapshot.docs.map(doc => doc.data())
      documentSnapshot.forEach(document => {
        this.receiptsFromCurrentCompany.push(document)
        this.receiptsID.push(document.designation.location_id)
      })

      this.allReceipts = this.receiptsFromCurrentCompany

      if (this.userLocationFilterValue === 'all') {
        this.receiptsFromCurrentCompany = this.allReceipts
      } else {
        const temp = []

        this.allReceipts.forEach(receipt => {
          if (receipt.designation.location_id === this.userLocationFilterValue) {
            temp.push(receipt)
          }
        })

        this.receiptsFromCurrentCompany = temp
      }
      this.extractReceiptData()

      this.chartOptions = {
        toolbar: {
          show: true,
          offsetX: 0,
          offsetY: 0,
          tools: {
            download: true,
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: true,
            reset: true | '<img src="/static/icons/reset.png" width="20">',
            customIcons: []
          },
          export: {
            csv: {
              filename: undefined,
              columnDelimiter: ',',
              headerCategory: 'category',
              headerValue: 'value',
              dateFormatter (timestamp) {
                return new Date(timestamp).toDateString()
              }
            },
            svg: {
              filename: undefined
            },
            png: {
              filename: undefined
            }
          },
          autoSelected: 'zoom'
        },
        fontFamily: 'Raleway, sans-serif',
        animations: {
          enabled: true,
          easing: 'easeinout',
          speed: 800,
          animateGradually: {
            enabled: true,
            delay: 150
          },
          dynamicAnimation: {
            enabled: true,
            speed: 350
          }
        },
        colors: ['#000000'],
        stroke: {
          curve: 'straight', width: 1
        },

        chart: {
          id: 'Ukupni promet'
        },
        xaxis: {
          categories: this.tableItems.map(row => {
            return row.datum
          })
        }
      }
      this.series = [{
        name: 'Ukupno',
        data: this.tableItems.map(row => {
          return row.grafiznos
        })
      }]
    },

    formatDate (date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${day}.${month}.${year}`
    },

    extractReceiptData () {
      function groupAndCollectSameDateItem (result, item) {
        const time = item.issue_date_time - item.issue_date_time % (3600 * 24)
        let groupList = result[time + item.currency]
        if (!groupList) {
          groupList = result[time + item.currency] = []
        }
        groupList.push(item)

        return result
      }

      const sortedArrays = this.receiptsFromCurrentCompany.reduce(groupAndCollectSameDateItem, {})
      const objectValues = Object.values(sortedArrays)
      const objectKeys = Object.keys(sortedArrays)

      const allReceiptTypesSums = []
      var sumOfAllReceipts = 0
      let objectForTable = {}

      objectValues.forEach((value, index) => {
        const trans = []
        const cash = []
        const card = []
        const ostalo = []
        let transTotal = 0
        let cashTotal = 0
        let cardTotal = 0
        let ostaloTotal = 0

        value.find(receipt => {
          if (receipt.payment_method === 'TRANSAKCIJSKI RAČUN') {
            trans.push(receipt)
            transTotal += receipt.total
          }
          if (receipt.payment_method === 'KARTICA') {
            card.push(receipt)
            cardTotal += receipt.total
          }
          if (receipt.payment_method === 'NOVČANICE') {
            cash.push(receipt)
            cashTotal += receipt.total
          }
          if (receipt.payment_method === 'OSTALO') {
            ostalo.push(receipt)
            ostaloTotal += receipt.total
          }
        })

        // Suma svih totala svih racuna
        var sumOfTotals = value.reduce(function (acc, obj) {
          return acc + obj.total
        }, 0)

        allReceiptTypesSums.push(sumOfTotals)

        var currency = objectKeys[index].replace(/[0-9]/g, '')
        objectForTable = {
          datum: this.$options.filters.local(parseInt(objectKeys[index]), false),
          gotovina: this.$options.filters.money(cashTotal, 100, currency),
          kartice: this.$options.filters.money(cardTotal, 100, currency),
          trans: this.$options.filters.money(transTotal, 100, currency),
          ostalo: this.$options.filters.money(ostaloTotal, 100, currency),
          currency: currency,
          iznos: this.$options.filters.money(cashTotal + cardTotal + transTotal + ostaloTotal, 100, currency),
          grafiznos: (cashTotal + cardTotal + transTotal) / 100
        }
        this.tableItems.push(JSON.parse(JSON.stringify(objectForTable)))
        this.exceltableItems.push(JSON.parse(JSON.stringify(objectForTable)))
      })

      // Zbrajanje totala svih vrsta racuna
      allReceiptTypesSums.forEach(item => {
        sumOfAllReceipts += item
      })

      this.totalSum = sumOfAllReceipts
      let excelFooter = {}
      excelFooter = {
        trans: 'Ukupno',
        iznos: this.$options.filters.money(sumOfAllReceipts, 100, this.currencyFilterValue)
      }

      this.exceltableItems.push(JSON.parse(JSON.stringify(excelFooter)))
    },

    resetValues () {
      this.receiptsFromCurrentCompany = []
      this.allReceipts = []
      this.tableItems = []
      this.exceltableItems = []
    }
  }
}
</script>

<style scoped>
.v-data-table > .v-data-trow > table {
  border-spacing: 0 0rem;
  justify-items: center;
}
td {
  text-align: center;
}
.sticky-table-footer td {
  font-weight: bold;
  position: sticky;
  bottom: 0;
  text-align: right;
  background-color: #f8f8f8;
  border-top: thin solid rgba(0, 0, 0, 0.12);
}
.v-btn {
  font-size: 12px;
  height: 25px;
}
</style>
