export default {
  methods: {
    isBarcodeSearch (word) {
      if (!word) return false
      const maskRe = /^\d+$/
      if (!maskRe.test(word)) return false
      if (word.length !== 13) return false
      return true
    },
    isNumber (value) {
      if (!value) return false
      const maskRe = /^\d+$/
      if (!maskRe.test(value)) return false
      return true
    }
  }
}
