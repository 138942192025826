<template>
  <div>
    <v-container class="justify-center">
      <v-form ref="generateReportForm" @submit.prevent="submit">
        <v-row>
          <v-col cols="6">
            <v-combobox
              autofocus
              label="Odaberi tip dokumenta"
              v-model="docType"
              :items="documentTypes"
              outlined
              dense
              item-text="name"
              item-value="value"
              clearable
              :rules="[rules.req]"
              class="uppercased-input"
            >
              <template v-slot:item="{ item, on, attrs }">
                <v-list-item
                  class="uppercased-input"
                  v-on="on"
                  v-bind="attrs"
                  >{{ item.name }}</v-list-item
                >
              </template>
            </v-combobox>
          </v-col>
        </v-row>
        <v-card>
          <v-card-title>
            <v-row class="pa-3 mb-2">
              <h3 class="text--secondary">GENERIRAJ KNJIGU PROMETA</h3>
              <v-spacer></v-spacer>
            </v-row>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col>
                <v-menu
                  v-model="totalFromMenu"
                  offset-y
                  :close-on-content-click="false"
                  transition="scale-transition"
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="formatFrom2"
                      label="OD DATUMA"
                      :rules="[rules.req]"
                      dense
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="from2"
                    no-title
                    scrollable
                    :max="totalTo"
                    locale="hr-HR"
                    @input="totalFromMenu = false"
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col>
                <v-menu
                  v-model="totalToMenu"
                  offset-y
                  :close-on-content-click="false"
                  transition="scale-transition"
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="formatTo2"
                      label="Do DATUMA"
                      :rules="[rules.req]"
                      dense
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="to2"
                    no-title
                    scrollable
                    locale="hr-HR"
                    :rules="[rules.req]"
                    @input="totalToMenu = false"
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-card-text>
          <v-row>
            <v-card-text>
              <v-row class="pa-3">
                <h3 class="text--secondary">
                  I. OPĆI PODACI O POREZNOM OBVEZNIKU
                </h3>
                <v-spacer></v-spacer>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    label="1. NAZIV DJELATNOSTI"
                    v-model="activityNameType"
                    outlined
                    dense
                  >
                  </v-text-field>
                </v-col>
                <v-col>
                  <v-text-field
                    label="ŠIFRA DJELATNOSTI"
                    v-model="activityCode"
                    outlined
                    dense
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    label="2. IME I PREZIME PODUZETNIKA / NOSITELJA ZAJEDNIČKE DJELATNOSTI"
                    v-model="firstLast"
                    outlined
                    dense
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    label="3. ADRESA PREBIVALIŠTA / UOBIČAJENOG BORAVIŠTA"
                    v-model="residenceAddress"
                    outlined
                    dense
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    label="4. OIB PODUZETNIKA / NOSITELJA ZAJEDNIČKE DJELATNOSTI"
                    v-model="oib"
                    outlined
                    dense
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row class="pa-3">
                <h3 class="text--secondary">II. PODACI O POSLOVNOJ JEDINICI</h3>
                <v-spacer></v-spacer>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    label="1. NAZIV"
                    v-model="locationName"
                    outlined
                    dense
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                    label="2. ADRESA"
                    v-model="locationAddress"
                    outlined
                    dense
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
            <v-row>
              <v-col>
                <v-card-actions class="d-flex justify-center pb-5">
                  <v-btn
                    class="white--text okButton"
                    height="45"
                    :disabled="submitting"
                    :loading="submitting"
                    type="submit"
                  >
                    Generiraj izvještaj
                  </v-btn>
                </v-card-actions>
              </v-col>
            </v-row>
          </v-row>
        </v-card>
      </v-form>
      <v-overlay v-show="loading" absolute z-index="10">
        <v-progress-circular
          indeterminate
          size="64"
          color="primary"
        ></v-progress-circular>
      </v-overlay>
    </v-container>
  </div>
</template>
<script>
import applicationSettings from '@/mixins/applicationSettings'
import { df, auth } from '@/plugins/firebase'
import { v4 as uuidv4 } from 'uuid'
import state from '@/state'
import rules from '@/plugins/rules'

export default {
  name: 'Reports',
  mixins: [applicationSettings],
  inject: ['showMsgBox', 'showLoader', 'hideLoader'],
  components: {},
  data: function () {
    return {
      visible: false,
      lastVisible: '',
      submitting: false,
      loading: false,
      companies: [],
      company: undefined,
      oib: '',
      firstLast: '',
      residenceAddress: '',
      entrepreneurName: '',
      activityNameType: '',
      activityAddress: '',
      showKprForm: true,
      from1: new Date(1609459260000).toISOString().substr(0, 10),
      fromMenu1: false,
      from2: '',
      fromMenu2: false,
      from3: '',
      fromMenu3: false,
      to1: new Date().toISOString().substr(0, 10),
      toMenu1: false,
      to2: '',
      toMenu2: false,
      to3: '',
      toMenu3: false,
      totalFromMenu: false,
      totalFrom: new Date(1609455600000).toISOString().substr(0, 10),
      totalToMenu: false,
      totalTo: new Date(1897388318000).toISOString().substr(0, 10),
      form: '',
      docType: { name: 'PDF', value: 'pdf' },
      locationAddress: '',
      locationName: '',
      activityCode: '',
      currency: 'HRK',
      receipts: [],
      rules: {
        req: rules.req(),
        oib: rules.oib(),
        arrReq: rules.arrReq(),
        validPass: rules.validPass,
        money: rules.money()
      }
    }
  },
  mounted () {
    this.getKpr()
  },
  watch: {
    search () {
      this.lastVisible = ''
    }
  },
  computed: {
    documentTypes () {
      return [
        { name: 'PDF', value: 'pdf' },
        { name: 'XLSX', value: 'xlsx' }
      ]
    },
    formatFrom2 () {
      if (this.from2 !== '') {
        return new Intl.DateTimeFormat('hr-HR', {
          month: '2-digit',
          day: '2-digit',
          year: 'numeric'
        }).format(new Date(this.from2))
      } else {
        return ''
      }
    },
    formatTo2 () {
      if (this.to2 !== '') {
        return new Intl.DateTimeFormat('hr-HR', {
          month: '2-digit',
          day: '2-digit',
          year: 'numeric'
        }).format(new Date(this.to2))
      } else {
        return ''
      }
    },
    totalFrom__datetime () {
      return parseInt(
        (new Date(`${this.totalFrom}`).getTime() / 1000).toFixed(0)
      )
    },
    totalTo__datetime () {
      return parseInt(
        (new Date(`${this.totalTo}T23:59:00`).getTime() / 1000).toFixed(0)
      )
    },
    from1__datetime () {
      return parseInt(
        (new Date(`${this.from1}T00:00:00`).getTime() / 1000).toFixed(0)
      )
    },
    from2__datetime () {
      return parseInt(
        (new Date(`${this.from2}T00:00:00`).getTime() / 1000).toFixed(0)
      )
    },

    from3__datetime () {
      return parseInt(
        (new Date(`${this.from3}T00:00:00`).getTime() / 1000).toFixed(0)
      )
    },
    to1__datetime () {
      return parseInt(
        (new Date(`${this.to1}T00:00:00`).getTime() / 1000).toFixed(0)
      )
    },
    to2__datetime () {
      return parseInt(
        (new Date(`${this.to2}T00:00:00`).getTime() / 1000).toFixed(0)
      )
    },

    to3__datetime () {
      return parseInt(
        (new Date(`${this.to3}T00:00:00`).getTime() / 1000).toFixed(0)
      )
    },
    from2__datetimekpr () {
      return parseInt((new Date(`${this.from2}T00:01:01`) / 1000).toFixed(0))
    },
    to2__datetimekpr () {
      return parseInt((new Date(`${this.to2}T23:59:00`) / 1000).toFixed(0))
    }
  },
  methods: {
    async getKpr () {
      try {
        let query = df
          .collection('companies/')
          .where('id', '==', state.getCurrentCompany().id)
        query.get().then((snapshot) => {
          snapshot.forEach((doc) => {
            const company = doc.data()

            this.activityNameType = company.name
            this.residenceAddress =
              company.address +
              ', ' +
              company.zip_code +
              ' ' +
              company.city +
              ', ' +
              company.country
            this.oib = company.oib
          })
        })

        query = df
          .collection('locations/')
          .where('id', '==', state.getPointOfSale().id)
        query.get().then((snapshot) => {
          snapshot.forEach((doc) => {
            const location = doc.data()

            this.locationName = location.name
            this.locationAddress =
              location.address +
              ', ' +
              location.zip_code +
              ' ' +
              location.city +
              ', ' +
              location.country
          })
        })
      } catch (error) {
        this.showMsgBox({
          text: error || 'Internal error',
          actions: ['cancel'],
          cancelBtnText: 'OK',
          color: 'error'
        })
        // console.error(error)
      }
    },
    modifyItems () {},
    submit () {
      if (!this.$refs.generateReportForm.validate()) return

      this.showLoader()
      const payload = {
        action: {
          operation: 'get',
          entity: 'sales_form',
          params: {
            company_id: state.getCurrentCompany().id,
            date_from: this.from2__datetimekpr,
            date_to: this.to2__datetimekpr,
            document_type: this.docType.value,
            location_id: state.getPointOfSale().id,
            name_of_activity: this.activityNameType,
            activity_code: this.activityCode,
            residence_address: this.residenceAddress,
            entrepreneur_oib: this.oib,
            entrepreneur_name: this.firstLast
          }
        }
      }

      const reqId = uuidv4()

      df.doc(`request/${reqId}`)
        .set({
          user_id: `${auth.currentUser.uid}`,
          device_id: 'web',
          created: `${new Date().getTime()}`,
          type: 'document',
          payload: btoa(unescape(encodeURIComponent(JSON.stringify(payload))))
        })
        .catch(() => {
          this.hideLoader()
          this.showMsgBox({
            text: 'Greška prilikom spremanja podataka.',
            actions: ['cancel'],
            cancelBtnText: 'OK',
            color: 'error'
          })
        })
        .finally(() => {})
      const unsubscribe = df.doc(`response/${reqId}`).onSnapshot((doc) => {
        if (this.$refs && doc.data()) {
          this.hideLoader()
          unsubscribe()
          if (
            !doc.data().body ||
            !doc.data().body.document ||
            doc.data().body.document === ''
          ) {
            this.showMsgBox({
              text: this.$t('$vuetify.errors.nullReportReceipts'),
              actions: ['cancel'],
              cancelBtnText: this.$t('$vuetify.close'),
              color: 'error'
            })
            this.$emit('onFinish')
            return
          }

          let linkSource = ''
          let fileName = ''
          fileName = `report-kpr.${new Date().getTime()}`
          if (this.docType.value === 'pdf') {
            linkSource = `data:application/pdf;base64,${
              doc.data().body.document
            }`
            fileName = `${fileName}.pdf`
          }

          if (this.docType.value === 'xlsx') {
            linkSource = `data:application/vnd.ms-excel;base64,${
              doc.data().body.document
            }`
            fileName = `${fileName}.xlsx`
          }

          const downloadLink = document.createElement('a')
          downloadLink.href = linkSource
          downloadLink.download = fileName
          downloadLink.click()
          this.$emit('onFinish')
        }
      })
    }
  }
}
</script>
