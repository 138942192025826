import { clone } from '@/plugins/utils'

export default {
  data: () => ({
    countries: []
  }),
  mounted () {
    let countries = {}
    const i18nIsoCountries = require('i18n-iso-countries')
    try {
      i18nIsoCountries.registerLocale(require(`i18n-iso-countries/langs/${this.$i18n.locale}.json`))
      countries = i18nIsoCountries.getNames(this.$i18n.locale, { select: 'official' })
    } catch {
      i18nIsoCountries.registerLocale(require('i18n-iso-countries/langs/en.json'))
      countries = i18nIsoCountries.getNames('en', { select: 'official' })
    }
    this.countries = Object.keys(countries).map((key) => {
      return { key: key, country: countries[key] }
    })

    const allCountries = Object.keys(countries).map((key) => {
      return { key: key, country: countries[key] }
    })
    this.countries = clone(allCountries.sort((a, b) => a.country.localeCompare(b.country)))
  }
}
