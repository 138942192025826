<template>
  <v-container fluid :style="`margin-left: ${margin}`">
    <div>
      <v-card class="pa-5 pt-3" width="97%" height="70">
        <v-row>
          <div
            style="
              width: 15% !important;
              padding-top: 15px !important;
              padding-left: 5px;
              padding-right: 5px;
            "
          >
            <v-menu v-model="reportFromMenu" offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  outlined
                  :value="formatDateFrom"
                  label="Od datuma"
                  dense
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="dateFrom"
                no-title
                scrollable
                @input="reportFromMenu = false"
              >
              </v-date-picker>
            </v-menu>
          </div>
          <div
            style="
              width: 15% !important;
              padding-top: 15px !important;
              padding-left: 5px;
              padding-right: 5px;
            "
          >
            <v-menu v-model="reportToMenu" offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  outlined
                  :value="formatDateTo"
                  label="Do datuma"
                  dense
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="dateTo"
                no-title
                scrollable
                @input="reportToMenu = false"
              >
              </v-date-picker>
            </v-menu>
          </div>
          <div
            style="
              width: 15% !important;
              padding-top: 15px !important;
              padding-left: 5px;
              padding-right: 5px;
            "
          >
            <v-select
              outlined
              dense
              label="Prodajno mjesto"
              v-model="location"
              :items="locations"
              item-text="name"
              item-value="id"
              return-object
            ></v-select>
          </div>
          <div
            style="
              max-width: 18% !important;
              padding-top: 15px;
              padding-right: 10px;
              overflow: auto;
            "
          >
            <v-btn
              :disabled="locationNotSelected"
              class="mr-3"
              @click="getData()"
              ><v-icon>mdi-magnify</v-icon></v-btn
            >
            <v-btn class="mr-2" @click="changeWidth()"
              ><v-icon>mdi-file-pdf-box</v-icon></v-btn
            >
            <v-btn class="ml-2" @click="generateExcel()"
              ><v-icon>mdi-microsoft-excel</v-icon></v-btn
            >
          </div>
          <div style="width: 25% !important">
            <v-switch
              v-model="showUnpaid"
              label="Uključi neplaćene račune"
            ></v-switch>
          </div>
          <div
            style="margin-left: 15px; padding-top: 15px; text-align: right"
            class="pt-3 pr-0"
          >
            <v-btn :to="to">X</v-btn>
          </div>
        </v-row>
        <v-row class="pt-0 mt-0 pl-2">
          <div class="d-flex justify-center"></div>
        </v-row>
      </v-card>
    </div>

    <div class="d-flex justify-center">
      <div
        id="table_info"
        justify="center"
        style="
          max-width: 1200px;
          min-width: 1200px;
          font-size: 16px;
          font-family: 'Roboto-Regular';
        "
      >
        <!-- HTML Code: Place this code in the document's body (between the 'body' tags) where the table should appear -->
        <table id="firma" style="display: none; font-family: 'Roboto-Regular'">
          <tbody>
            <tr>
              <td>
                {{ company ? $options.filters.capitalize(company.name) : "" }}
              </td>
            </tr>
            <tr>
              <td>
                {{ company ? company.address + ", " + company.city : "" }}
              </td>
            </tr>
            <tr>
              <td>OIB: {{ company ? company.oib : "" }}</td>
            </tr>
            <tr>
              <td>Prodajno mjesto: {{ location ? location.name : "" }}</td>
            </tr>
            <tr>
              <td>
                Adresa prodajnog mjesta:
                {{ location ? location.address + ", " + location.city : "" }}
              </td>
            </tr>
          </tbody>
        </table>
        <div style="font-family: font-family: Raleway, sans-serif;">
          <table
            justify="center"
            style="width: 100% !important; border: 0 !important"
            cellspacing="0"
            id="title"
          >
            <thead>
              <tr class="pb-0 mb-0" style="border: 0 !important">
                <th colspan="6" class="pb-0 mb-0">
                  <p style="font-weight: bold; font-size: 25px">
                    Porez na potrošnju
                  </p>
                </th>
              </tr>
              <tr class="pa-0 ma-0">
                <th colspan="6">
                  <p style="font-weight: bold; font-size: 12px">
                    za razdoblje od {{ formatDateFrom }} do
                    {{ formatDateTo }}
                  </p>
                </th>
              </tr>
            </thead>
          </table>
          <!-- Codes by Quackit.com -->
        </div>
        <div style="font-family: font-family: Raleway, sans-serif;">
          <table
            justify="center"
            style="width: 100% !important"
            border="1"
            cellspacing="0"
            id="tableTraffic"
            v-if="showTableData"
          >
            <thead>
              <tr :class="['header', 'naslov']" style="text-align: left">
                <th colspan="5">Pregled poreza na potrošnju</th>
              </tr>
              <tr :class="['header', 'naslov']" style="text-align: left">
                <th>Iznos</th>
                <th>Naziv poreza</th>
                <th>Osnovica</th>
                <th>Stopa</th>
                <th>Iznos</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(tax, tidx) in totalByItemPnp" :key="tax.base + tidx">
                <td>
                  {{
                    viewType === "pdf"
                      ? $options.filters
                          .money(tax.total, 1, currency)
                          .replace("€", "")
                      : tax.total
                  }}
                </td>
                <td>{{ showTaxName(tax.rate) }}</td>
                <td>
                  {{
                    viewType === "pdf"
                      ? $options.filters
                          .money(tax.base, 1, currency)
                          .replace("€", "")
                      : tax.base
                  }}
                </td>
                <td>{{ tax.rate + ",00 %" }}</td>
                <td>
                  {{
                    viewType === "pdf"
                      ? $options.filters
                          .money(tax.tax, 1, currency)
                          .replace("€", "")
                      : tax.tax
                  }}
                </td>
              </tr>

              <tr
                :class="['header', 'naslov']"
                style="font-weight: bold; text-align: left"
              >
                <th>
                  {{
                    viewType === "pdf"
                      ? $options.filters
                          .money(totalPnp.base + totalPnp.tax, 1, currency)
                          .replace("€", "")
                      : totalPnp.base + totalPnp.tax
                  }}
                </th>
                <th>Ukupno</th>
                <th>
                  {{
                    viewType === "pdf"
                      ? $options.filters
                          .money(totalPnp.base, 1, currency)
                          .replace("€", "")
                      : totalPnp.base
                  }}
                </th>
                <th>Ukupno porez</th>
                <th>
                  {{
                    viewType === "pdf"
                      ? $options.filters
                          .money(totalPnp.tax, 1, currency)
                          .replace("€", "")
                      : totalPnp.tax
                  }}
                </th>
              </tr>
            </tbody>
          </table>
        </div>
        <div style="font-family: font-family: Raleway, sans-serif;">
          <table
            justify="center"
            style="width: 100% !important"
            border="1"
            class="pt-5"
            cellspacing="0"
            v-if="showTableData"
            id="tablePayments"
          >
            <thead>
              <tr :class="['header', 'naslov']" style="text-align: left">
                <th>Način plaćanja</th>
                <th>Total</th>
                <th>Naziv poreza</th>
                <th>Osnovica</th>
                <th>Stopa</th>
                <th>Iznos</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(c, cidx) in cashPnp.pnp" :key="c.base + cidx">
                <td
                  v-if="cidx === 0"
                  :rowspan="cashPnp.pnp.length + 1"
                  style="font-weight: bold"
                >
                  Gotovina
                </td>
                <td
                  v-if="cidx === 0"
                  :rowspan="cashPnp.pnp.length + 1"
                  style="text-align: right; font-weight: bold"
                >
                  {{
                    viewType === "pdf"
                      ? $options.filters
                          .money(cashPnp.total, 1, currency)
                          .replace("€", "")
                      : cashPnp.total
                  }}
                </td>
                <td style="text-align: right">
                  {{ showTaxName(c.rate) }}
                </td>
                <td style="text-align: right">
                  {{
                    viewType === "pdf"
                      ? $options.filters
                          .money(c.base, 1, currency)
                          .replace("€", "")
                      : c.base
                  }}
                </td>
                <td style="text-align: right">
                  {{ c.rate + ",00 %" }}
                </td>
                <td style="text-align: right; font-weight: bold">
                  {{
                    viewType === "pdf"
                      ? $options.filters
                          .money(c.tax, 1, currency)
                          .replace("€", "")
                      : c.tax
                  }}
                </td>
              </tr>
              <tr v-if="cashPnp && cashPnp.pnp && cashPnp.pnp.length > 0">
                <td colspan="3" style="text-align: right; font-weight: bold">
                  Ukupno
                </td>
                <td style="text-align: right; font-weight: bold">
                  {{
                    viewType === "pdf"
                      ? $options.filters
                          .money(totalCashPnp.tax, 1, currency)
                          .replace("€", "")
                      : totalCashPnp.tax
                  }}
                </td>
              </tr>
              <tr v-for="(c, cidx) in cardPnp.pnp" :key="c.base + cidx">
                <td
                  v-if="cidx === 0"
                  :rowspan="cardPnp.pnp.length + 1"
                  style="font-weight: bold"
                >
                  Kartice
                </td>
                <td
                  v-if="cidx === 0"
                  :rowspan="cardPnp.pnp.length + 1"
                  style="text-align: right; font-weight: bold"
                >
                  {{
                    viewType === "pdf"
                      ? $options.filters
                          .money(cardPnp.total, 1, currency)
                          .replace("€", "")
                      : cardPnp.total
                  }}
                </td>
                <td style="text-align: right">
                  {{ showTaxName(c.rate) }}
                </td>
                <td style="text-align: right">
                  {{
                    viewType === "pdf"
                      ? $options.filters
                          .money(c.base, 1, currency)
                          .replace("€", "")
                      : c.base
                  }}
                </td>
                <td style="text-align: right">
                  {{ c.rate + ",00 %" }}
                </td>
                <td style="text-align: right; font-weight: bold">
                  {{
                    viewType === "pdf"
                      ? $options.filters
                          .money(c.tax, 1, currency)
                          .replace("€", "")
                      : c.tax
                  }}
                </td>
              </tr>
              <tr v-if="cardPnp && cardPnp.pnp && cardPnp.pnp.length > 0">
                <td colspan="3" style="text-align: right; font-weight: bold">
                  Ukupno
                </td>
                <td style="text-align: right; font-weight: bold">
                  {{
                    viewType === "pdf"
                      ? $options.filters
                          .money(totalCardPnp.tax, 1, currency)
                          .replace("€", "")
                      : totalCardPnp.tax
                  }}
                </td>
              </tr>

              <tr v-for="(c, cidx) in otherPnp.pnp" :key="c.base + cidx">
                <td
                  v-if="cidx === 0"
                  :rowspan="otherPnp.pnp.length + 1"
                  style="font-weight: bold; font-weight: bold"
                >
                  Ostalo
                </td>
                <td
                  v-if="cidx === 0"
                  :rowspan="otherPnp.pnp.length + 1"
                  style="text-align: right; font-weight: bold"
                >
                  {{
                    viewType === "pdf"
                      ? $options.filters
                          .money(otherPnp.total, 1, currency)
                          .replace("€", "")
                      : otherPnp.total
                  }}
                </td>
                <td style="text-align: right">
                  {{ showTaxName(c.rate) }}
                </td>
                <td style="text-align: right">
                  {{
                    viewType === "pdf"
                      ? $options.filters
                          .money(c.base, 1, currency)
                          .replace("€", "")
                      : c.base
                  }}
                </td>
                <td style="text-align: right">
                  {{ c.rate + ",00 %" }}
                </td>
                <td style="text-align: right; font-weight: bold">
                  {{
                    viewType === "pdf"
                      ? $options.filters
                          .money(c.tax, 1, currency)
                          .replace("€", "")
                      : c.tax
                  }}
                </td>
              </tr>
              <tr v-if="otherPnp && otherPnp.pnp && otherPnp.pnp.length > 0">
                <td colspan="3" style="text-align: right; font-weight: bold">
                  Ukupno
                </td>
                <td style="text-align: right; font-weight: bold">
                  {{
                    viewType === "pdf"
                      ? $options.filters
                          .money(totalOtherPnp.tax, 1, currency)
                          .replace("€", "")
                      : totalOtherPnp.tax
                  }}
                </td>
              </tr>

              <tr
                v-for="(c, cidx) in transactionalPnp.pnp"
                :key="c.base + cidx"
              >
                <td
                  v-if="cidx === 0"
                  :rowspan="transactionalPnp.pnp.length + 1"
                  style="font-weight: bold"
                >
                  Transakcijski račun
                </td>
                <td
                  v-if="cidx === 0"
                  :rowspan="transactionalPnp.pnp.length + 1"
                  style="text-align: right; font-weight: bold"
                >
                  {{
                    viewType === "pdf"
                      ? $options.filters
                          .money(transactionalPnp.total, 1, currency)
                          .replace("€", "")
                      : transactionalPnp.total
                  }}
                </td>
                <td style="text-align: right">
                  {{ showTaxName(c.rate) }}
                </td>
                <td style="text-align: right">
                  {{
                    viewType === "pdf"
                      ? $options.filters
                          .money(c.base, 1, currency)
                          .replace("€", "")
                      : c.base
                  }}
                </td>
                <td style="text-align: right">
                  {{ c.rate + ",00 %" }}
                </td>
                <td style="text-align: right; font-weight: bold">
                  {{
                    viewType === "pdf"
                      ? $options.filters
                          .money(c.tax, 1, currency)
                          .replace("€", "")
                      : c.tax
                  }}
                </td>
              </tr>
              <tr
                v-if="
                  transactionalPnp &&
                  transactionalPnp.pnp &&
                  transactionalPnp.pnp.length > 0
                "
              >
                <td colspan="3" style="text-align: right; font-weight: bold">
                  Ukupno
                </td>
                <td style="text-align: right; font-weight: bold">
                  {{
                    viewType === "pdf"
                      ? $options.filters
                          .money(totalTransactionalPnp.tax, 1, currency)
                          .replace("€", "")
                      : totalTransactionalPnp.tax
                  }}
                </td>
              </tr>
              <!-- Zadnji red total -->
              <tr
                :class="['header', 'naslov']"
                style="font-weight: bold; text-align: left"
              >
                <th style="text-align: right">Ukupno total</th>
                <th style="text-align: right">
                  {{
                    viewType === "pdf"
                      ? $options.filters
                          .money(totalPnp.base + totalPnp.tax, 1, currency)
                          .replace("€", "")
                      : totalPnp.base + totalPnp.tax
                  }}
                </th>
                <th colspan="3" style="text-align: right">Ukupno porez</th>
                <th style="text-align: right">
                  {{
                    viewType === "pdf"
                      ? $options.filters
                          .money(totalPnp.tax, 1, currency)
                          .replace("€", "")
                      : totalPnp.tax
                  }}
                </th>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </v-container>
</template>
<script>

import { DAY, clone } from '@/plugins/utils'
import { df, auth } from '@/plugins/firebase'
import state from '@/state'
import 'jspdf-autotable'
import { saveExcel } from '@/modules/all-reports/xlsx/config.js'
import EventBus from '@/plugins/event-bus'
import { initPdf, setTable, setPageNumber } from '@/modules/all-reports/pdf/config.js'

export default {
  name: 'Reports',
  inject: ['showMsgBox', 'showLoader', 'hideLoader'],
  components: { },
  data: function () {
    return {
      dateFrom: new Date(new Date().getTime() - DAY)
        .toISOString()
        .substr(0, 10),
      dateTo: new Date().toISOString().substr(0, 10),
      locations: [],
      searchLocation: null,
      allReceipts: [],
      reportFromMenu: undefined,
      reportToMenu: undefined,
      company: undefined,
      location: undefined,
      payments: {
        pdv: [],
        pdvTotal: { base: 0, rate: 'Ukupno', total: 0 }
      },
      tableWidth: '1200px',
      a4PortraitWidth: '575px',
      a4LandscapeWidth: '610px',
      targetModifier: '',
      viewType: 'pdf',
      showTableData: false,
      multiplier: 0,
      listeners: [],
      currency: 'EUR',
      totalByItemPnp: [],
      totalByReceiptPnp: [],
      totalPnp: {},
      cashPnp: { pnp: [{ rate: 0, total: 0, tax: 0 }], total: 0 },
      cardPnp: { pnp: [{ rate: 0, total: 0, tax: 0 }], total: 0 },
      otherPnp: { pnp: [{ rate: 0, total: 0, tax: 0 }], total: 0 },
      transactionalPnp: { pnp: [{ rate: 0, total: 0, tax: 0 }], total: 0 },
      totalCashPnp: {},
      totalCardPnp: {},
      totalOtherPnp: {},
      totalTransactionalPnp: {},
      hasPnp: false,
      showUnpaid: false
    }
  },
  computed: {
    to () {
      return { name: `${this.targetModifier}.all-reports.reportMenu` }
    },
    formatDateTo () {
      return new Intl.DateTimeFormat('hr-HR', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric'
      }).format(new Date(this.dateTo))
    },
    formatDateFrom () {
      return new Intl.DateTimeFormat('hr-HR', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric'
      }).format(new Date(this.dateFrom))
    },
    locationNotSelected () {
      return !this.location || !this.location.id
    }
  },
  async created () {
    this.margin = state.getMargin()
    await this.getPointsOfSale()
  },
  mounted () {
    this.getMultiplier()

    var sideNav = this.$route.meta.sideNav
    switch (sideNav) {
      case 'Companies':
        break
      case 'Company':
        this.targetModifier = 'company'
        break
      case 'PointOfSale':
        this.targetModifier = 'pointOfSale'
        break
      case 'CashRegister':
        this.targetModifier = 'pointOfSale'
        break
    }

    EventBus.$on('set-mini', () => {
      this.margin = state.getMargin()
    })

    this.company = clone(state.getCurrentCompany())
    this.location = clone(state.getPointOfSale())

    if (this.company.type === 'pausalni_obrt') {
      this.showUnpaid = false
    } else {
      this.showUnpaid = true
    }
  },
  methods: {
    showTaxName (rate) {
      var tax = 'PNP '
      return tax + rate + ' %'
    },
    getMultiplier () {
      const listener = df.doc('project_constants/config').onSnapshot(doc => {
        this.config = doc.data()
        this.multiplier = doc.data().item_amount.decimal_multiplier
      })
      this.listeners.push(listener)
    },
    async getData () {
      this.showLoader()
      const dateFrom = new Date(this.dateFrom).getTime() / 1000
      const dateTo = new Date(this.dateTo).getTime() / 1000

      var cashPnp = {
        pnp: [],
        total: 0
      }

      var cardPnp = {
        pnp: [],
        total: 0
      }

      var otherPnp = {
        pnp: [],
        total: 0
      }

      var transactionalPnp = {
        pnp: [],
        total: 0
      }

      this.allReceipts = []

      this.totalCashPnp = { base: 0, tax: 0, total: 0 }
      this.totalCardPnp = { base: 0, tax: 0, total: 0 }
      this.totalOtherPnp = { base: 0, tax: 0, total: 0 }
      this.totalTransactionalPnp = { base: 0, tax: 0, total: 0 }
      this.totalPnp = { base: 0, tax: 0, total: 0 }

      let query = df.collection(`receipts/${state.getCurrentCompany().id}/receipts`)
        .where('issue_date_time', '>=', dateFrom).where('issue_date_time', '<=', dateTo + 86399)
      if (this.searchLocation && this.searchLocation.id !== 'all') {
        query = query.where('location_details.id', '==', this.searchLocation.id)
      }

      await query.get()
        .then((documentSnapshots) => {
          if (documentSnapshots.docs && !documentSnapshots.docs.empty) {
            documentSnapshots.docs.forEach((item) => {
              var rcpt = item.data()
              if (this.showUnpaid === false) {
                if (rcpt.payment && (rcpt.payment.current_status === 'PAID' || rcpt.payment.current_status === 'REVERSED')) {
                  this.allReceipts.push(item.data())
                }
              } else {
                this.allReceipts.push(item.data())
              }
            })
          }
        })

      this.allReceipts.sort((a, b) => a.issue_date_time - b.issue_date_time) // b - a for reverse sort

      var totalByReceiptPnp = []
      this.allReceipts.forEach(receipt => {
        var tempPnp = []
        if (receipt.pdv && receipt.pdv.length > 0) {
          if (receipt.pnp && receipt.pnp.total && receipt.pnp.total > 0) {
            this.hasPnp = true
            tempPnp.push({
              rate: (receipt.pnp.rate),
              base: (receipt.pnp.base),
              total: (receipt.pnp.total + receipt.pnp.base),
              tax: (receipt.pnp.total),
              count: 1,
              paymentMethod: receipt.payment_method
            })
          }
        }

        if (tempPnp && tempPnp.length > 0) {
          // Ako postoji popust na iznos racuna onda skini na pojedinacnim artiklima

          tempPnp.forEach(pnp => {
            const base = Math.round(pnp.base * 100) / 100
            const total = Math.round(pnp.total * 100) / 100
            const tax = Math.round((pnp.tax) * 100) / 100

            const idx = totalByReceiptPnp.findIndex(p => p?.rate === pnp.rate)
            if (idx > -1) {
              totalByReceiptPnp[idx].base += base
              totalByReceiptPnp[idx].total += total
              totalByReceiptPnp[idx].tax += tax
              totalByReceiptPnp[idx].count += 1
            } else {
              totalByReceiptPnp.push({
                rate: (pnp.rate),
                base: base,
                total: (total),
                tax: (tax),
                count: 1
              })
            }

            // Podaci za drugu tablicu po načinima plaćanja
            if (pnp.paymentMethod === 'NOVČANICE') {
              this.totalCashPnp.base += base / 100
              this.totalCashPnp.tax += tax / 100
              this.totalCashPnp.total += total / 100

              const idx = cashPnp.pnp.findIndex(p => p?.rate === pnp.rate / 100)
              if (idx > -1) {
                cashPnp.pnp[idx].base += base / 100
                cashPnp.pnp[idx].tax += tax / 100
                cashPnp.total += total / 100
              } else {
                cashPnp.total += total / 100
                var p = {
                  base: base / 100,
                  rate: pnp.rate / 100,
                  tax: tax / 100
                }
                cashPnp.pnp.push(p)
              }
            }

            if (pnp.paymentMethod === 'KARTICA') {
              this.totalCardPnp.base += base / 100
              this.totalCardPnp.tax += tax / 100
              this.totalCardPnp.total += total / 100

              const idx = cardPnp.pnp.findIndex(p => p?.rate === pnp.rate / 100)
              if (idx > -1) {
                cardPnp.pnp[idx].base += base / 100
                cardPnp.pnp[idx].tax += tax / 100
                cardPnp.total += total / 100
              } else {
                cardPnp.total += total / 100
                p = {
                  base: base / 100,
                  rate: pnp.rate / 100,
                  tax: tax / 100
                }
                cardPnp.pnp.push(p)
              }
            }

            if (pnp.paymentMethod === 'TRANSAKCIJSKI RAČUN') {
              this.totalTransactionalPnp.base += base / 100
              this.totalTransactionalPnp.tax += tax / 100
              this.totalTransactionalPnp.total += total / 100

              const idx = transactionalPnp.pnp.findIndex(p => p?.rate === pnp.rate / 100)
              if (idx > -1) {
                transactionalPnp.pnp[idx].base += base / 100
                transactionalPnp.pnp[idx].tax += tax / 100
                transactionalPnp.total += total / 100
              } else {
                transactionalPnp.total += total / 100
                p = {
                  base: base / 100,
                  rate: pnp.rate / 100,
                  tax: tax / 100
                }
                transactionalPnp.pnp.push(p)
              }
            }

            if (pnp.paymentMethod === 'OSTALO') {
              this.totalOtherPnp.base += base / 100
              this.totalOtherPnp.tax += tax / 100
              this.totalOtherPnp.total += total / 100

              const idx = otherPnp.pnp.findIndex(p => p?.rate === pnp.rate / 100)
              if (idx > -1) {
                otherPnp.pnp[idx].base += base / 100
                otherPnp.pnp[idx].tax += tax / 100
                otherPnp.total += total / 100
              } else {
                otherPnp.total += total / 100
                p = {
                  base: base / 100,
                  rate: pnp.rate / 100,
                  tax: tax / 100
                }
                otherPnp.pnp.push(p)
              }
            }
          })
        }
      })

      // Sluzi samo za formatiranje i zbrajanje ukupnog stanja
      this.totalByItemPnp = totalByReceiptPnp

      this.totalPnp.base = 0
      this.totalPnp.tax = 0
      this.totalPnp.total = 0
      this.totalByItemPnp.forEach((pnp, pIdx) => {
        this.totalByItemPnp[pIdx].base /= 100
        this.totalByItemPnp[pIdx].rate /= 100
        this.totalByItemPnp[pIdx].tax /= 100
        this.totalByItemPnp[pIdx].total /= 100

        this.totalByItemPnp[pIdx].base = Math.round(this.totalByItemPnp[pIdx].base * 100) / 100
        this.totalByItemPnp[pIdx].rate = Math.round(this.totalByItemPnp[pIdx].rate * 100) / 100
        this.totalByItemPnp[pIdx].tax = Math.round(this.totalByItemPnp[pIdx].tax * 100) / 100
        this.totalByItemPnp[pIdx].total = Math.round(this.totalByItemPnp[pIdx].total * 100) / 100

        this.totalPnp.base += pnp.base
        this.totalPnp.tax += pnp.tax
        this.totalPnp.total += pnp.total
      })

      this.cashPnp = cashPnp
      this.cardPnp = cardPnp
      this.otherPnp = otherPnp
      this.transactionalPnp = transactionalPnp
      this.showTableData = true

      this.hideLoader()
    },
    changeWidth () {
      this.showLoader('', { opacity: 1 })
      document.getElementById('table_info').style.maxWidth = this.a4PortraitWidth
      document.getElementById('table_info').style.minWidth = this.a4PortraitWidth
      document.getElementById('table_info').style.fontSize = '80%'

      this.generatePdf()
    },
    async generatePdf () {
      const that = this
      // eslint-disable-next-line new-cap
      var doc = await initPdf()

      doc = await setTable(doc, '#firma', 10)
      var finalY = doc.lastAutoTable.finalY || 10
      doc = await setTable(doc, '#title', finalY)
      finalY = doc.lastAutoTable.finalY || 10
      doc = await setTable(doc, '#tableTraffic', finalY)
      finalY = doc.lastAutoTable.finalY || 10
      doc = await setTable(doc, '#tablePayments', finalY + 10)

      doc = await setPageNumber(doc)

      doc.save('Porezno izvješće.pdf')

      document.getElementById('table_info').style.maxWidth = that.tableWidth
      document.getElementById('table_info').style.minWidth = that.tableWidth
      document.getElementById('table_info').style.fontSize = '100%'
      this.viewType = 'pdf'
      that.hideLoader()
    },
    generateExcel () {
      this.showLoader('', { opacity: 1 })
      this.viewType = 'excel'
      setTimeout(() => {
        var tables = ['firma', 'title', 'tableTraffic']
        var fileName = 'Porez na potrošnju.xlsx'

        saveExcel(tables, fileName)

        this.viewType = 'pdf'
        this.hideLoader()
      }, 1000)
    },
    async getPointsOfSale () {
      const docRef = df.doc(`user_locations/${auth.currentUser.uid}.${state.getCurrentCompany().id}`)
      docRef.onSnapshot(doc => {
        var locations = Object.values(doc.data().locations)
        locations.forEach((location, index) => {
          this.locations.push(location)
        })
      })
      // const all = { name: 'Sva', id: 'all' }
      // this.locations.push(all)
      // this.searchLocation = all
    }
  }
}
</script>
<style scoped>
#tableTraffic {
  border: 1px solid #767676;
}

#tableTax {
  border: 1px solid #767676;
}

.naslov {
  background-color: #d3d3d3;
  border: 1px solid black;
}
.v-btn {
  min-width: 40px !important;
  padding: 0px !important;
}
</style>
